import { apiSlice } from "../../services/apiSlice";


export const pitchDeckApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        pitchDeckUpload: builder.mutation({
            query: (data: { user_id: string | undefined, file: FormData }) => ({
                url: `pitch_deck_uploader/uploading_pitch_deck/`,
                method: 'POST',
                body: {
                    user_id: data.user_id,
                    file: data.file,
                }
            })
        }),
        pitchDeckProcess: builder.mutation({
            query: (data: { user_id: string | undefined }) => ({
                url: `pitch_deck_uploader/process_pitch_deck/`,
                method: 'POST',
                body: {
                    user_id: data.user_id,
                }
            })
        }),
        getPitchDeck: builder.query({
            query: (user_id) => ({
                url: `pitch_deck_uploader/getting_pitch_deck/${user_id}/`,
                method: 'GET',
            })
        }),
        pitchDeckDelete: builder.mutation({
            query: (data: { user_id: string | undefined }) => ({
                url: `pitch_deck_uploader/delete-pitch-deck/${data.user_id}`,
                method: 'DELETE'
            })
        }),


    })
})

export const {
    useGetPitchDeckQuery,
    usePitchDeckProcessMutation,
    usePitchDeckUploadMutation,
    usePitchDeckDeleteMutation
    
} = pitchDeckApiSlice