import React from "react";
import LandingLayout from "../Layout";
import { Form, Input, Button } from "antd";

function Contact() {
  const [form] = Form.useForm();

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
  };

  return (
    <LandingLayout>
      <section className="xl:py-[80px] py-16 max-w-[500px] mx-auto px-7">
        <div className="flex flex-col items-center justify-center ">
          <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5 ">
            Contact Us
          </span>
          <h3 className="text-4xl font-semibold mt-3 xl:text-left text-center">
            Get in touch
          </h3>
          <p className="text-grey600 text-base font-regular mt-5 text-center">
            We’d love to hear from you. Please fill out this form.
          </p>
        </div>

        <div className="mt-10">
          <Form
            form={form}
            layout="vertical"
            onFinish={() => {}}
            initialValues={initialValues}
          >
            <div className="grid md:grid-cols-2 gap-x-5">
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
              >
                <Input placeholder="First Name" className="h-12" />
              </Form.Item>

              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
              >
                <Input placeholder="Last Name" className="h-12" />
              </Form.Item>
            </div>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input placeholder="you@company.com" className="h-12" />
            </Form.Item>

            <Form.Item
              name="phone_number"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number",
                },
              ]}
            >
              <Input placeholder="+1 (555) 000-0000" className="h-12" />
            </Form.Item>

            <Form.Item
              name="message"
              label="Message"
              rules={[
                {
                  required: true,
                  message: "Please input your message",
                },
              ]}
            >
              <Input.TextArea placeholder="Message" rows={8} />
            </Form.Item>

            <Form.Item className="w-full">
              <Button type="primary" htmlType="submit" className="w-full bg-primary h-12">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section>
    </LandingLayout>
  );
}

export default Contact;
