import React, { useCallback, useState } from 'react'
import HomePageLogo from '../../assets/svg/HompageLogo2.svg'

import Overview from './tabs/Overview'
import PitchDeckModal from '../../components/PitchDeckModal'
import { useAppSelector } from '../../lib/hooks'
import { RootState } from '../../lib/store'
import { shallowEqual } from 'react-redux'
import { calculatePercentage } from '../../utils/calculatePercentage'
import { Button, Switch } from 'antd'
import Dnd from './components/Dnd'
import Matches from './tabs/Matches'
import Shortlist from './tabs/Shortlist'
import ReachedOut from './tabs/ReachOut'
import { IconBlue, IconWhite } from '../../assets'
import { Link } from 'react-router-dom'
import { FiFilter } from "react-icons/fi";



function Homepage() {
    const [pitchDeckModal, setPitchDeckModal] = React.useState(false)
    const user = useAppSelector((state: RootState) => state.user, shallowEqual)
    const startupData = useAppSelector((state: RootState) => state.startup, shallowEqual)
    const [funnel, setFunnel] = React.useState(false)


    const calculateNonEmptyPercentage = useCallback(() => {
        const val = calculatePercentage(user, startupData)
        return val
    }, [startupData, user])

    const [tab, setTab] = useState("overview")


    // useEffect(() => {
    //   if(parseInt(calculateNonEmptyPercentage()) < 50 || user.){

    //   }
    // }, [])

    const isActive = useCallback(
        (value: string) => {
            return tab === value;
        },
        [tab],
    )


    return (
        <div className='h-screen  overflow-scroll'>
            <div className='flex xl:flex-row flex-col  xl:items-center justify-start xl:justify-between xl:w-full xl:bg-primary50 xl:rounded-bl-2xl xl:px-8 px-4 py-4'>
                <div className='flex flex-wrap xl:flex-row items-center gap-x-1 w-full'>
                    <span onClick={() => { setTab("overview") }} className={`cursor-pointer rounded-md xl:text-md text-sm font-semibold px-3 py-2 ${isActive("overview") ? 'text-white bg-primary600 ' : 'text-grey700 bg-primary50'}`}>Overview</span>
                    <span onClick={() => { setTab("matches") }} className={`cursor-pointer rounded-md xl:text-md text-sm font-semibold px-3 py-2 ${isActive("matches") ? 'text-white bg-primary600' : 'text-grey700 bg-primary50'}`}>Matches</span>
                    <span onClick={() => { setTab("shortlist") }} className={`cursor-pointer rounded-md xl:text-md text-sm font-semibold px-3 py-2 ${isActive("shortlist") ? 'text-white bg-primary600 ' : 'text-grey700 bg-primary50'}`}>Shortlists</span>
                    <span onClick={() => { setTab("reached") }} className={`cursor-pointer rounded-md xl:text-md text-sm font-semibold px-3 py-2 ${isActive("reached") ? 'text-white bg-primary600 ' : 'text-grey700 bg-primary50'}`}>Reached out</span>
                </div>

                <div className={`flex items-center cursor-pointer text-base min-w-[185px] rounded-md border-primary px-3 py-2 border font-semibold gap-x-3 w-fit xl:mt-0 mt-4 ${isActive("funnel") ? 'text-white bg-primary600 rounded-md' : 'text-grey700'}`}
                    onClick={() => { setTab("funnel") }}
                >
                    <FiFilter className='size-5' />
                    <p>Manage Funnels</p>
                </div>
            </div>

            <div className='min-h-[100px] bg-primary/10 flex items-center justify-between rounded-2xl mt-6 mx-8' >

                <div className='flex items-center justify-between w-full'>
                    <div className='flex flex-row xl:items-center px-8 py-[30px] xl:gap-x-6'>
                        <img src={HomePageLogo} alt="" className='w-[90px] h-[60px]' />
                        <h2 className='md:text-xl font-semibold md:max-w-[500px] text-grey700'>Welcome to MyCofoundr.AI, the world of personalized and contextualized intros.</h2>
                    </div>
                    {parseInt(calculateNonEmptyPercentage()) < 50 ?
                    <Link to="/profile"
                        type='primary'
                        className='bg-primary600 text-white !py-4 px-7 flex items-center gap-x-2 rounded-md'
                    >
                        <img src={IconWhite} alt='' className='size-6' />
                        Get Started
                    </Link>
                    : null
                    }
                </div>

                {/* <div className='mr-[30px]'>
                    {parseInt(calculateNonEmptyPercentage()) < 50 ?
                        <button onClick={() => { setPitchDeckModal(true) }} className='flex items-center gap-x-2 bg-primary text-[15px] text-white px-5 py-3.5 rounded-md text-sm font-normal'>
                            <p>Get Started</p>
                        </button>
                        : null
                    }
                </div> */}



            </div>

            <div className='px-[30px]'>
                {tab === "overview" &&
                    <Overview setTab={setTab}/>
                }

                {tab === "matches" &&
                    <Matches />
                }

                {tab === "shortlist" &&
                    <Shortlist />
                }

                {tab === "reached" &&
                    <ReachedOut />
                }

                {tab === "funnel" &&
                    <Dnd
                    />
                }
            </div>




            <PitchDeckModal pitchDeckModal={pitchDeckModal} setPitchDeckModal={setPitchDeckModal} />


        </div>
    )
}

export default Homepage