import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAppSelector } from '../../../lib/hooks';
import { RootState } from '../../../lib/store';
import { defaultProfile } from '../../../constants/defaults';
import { useGetFunnelInvestorsQuery, useMoveInvestorsMutation } from '../../../lib/features/recommendations/recommendations';
import Loading from '../../../components/Loading';
import { FaLinkedin } from 'react-icons/fa';
import { Tooltip } from 'antd';
import { TbWorldWww } from 'react-icons/tb';
import Gmail from "../../../assets/svg/gmail.svg"
import Rating from '../../Recommendation/components/Rating';


interface Item {
    id: string;
    content: string;
    image?: string;
    score?: number;
    fund?: string;
    email: string;
    matchLevel: string;
    bio: string;
    fundwebsite: string;
    linkedin: string
}

// a little function to help with reordering the result
const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any): React.CSSProperties => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? '#C9C9CA' : '#F4F4F5',
    padding: grid,
    width: "100%"
});

function Dnd() {
    const user = useAppSelector((state: RootState) => state.user)

    const [moveInvestors, { isLoading: moveIsloading }] = useMoveInvestorsMutation()


    const { data: vcRecommendations, isLoading } = useGetFunnelInvestorsQuery(user?.id)

    const [items, setItems] = useState<Item[]>([]);
    const [selected, setSelected] = useState<Item[]>([]);
    const [inProgress, setInProgress] = useState<Item[]>([]);
    const [completed, setCompleted] = useState<Item[]>([]);




    const mapToItems = (list: any[]) => list.map((key: any) => ({
        id: key.id,
        content: key.Name,
        score: 100,
        image: key?.id.toString().includes('vc') ? key?.metadata?.ProfilepicturesinS3 : !key?.id.toString().includes('vc') ? key?.metadata?.ProfilepictureinS3 : defaultProfile,
        fund: key.FundName,
        email: key?.metadata?.Email,
        matchLevel: key?.Score.toString(),
        bio: key?.metadata?.Longerbio,
        fundwebsite: key?.metadata?.Fundwebsite,
        linkedin: key?.metadata?.LinkedInprofile
    }));

    useEffect(() => {
        const ai_matches = vcRecommendations?.ai_matches || []
        const shortlist = vcRecommendations?.shortlist || []
        const reached_out = vcRecommendations?.reached_out || []
        const qna_list = vcRecommendations?.qna_list || []
        setItems(mapToItems(ai_matches))
        setSelected(mapToItems(shortlist))
        setInProgress(mapToItems(reached_out))
        setCompleted(mapToItems(qna_list))
    }, [vcRecommendations]);








    const move = useCallback(
        (
            source: Item[],
            destination: Item[],
            droppableSource: { index: number; droppableId: string },
            droppableDestination: { index: number; droppableId: string }
        ) => {
            const id2List: { [key: string]: string } = {
                "droppable": 'ai_matches',
                "droppable2": 'shortlist',
                "droppable3": 'reached_out',   // New column
                "droppable4": 'qna_list'     // New column
            };
            const sourceClone = Array.from(source);
            const destClone = Array.from(destination);
            const [removed] = sourceClone.splice(droppableSource.index, 1);
            destClone.splice(droppableDestination.index, 0, removed);
            const result: { [key: string]: Item[] } = {};
            moveInvestors(
                {
                    "user_id": user?.id,
                    "investor_id": removed.id,
                    "current_field": id2List[droppableSource.droppableId.toString()],
                    "next_field": id2List[droppableDestination.droppableId.toString()]
                }
            ).then((response) => {
                console.log("response", response)
            }).catch((error) => {
                console.log("error", error)
            });
            result[droppableSource.droppableId] = sourceClone;
            result[droppableDestination.droppableId] = destClone;
            return result;
        },
        [moveInvestors, user?.id]
    );


    const getList = useCallback(
        (id: any) => {
            switch (id) {
                case 'droppable':
                    return items;
                case 'droppable2':
                    return selected;
                case 'droppable3':
                    return inProgress;   // New column
                case 'droppable4':
                    return completed;    // New column
                default:
                    return [];
            }
        },
        [items, selected, inProgress, completed]
    );

    const onDragEnd = (result: any) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (moveIsloading) return

        if (source.droppableId === destination.droppableId) {
            const reorderedItems = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            if (source.droppableId === 'droppable') {
                setItems(reorderedItems);
            } else if (source.droppableId === 'droppable2') {
                setSelected(reorderedItems);
            } else if (source.droppableId === 'droppable3') {
                setInProgress(reorderedItems);  // New column
            } else {
                setCompleted(reorderedItems);   // New column
            }
        } else {
            const movedResult = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );

            setItems(movedResult.droppable || items);
            setSelected(movedResult.droppable2 || selected);
            setInProgress(movedResult.droppable3 || inProgress);  // New column
            setCompleted(movedResult.droppable4 || completed);    // New column
        }
    };

    if (isLoading) return <Loading />


    return (

        <div>
            <h1 className='text-xl font-semibold mt-6'>Manage Funnel</h1>

            <DragDropContext onDragEnd={onDragEnd}>
                <div className='grid md:grid-cols-3 md:gap-10 gap-5 mt-5'>
                    {/* First column */}
                    <Droppable droppableId="droppable" >
                        {(provided: any, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                className='!p-5 rounded-lg'
                            >
                                <h3 className='mb-5 text-base'>AI Matches</h3>
                                {items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                className='rounded-md'
                                            >
                                                <div className='flex flex-col gap-y-2'>
                                                    <div className='flex items-center justify-end'>
                                                        {!item.fund ?
                                                            <span className="w-max bg-green-100 text-green-800 text-xs font-medium me-2 px-3 py-0.5 rounded-2xl">ANGEL</span>
                                                            :
                                                            <span className="w-max top-3 bg-primary100 text-primary700 text-xs font-medium me-2 2xl:px-3 px-1 2xl:py-2 py-0.5 rounded-2xl">VENTURE CAPITALIST</span>
                                                        }
                                                        {/* <span className='bg-[#9DFF9D] text-[#1A5B20] py-0.5 px-1 rounded-md'>{item.score?.toFixed(1)}%</span> */}
                                                    </div>
                                                    <div className='flex items-center gap-x-4'>
                                                        <img src={item.image} alt="" className='size-16 rounded-full' />
                                                        <div>
                                                            <p className='text-lg font-semibold text-grey900'>{item.content}</p>
                                                            {item.fund &&
                                                                <p className='text-sm text-grey600 '><b>Fund:</b> {item.fund}</p>
                                                            }
                                                            <div className='flex mt-2 gap-x-2'>


                                                                {item.linkedin &&
                                                                    <Tooltip title="Linkedin">
                                                                        <a href={`${item.linkedin}`} target='_blank' rel='noreferrer'>
                                                                            <FaLinkedin className='text-[#0A66C2] size-5 hover:text-primary cursor-pointer' />
                                                                        </a>
                                                                    </Tooltip>
                                                                }

                                                                {item.fundwebsite &&
                                                                    <Tooltip title="Website">
                                                                        <a href={`${item.fundwebsite ? item.fundwebsite?.startsWith("www") ? `https://${item.fundwebsite}` : item.fundwebsite : ""}` || ""} target='_blank' rel='noreferrer' >
                                                                            <TbWorldWww className='hover:text-primary cursor-pointer text-[#0A66C2] size-5' />
                                                                        </a>
                                                                    </Tooltip>
                                                                }

                                                                {item.email &&
                                                                    <Tooltip title="Email">
                                                                        <a href={`mailto:${item.email}`}>
                                                                            {/* <AiOutlineMail className='hover:text-primary cursor-pointer' /> */}
                                                                            <img src={Gmail} alt="" />
                                                                        </a>
                                                                    </Tooltip>
                                                                }

                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className='h-0.5 w-full border border-grey100 my-2.5'></div>

                                                    <div className='flex gap-x-2 text-sm'>
                                                        <div className='flex'>
                                                            <p className=''>Match level:</p>
                                                        </div>

                                                        <div className='flex'>
                                                            <Rating rating={parseInt(item.matchLevel)} maxRating={!item.fund ? 4 : 6} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>

                    {/* Second column */}
                    <Droppable droppableId="droppable2">
                        {(provided: any, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                className='!p-5 rounded-lg'
                            >
                                <h3 className='mb-5 text-base'>Shortlist</h3>
                                {selected.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index} >
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                className='rounded-md'
                                            >
                                                <div className='flex flex-col gap-y-2'>
                                                    <div className='flex items-center justify-end'>
                                                        {!item.fund ?
                                                            <span className="w-max bg-green-100 text-green-800 text-xs font-medium me-2 px-3 py-0.5 rounded-2xl">ANGEL</span>
                                                            :
                                                            <span className="w-max top-3 bg-primary100 text-primary700 text-xs font-medium me-2 2xl:px-3 px-1 2xl:py-2 py-0.5 rounded-2xl">VENTURE CAPITALIST</span>
                                                        }
                                                        {/* <span className='bg-[#9DFF9D] text-[#1A5B20] py-0.5 px-1 rounded-md'>{item.score?.toFixed(1)}%</span> */}
                                                    </div>
                                                    <div className='flex items-center gap-x-4'>
                                                        <img src={item.image} alt="" className='size-16 rounded-full' />
                                                        <div>
                                                            <p className='text-lg font-semibold text-grey900'>{item.content}</p>
                                                            {item.fund &&
                                                                <p className='text-sm text-grey600 '><b>Fund:</b> {item.fund}</p>
                                                            }
                                                            <div className='flex mt-2 gap-x-2'>


                                                                {item.linkedin &&
                                                                    <Tooltip title="Linkedin">
                                                                        <a href={`${item.linkedin}`} target='_blank' rel='noreferrer'>
                                                                            <FaLinkedin className='text-[#0A66C2] size-5 hover:text-primary cursor-pointer' />
                                                                        </a>
                                                                    </Tooltip>
                                                                }

                                                                {item.fundwebsite &&
                                                                    <Tooltip title="Website">
                                                                        <a href={`${item.fundwebsite ? item.fundwebsite?.startsWith("www") ? `https://${item.fundwebsite}` : item.fundwebsite : ""}` || ""} target='_blank' rel='noreferrer' >
                                                                            <TbWorldWww className='hover:text-primary cursor-pointer text-[#0A66C2] size-5' />
                                                                        </a>
                                                                    </Tooltip>
                                                                }

                                                                {item.email &&
                                                                    <Tooltip title="Email">
                                                                        <a href={`mailto:${item.email}`}>
                                                                            {/* <AiOutlineMail className='hover:text-primary cursor-pointer' /> */}
                                                                            <img src={Gmail} alt="" />
                                                                        </a>
                                                                    </Tooltip>
                                                                }

                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className='h-0.5 w-full border border-grey100 my-2.5'></div>

                                                    <div className='flex gap-x-2 text-sm'>
                                                        <div className='flex'>
                                                            <p className=''>Match level:</p>
                                                        </div>

                                                        <div className='flex'>
                                                            <Rating rating={parseInt(item.matchLevel)} maxRating={!item.fund ? 4 : 6} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>

                    {/* Third column - In Progress */}
                    <Droppable droppableId="droppable3">
                        {(provided: any, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                className='!p-5 rounded-lg'
                            >
                                <h3 className='mb-5 text-base'>Reached Out</h3>
                                {inProgress.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                className='rounded-md'
                                            >
                                                <div className='flex flex-col gap-y-2'>
                                                    <div className='flex items-center justify-end'>
                                                        {!item.fund ?
                                                            <span className="w-max bg-green-100 text-green-800 text-xs font-medium me-2 px-3 py-0.5 rounded-2xl">ANGEL</span>
                                                            :
                                                            <span className="w-max top-3 bg-primary100 text-primary700 text-xs font-medium me-2 2xl:px-3 px-1 2xl:py-2 py-0.5 rounded-2xl">VENTURE CAPITALIST</span>
                                                        }
                                                        {/* <span className='bg-[#9DFF9D] text-[#1A5B20] py-0.5 px-1 rounded-md'>{item.score?.toFixed(1)}%</span> */}
                                                    </div>
                                                    <div className='flex items-center gap-x-4'>
                                                        <img src={item.image} alt="" className='size-16 rounded-full' />
                                                        <div>
                                                            <p className='text-lg font-semibold text-grey900'>{item.content}</p>
                                                            {item.fund &&
                                                                <p className='text-sm text-grey600 '><b>Fund:</b> {item.fund}</p>
                                                            }
                                                            <div className='flex mt-2 gap-x-2'>


                                                                {item.linkedin &&
                                                                    <Tooltip title="Linkedin">
                                                                        <a href={`${item.linkedin}`} target='_blank' rel='noreferrer'>
                                                                            <FaLinkedin className='text-[#0A66C2] size-5 hover:text-primary cursor-pointer' />
                                                                        </a>
                                                                    </Tooltip>
                                                                }

                                                                {item.fundwebsite &&
                                                                    <Tooltip title="Website">
                                                                        <a href={`${item.fundwebsite ? item.fundwebsite?.startsWith("www") ? `https://${item.fundwebsite}` : item.fundwebsite : ""}` || ""} target='_blank' rel='noreferrer' >
                                                                            <TbWorldWww className='hover:text-primary cursor-pointer text-[#0A66C2] size-5' />
                                                                        </a>
                                                                    </Tooltip>
                                                                }

                                                                {item.email &&
                                                                    <Tooltip title="Email">
                                                                        <a href={`mailto:${item.email}`}>
                                                                            {/* <AiOutlineMail className='hover:text-primary cursor-pointer' /> */}
                                                                            <img src={Gmail} alt="" />
                                                                        </a>
                                                                    </Tooltip>
                                                                }

                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className='h-0.5 w-full border border-grey100 my-2.5'></div>

                                                    <div className='flex gap-x-2 text-sm'>
                                                        <div className='flex'>
                                                            <p className=''>Match level:</p>
                                                        </div>

                                                        <div className='flex'>
                                                            <Rating rating={parseInt(item.matchLevel)} maxRating={!item.fund ? 4 : 6} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>

                    {/* Fourth column - Completed */}
                    {/* <Droppable droppableId="droppable4">
                        {(provided: any, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                className='!p-5 rounded-lg'
                            >
                                <h3 className='mb-5 text-base'>Q&A</h3>
                                {completed.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index} >
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                className='rounded-md'
                                            >
                                                <div className='flex flex-col gap-y-2'>
                                                    <div className='flex items-center justify-end'>
                                                        <span className='bg-[#9DFF9D] text-[#1A5B20] py-0.5 px-1 rounded-md'>{item.score?.toFixed(1)}%</span>
                                                    </div>
                                                    <div className='flex items-center gap-x-2'>
                                                        <img src={item.image} alt="" className='w-6 h-6 rounded-full' />
                                                        {item.content}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable> */}
                </div>
            </DragDropContext>
        </div>
    );
}

export default Dnd;
