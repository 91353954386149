import { FC, useRef, useState } from "react";
import { defaultProfile } from "../../constants/defaults";
import { Avatar, Button, Form, FormProps, Input } from "antd";
import { userBadges } from "./config";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../lib/store";
import { useAppSelector } from "../../lib/hooks";
import {
  useGetProfileImageQuery,
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../lib/features/user/userApiSlice";
import { useDispatch } from "react-redux";
import { errorMessage, successMessage } from "../../constants/notification";
import type { UploadProps } from "antd";
import { message, Upload, Select } from "antd";
import { FiUploadCloud } from "react-icons/fi";
import { Mail } from "lucide-react";
import { SiLinkedin } from "react-icons/si";
import { Countries } from "../../constants/countries";
import { BASE_URL } from "../../lib/services/reAuth";
const { Option } = Select;

const { Dragger } = Upload;

type FieldType = {
  email: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  linkedin_profile: string | undefined;
  bio: string | undefined;
  bio_link: string | undefined;
};

interface IProps {}

export const UserSetting: FC<IProps> = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const { access } = useAppSelector((state: RootState) => state.auth);
  const [form] = Form.useForm<{
    email: string | undefined;
    first_name: string | undefined;
    last_name: string | undefined;
    linkedin_profile: string | undefined;
    bio: string | undefined;
    bio_link: string | undefined;
  }>();
  const bioValue = Form.useWatch("bio", form);
  const [isLoading, setIsLoading] = useState(false);
  const [name] = useState(user?.first_name);
  const [email] = useState(user?.email);
  const [role] = useState(user?.religion);
  const [badges, setBadges] = useState(userBadges);
  const [avatar, setAvatar] = useState<File>();
  const [imagePath, setImagePath] = useState(
    user?.founder_image_url || defaultProfile
  );

  const [updateUser, { isLoading: updateUserIsLoading }] =
    useUpdateUserMutation();
  const { refetch: userDataRefetch } = useGetUserQuery(user?.id);

  const { data: getProfileImage, refetch: getProfileImageRefetch } =
    useGetProfileImageQuery({ userId: user?.id });

  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: `${BASE_URL}users/upload-image/${user?.id}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        getProfileImageRefetch();
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  // const [updateUserProfileImg] = useUpdateUserProfileImgMutation()

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    //
    try {
      updateUser({ userId: user?.id, ...values })
        .then((res) => {
          userDataRefetch();
          successMessage(
            res?.data?.message || "Information saved successfully"
          );
        })
        .catch(() => {
          errorMessage("Failed to update information");
        });
    } catch (e: any) {
      setIsLoading(false);
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = () => {};

  // const handleFileSelect = () => {
  //     if (fileInputRef.current && fileInputRef.current.files) {
  //         const file = fileInputRef.current.files[0];
  //         if (file && file.type.startsWith('image/')) {
  //             // Do something with the selected image file
  //             setAvatar(file)
  //             const selectedImage = document.querySelector('.selectedImage');
  //             const reader = new FileReader();

  //             reader.onload = function () {
  //                 if (!selectedImage) return
  //                 // @ts-ignore
  //                 selectedImage.src = reader.result;
  //             };

  //             if (file) {
  //                 reader.readAsDataURL(file);
  //                 // Display the path of the selected image

  //                 const formData = new FormData();
  //                 formData.append('file', file);

  //                 setImagePath(URL.createObjectURL(file));

  //                 updateUserProfileImg({ userId: user?.id, formData }).then((res: any) => {
  //                     userDataRefetch();
  //                     successMessage("Profile Image updated successfully");
  //                 }).catch((err) => {
  //                     errorMessage("Failed to update profile image");
  //                 });
  //             }
  //         }
  //     }
  // };

  return (
    <div className="h-screen overflow-y-auto p-8">
      <div>
        <h3 className="text-3xl font-semibold">Settings</h3>
        <h5 className="mt-8 text-lg font-semibold">Personal Info</h5>
        <p className="mt-1 mb-5 text-sm text-grey600">
          Update your photo and personal details here
        </p>
        <hr />
      </div>

      <div className="mt-6">
        <Form
          layout="horizontal"
          form={form}
          initialValues={{
            email: user?.email,
            first_name: user?.first_name,
            last_name: user?.last_name,
            linkedin_profile: user?.linkedin_profile,
            bio: user?.bio,
            bio_link: user?.bio_link,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="flex">
            <div className="w-1/5">
              <h6 className="text-sm font-semibold text-grey700">Your photo</h6>
              <p className="text-sm text-grey600">
                This will be displayes on your profile
              </p>
            </div>
            <div className="w-3/5 flex gap-x-5 items-start">
              <div className="">
                <Avatar
                  className={"selectedImage"}
                  size={96}
                  src={getProfileImage?.url || defaultProfile}
                />
              </div>

              <Dragger {...props} className="bg-transparent w-full">
                <div className="ant-upload-drag-icon flex w-full justify-center items-center ">
                  <div className="size-10 bg-grey50 rounded-full flex justify-center items-center">
                    <FiUploadCloud size={20} />
                  </div>
                </div>
                <p className="ant-upload-text">
                  <span className="text-primary700 font-semibold">
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </p>
                <p className="ant-upload-hint">SVG, PNG, JPG</p>
              </Dragger>
            </div>
          </div>

          <div className="flex items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">Name</p>
            </div>
            <div className="flex w-3/5 justify-between gap-x-6 items-center">
              <Form.Item<FieldType>
                hasFeedback
                label=""
                name="first_name"
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
                className="w-full"
              >
                <Input placeholder="First Name" className="h-11" />
              </Form.Item>
              <Form.Item<FieldType>
                hasFeedback
                label=""
                name="last_name"
                className="w-full"
                rules={[
                  { required: true, message: "Please input your last name!" },
                ]}
              >
                <Input placeholder="Last Name" className="h-11" />
              </Form.Item>
            </div>
          </div>

          <hr />

          <div className="flex items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">Email address</p>
            </div>
            <div className="w-3/5">
              <Form.Item name={"email"} hasFeedback label="" className="w-full">
                <Input
                  disabled
                  prefix={<Mail className="size-5" />}
                  className="h-11"
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">LinkedIn Account</p>
            </div>
            <div className="w-3/5">
              <Form.Item<FieldType>
                hasFeedback
                label=""
                name="linkedin_profile"
                rules={[
                  { required: true, message: "Whats your linkedIn profile?" },
                ]}
              >
                <Input
                  placeholder="LinkedIn profile"
                  prefix={<SiLinkedin className="size-5 text-[#0A66C2]" />}
                  className="h-11"
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">Personal Website</p>
            </div>
            <div className="w-3/5">
              <Form.Item name="personal_website" label="">
                <Input placeholder="Personal Website" className="h-11" />
              </Form.Item>
            </div>
          </div>

          <div className="flex items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">Bio</p>
              <p className="text-grey600">Write a short introduction</p>
            </div>
            <div className="w-3/5">
              <Form.Item name="bio" label="" className="mb-0">
                <Input.TextArea placeholder="Bio" rows={6} maxLength={500} />
              </Form.Item>
              <p>{500 - (bioValue?.length || 0)} characters left</p>
            </div>
          </div>
          <hr />
          <div className="flex items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">Country</p>
            </div>
            <div className="w-3/5">
              <Form.Item
                name="country"
                // label="Monthly Recurring Revenue"

                label={""}
              >
                <Select placeholder="Select Country" showSearch>
                  <Option value={""}>Select country</Option>
                  {Countries.map((item) => (
                    <Option key={item.code} value={item.name}>
                      {item.emoji} {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <hr />

          <div className="mt-5 flex justify-end gap-x-4">
            <Button
              onClick={() => {
                // dispatch(unauthenticate())
                // dispatch(unSetProfile())
                // navigate('/')
              }}
              type="default"
              htmlType="submit"
            >
              Cancel
            </Button>

            <Form.Item>
              <Button
                disabled={updateUserIsLoading}
                loading={updateUserIsLoading}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>

          {/* <div style={{ marginTop: 25, paddingBottom: 10 }}>
                        <div style={{ fontSize: 14, color: '#495057', fontWeight: '500' }}>
                            Optional Demographic Information Form
                        </div>
                        <div style={{ fontSize: 14, color: '#868E96', marginTop: 5, marginBottom: 10 }}>
                            Filling the form is entirely optional, but useful for generating the best matches.
                        </div>

                        <img
                            src={fillForm}
                            alt={'fillForm'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/settings')
                            }} />
                    </div> */}
        </Form>
      </div>
    </div>
  );
};
