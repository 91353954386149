import React, { FC, useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import { Button, Modal, Skeleton } from "antd";
import { TbWorldWww } from "react-icons/tb";
import Rating from "./Rating";
import { IoClose } from "react-icons/io5";
import { Tooltip } from "antd";
import { defaultProfile } from "../../../constants/defaults";
import { FaLinkedin } from "react-icons/fa";
import Gmail from "../../../assets/svg/gmail.svg";
import { FiCopy } from "react-icons/fi";
import { useGenerateEmailMutation } from "../../../lib/features/startup/startupApiSlice";
import { useAppSelector } from "../../../lib/hooks";
import { RootState } from "../../../lib/store";
import { AIMessage } from "../../../components/message";
import { toast } from "react-toastify";
import { useShortlistInvestorsMutation } from "../../../lib/features/recommendations/recommendations";
import { errorMessage, successMessage } from "../../../constants/notification";
import useAnalyticsEventTracker from "../../../utils/Analytics";

interface IProps {
  avatar?: string | undefined;
  name: string;
  fund?: string;
  sectors?: string;
  email?: string;
  Reason?: string;
  matchLevel: string;
  bio?: string;
  tag?: boolean;
  fundwebsite?: string;
  linkedin?: string;
  loading?: boolean | undefined;
  active?: boolean | undefined;
  stages?: string | undefined;
  index?: string | number;
  setRecommendationCard?: any;
  recommendationCard?: any;
  id?: string | undefined;
  shortlist: boolean;
}

export const InvestorCard: FC<IProps> = ({
  avatar,
  name,
  fund,
  email,
  sectors,
  matchLevel,
  Reason,
  bio,
  fundwebsite,
  tag,
  linkedin,
  stages,
  loading,
  active,
  index,
  id,
  recommendationCard,
  setRecommendationCard,
  shortlist,
}) => {
  const showModal = () => {
    setRecommendationCard(index);
  };

  const handleOk = () => {
    setRecommendationCard(null);
  };

  const handleCancel = () => {
    setRecommendationCard(null, "cancel");
  };

  const [draftEmail, setDraftEmail] = React.useState(false);
  const user = useAppSelector((state: RootState) => state.user);
  const [subject, setSubject] = React.useState("");
  const [body, setBody] = React.useState("");
  const [copySuccess, setCopySuccess] = useState("Copy Email");

  const gAnalytics = useAnalyticsEventTracker("recommendations");


  const [generateEmail] = useGenerateEmailMutation();

  const handleCopy = async (textToCopy: string) => {
    gAnalytics("Copied email", user?.email || "User");
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const [shortlistInvestors] = useShortlistInvestorsMutation();
  const handleShortlist = async () => {
    gAnalytics(`Shortlisted investor: ${name}`, user?.email || "User");
    await shortlistInvestors({
      user_id: user?.id,
      id: id,
    })
      .then((res) => {
        successMessage("Shortlisted successfully");
        console.log(res);
      })
      .catch((err) => {
        errorMessage("Failed to shortlist");
        console.log(err);
      });
  };

  return (
    <>
      <div
        className="relative flex flex-col justify-between lg:m-2.5 gap-2.5 rounded-lg pb-8 pt-5  px-6 shadow-lg border-t cursor-pointer hover:shadow-2xl"
        onClick={() => {
          showModal();
        }}
        key={index}
      >
        <Skeleton loading={loading} active={active} avatar>
          <div className="flex justify-end">
            {tag ? (
              <span className="w-max bg-green-100 text-green-800 text-xs font-medium me-2 px-3 py-0.5 rounded-2xl">
                ANGEL
              </span>
            ) : (
              <span className="w-max top-3 bg-primary100 text-primary700 text-xs font-medium me-2 2xl:px-3 px-1 2xl:py-2 py-0.5 rounded-2xl">
                VENTURE CAPITALIST
              </span>
            )}
          </div>

          <div className="flex justify-between">
            <div className="flex items-center justify-start gap-x-3">
              <Avatar avatarUrl={avatar || defaultProfile} size={64} />
              <div>
                <div style={{ fontSize: 16, fontWeight: "500" }}>{name}</div>
                {!tag && (
                  <p className="text-sm text-grey600 ">
                    <b>Fund:</b> {fund}
                  </p>
                )}
                <div className="flex mt-2 gap-x-2">
                  {linkedin && (
                    <Tooltip title="Linkedin">
                      <a href={`${linkedin}`} target="_blank" rel="noreferrer">
                        <FaLinkedin className="text-[#0A66C2] size-5 hover:text-primary cursor-pointer" />
                      </a>
                    </Tooltip>
                  )}

                  {fundwebsite && (
                    <Tooltip title="Website">
                      <a
                        href={
                          `${
                            fundwebsite
                              ? fundwebsite?.startsWith("www")
                                ? `https://${fundwebsite}`
                                : fundwebsite
                              : ""
                          }` || ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleCancel}
                      >
                        <TbWorldWww className="hover:text-primary cursor-pointer text-[#0A66C2] size-5" />
                      </a>
                    </Tooltip>
                  )}

                  {email && (
                    <Tooltip title="Email">
                      <a href={`mailto:${email}`}>
                        {/* <AiOutlineMail className='hover:text-primary cursor-pointer' /> */}
                        <img src={Gmail} alt="" />
                      </a>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>

            {/* <div className='flex flex-col gap-y-2 items-center'>
                        <div><img src={connect} alt={'connect'} /></div>
                        <div style={{ cursor: 'pointer' }} onClick={showModal}><img src={moreInfo} alt={'moreInfo'} /></div>
                    </div> */}
          </div>
          {stages && (
            <div className="flex flex-wrap items-center gap-1 w-full">
              <p className="text-sm w-1/6">Stages:</p>
              {stages?.split(",").map((item, index) => (
                <div
                  className="bg-[#BDDCFF] text-primary700 text-xs font-medium 2xl:px-1.5 px-1 py-0.5 rounded-2xl"
                  key={index}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </div>
              ))}
            </div>
          )}

          {sectors && (
            <div className="flex gap-1 w-full">
              <p className="text-sm w-1/6">Verticals: </p>
              <div className="flex flex-wrap items-center gap-1 w-full">
                {sectors.split(",").map((s, index) => (
                  <span
                    key={index}
                    className="bg-grey100 text-grey700"
                    style={{
                      borderRadius: 100,
                      fontSize: 12,
                      padding: "4px 12px",
                    }}
                  >
                    {s}
                  </span>
                ))}
              </div>
            </div>
          )}

          <hr />

          <div className="flex flex-col relative">
            <div className="flex gap-x-2 text-sm">
              <div className="flex">
                <p className="">Match level:</p>
              </div>

              <div className="flex">
                <Rating rating={parseInt(matchLevel)} maxRating={tag ? 4 : 6} />
              </div>
            </div>

            {/* <div className='w-full'>
                        <Progress percent={(parseInt(matchLevel) / 8) * 100} />

                    </div> */}
          </div>

          <div>
            <p className="text-sm text-grey700">Description</p>
            <p className="h-40 overflow-y-auto text-grey600">{Reason}</p>
          </div>
        </Skeleton>
        <Skeleton loading={loading} active={active}></Skeleton>
      </div>

      <Modal
        closeIcon={
          <IoClose className="size-6 absolute right-0 top-0 rounded-md bg-white text-black" />
        }
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        open={recommendationCard === index}
        onOk={handleOk}
        onCancel={handleCancel}
        className="lg:!w-1/2 !w-full"
      >
        <div className="flex h-full">
          <div className={` ${draftEmail ? "w-1/2" : "w-full"}`}>
            <div className="mt-10">
              <div className="flex justify-between">
                <div className="flex items-center justify-start gap-x-3">
                  <Avatar avatarUrl={avatar || defaultProfile} size={64} />
                  <div>
                    <div style={{ fontSize: 16, fontWeight: "500" }}>
                      {name}
                    </div>
                    {!tag && (
                      <p className="text-sm text-grey600 ">
                        <b>Fund:</b> {fund}
                      </p>
                    )}
                    <div className="flex mt-2 gap-x-2">
                      {linkedin && (
                        <Tooltip title="Linkedin">
                          <a
                            href={`${linkedin}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaLinkedin className="text-[#0A66C2] size-5 hover:text-primary cursor-pointer" />
                          </a>
                        </Tooltip>
                      )}

                      {fundwebsite && (
                        <Tooltip title="Website">
                          <a
                            href={
                              `${
                                fundwebsite
                                  ? fundwebsite?.startsWith("www")
                                    ? `https://${fundwebsite}`
                                    : fundwebsite
                                  : ""
                              }` || ""
                            }
                            target="_blank"
                            rel="noreferrer"
                            onClick={handleCancel}
                          >
                            <TbWorldWww className="hover:text-primary cursor-pointer text-[#0A66C2] size-5" />
                          </a>
                        </Tooltip>
                      )}

                      {email && (
                        <Tooltip title="Email">
                          <a href={`mailto:${email}`}>
                            {/* <AiOutlineMail className='hover:text-primary cursor-pointer' /> */}
                            <img src={Gmail} alt="" />
                          </a>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className='flex flex-col gap-y-2 items-center'>
    <div><img src={connect} alt={'connect'} /></div>
    <div style={{ cursor: 'pointer' }} onClick={showModal}><img src={moreInfo} alt={'moreInfo'} /></div>
</div> */}
              </div>
              {stages && (
                <div className="flex flex-wrap items-center gap-1 w-full mt-4">
                  <p className="text-sm w-1/6 text-black font-medium">
                    Stages:
                  </p>
                  {stages?.split(",").map((item, index) => (
                    <div
                      className="bg-[#BDDCFF] text-primary700 text-xs font-medium 2xl:px-1.5 px-1 py-0.5 rounded-2xl"
                      key={index}
                    >
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </div>
                  ))}
                </div>
              )}

              {sectors && (
                <div className="flex gap-1 w-full mt-4">
                  <p className="text-sm w-1/6 text-black font-medium">
                    Verticals:{" "}
                  </p>
                  <div className="flex flex-wrap items-center gap-1 w-full">
                    {sectors.split(",").map((s, index) => (
                      <span
                        key={index}
                        className="bg-grey100 text-grey700"
                        style={{
                          borderRadius: 100,
                          fontSize: 12,
                          padding: "4px 12px",
                        }}
                      >
                        {s}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              <hr className="my-4" />

              <div className="flex flex-col relative">
                <div className="flex gap-x-2 text-sm">
                  <div className="flex">
                    <p className="">Match level:</p>
                  </div>

                  <div className="flex">
                    <Rating
                      rating={parseFloat(matchLevel)}
                      maxRating={tag ? 4 : 6}
                    />
                  </div>
                </div>

                {/* <div className='w-full'>
    <Progress percent={(parseInt(matchLevel) / 8) * 100} />

</div> */}
              </div>

              <div className="mt-3">
                <p className="text-sm text-grey700">Description</p>
                <p className="h-40 overflow-y-auto text-grey600">{Reason}</p>
              </div>
            </div>

            <div className="flex items-center justify-center gap-x-7">
              {!draftEmail && (
                <Button
                  type="text"
                  className="h-12 border border-primary"
                  onClick={() => {
                    const toastId = toast.loading(
                      "Generating warm intro email"
                    );
                    gAnalytics("Generated warm intro email", user?.email || "User");
                    generateEmail({
                      user_id: user?.id,
                      investor_class: tag
                        ? "previous_offered_investors"
                        : "previous_offered_vcs",
                      investor_id: id,
                    })
                      .then((res) => {
                        setSubject(res?.data?.subject);
                        setBody(res?.data?.email);
                        toast.update(toastId, {
                          render: "Email Generated",
                          type: "success",
                          isLoading: false,
                          autoClose: 4000,
                        });
                        setDraftEmail(true);
                      })
                      .catch((err) => {
                        toast.update(toastId, {
                          render: err.toString() || "Failed to generate email",
                          type: "error",
                          isLoading: false,
                          autoClose: 4000,
                        });
                      })
                      .finally(() => {
                        toast.dismiss(toastId);
                      });
                  }}
                >
                  Draft Email
                </Button>
              )}
              {!shortlist && (
                <Button
                  className="bg-primary text-white h-12"
                  icon={<div>+</div>}
                  onClick={handleShortlist}
                >
                  Add to Shortlist
                </Button>
              )}
            </div>
          </div>
          {draftEmail && (
            <div className="w-1/2 h-full bg-primary50 p-2 border ml-2">
              <div
                className="flex items-center gap-x-3 cursor-pointer"
                onClick={() => handleCopy(`${subject} - ${body}`)}
              >
                <FiCopy />
                <p>{copySuccess}</p>
              </div>
              <div className="bg-white mt-3 max-h-[450px] overflow-y-scroll p-4">
                <p className="text-base font-bold mb-3">{subject}</p>
                <AIMessage content={body} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
