import React, { useState } from "react";
import LandingLayout from "../Layout";

import Terms from "../../../components/landing/termsAndPrivacy/Terms";
import Privacy from "../../../components/landing/termsAndPrivacy/Privacy";

function TermsAndPrivacy() {
  const [activeTab, setActiveTab] = useState("terms");

  return (
    <LandingLayout>
      <section className="xl:py-[80px] py-16 max-w-[900px] mx-auto px-7">
        <div className="flex flex-col items-center justify-center ">
          <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5 ">
            Updated: Nov 4, 2024
          </span>
          <h3 className="text-4xl font-semibold mt-3 xl:text-left text-center">
            Terms & Privacy Policy
          </h3>
          <p className="text-grey600 text-base font-regular mt-5 text-center">
            Your privacy is important to us at Untitled. We respect your privacy
            regarding any information we may collect from you across our
            website.
          </p>
        </div>

        <div className="mt-6 max-w-[900px] mx-auto xl:px-0 px-4 w-full">
          <div className="flex items-center justify-center">
            <div className="bg-gray-50 rounded-xl py-1.5 px-2 space-x-4 flex items-center">
              <button
                onClick={() => setActiveTab("terms")}
                className={`py-2.5 px-3.5 rounded-lg ${
                  activeTab === "terms"
                    ? "bg-white text-gray-700 shadow-sm"
                    : " text-gray-700"
                } hover:bg-gray-100 ${
                  activeTab === "terms" ? "hover:bg-white" : ""
                }`}
              >
                Terms
              </button>
              <button
                onClick={() => setActiveTab("privacy")}
                className={`py-2.5 px-3.5 rounded-lg ${
                  activeTab === "privacy"
                    ? "bg-white text-gray-700 shadow-sm"
                    : "text-gray-700"
                } hover:bg-gray-100 ${
                  activeTab === "privacy" ? "hover:bg-[#E4E8EC]" : ""
                }`}
              >
                Privacy Policy
              </button>
            </div>
          </div>

          <div className="mt-6">
            {activeTab === "terms" && <Terms />}
            {activeTab === "privacy" && <Privacy />}
          </div>
        </div>
      </section>
    </LandingLayout>
  );
}

export default TermsAndPrivacy;
