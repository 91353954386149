import React from 'react'
import Web from "../../../assets/svg/Web.svg"
import Info from "../../../assets/svg/Info.svg"
import Logo from "../../../assets/singleLogo.png"
import { Form, Input, Select, Button, Upload, Avatar, message } from 'antd';
import {
    GetCountries
} from "react-country-state-city";
import { Country } from '../tabs/demographicsInfo'
import Flag from "../../../assets/svg/summary/Flag.svg"
import Bulb from "../../../assets/svg/summary/Bulb.svg"
import Team from "../../../assets/team.png"
import Market from "../../../assets/svg/summary/Market.svg"
import UploadIcon from "../../../assets/svg/Upload.svg"
import Competitive from "../../../assets/svg/summary/Competitive.svg"
import Why from "../../../assets/svg/summary/Why.svg"
import type { UploadProps } from 'antd';
import { useAppSelector } from '../../../lib/hooks'
import { RootState } from '../../../lib/store'
import { shallowEqual } from 'react-redux'
import { errorMessage, successMessage } from '../../../constants/notification'
import { DocumentViewer } from 'react-documents';
import { useGetProfileImageQuery } from '../../../lib/features/user/userApiSlice';
import { FiUploadCloud } from 'react-icons/fi';
import { defaultProfile } from '../../../constants/defaults';
import { useGetPitchDeckQuery } from '../../../lib/features/pitch_deck/pitchDeck';
import { useGetTeamMembersQuery } from '../../../lib/features/startup/startupApiSlice';
import { BASE_URL } from '../../../lib/services/reAuth';


const { Option } = Select;

const { Dragger } = Upload;




function Overview({ setHash }: { setHash: any }) {
    const startupData = useAppSelector((state: RootState) => state.startup, shallowEqual)
    const userData = useAppSelector((state: RootState) => state.user, shallowEqual)
    const {access} = useAppSelector((state: RootState) => state.auth)



    // const [pitchDeckFile, setPitchDeckFile] = React.useState<string>("")
    const { data: pitchDeckFile, isLoading: pitchDeckIsLoading } = useGetPitchDeckQuery(userData.id)

    const { data: getProfileImage, refetch: getProfileImageRefetch } = useGetProfileImageQuery({ userId: userData?.id })



    const summary: any[] = [
        {
            id: 1,
            title: "Problem",
            icon: Flag,
            content: startupData.problem,
            assistant: ["Elite mentors' importance?", "Challenges without guidance?", "Impact on long-term success?"],

        },
        {
            id: 2,
            title: "Solution",
            icon: Bulb,
            content: startupData.solution,
            assistant: ["AI mentoring effectiveness?", "AI vs. human mentors?", "AI for early/late-stage?"],
        },
        {
            id: 3,
            title: "Market",
            icon: Market,
            content: startupData.market,
            assistant: ["Focus on SMBs?", "Defining diverse founders?", "Reaching global SMBs?"],
        },
        {
            id: 4,
            title: "Competitive advantage",
            icon: Competitive,
            content: startupData.competitive_advantages,
            assistant: ["Elite mentors' importance?", "Challenges without guidance?", "Impact on long-term success?"],
        },
        {
            id: 5,
            title: "Why is this a good investment case?",
            icon: Why,
            content: startupData.good_investment_case,
            assistant: ["Elite mentors' importance?", "Challenges without guidance?", "Impact on long-term success?"],
        },
    ]

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        action: `${BASE_URL}users/upload-image/${userData?.id}/`,
        headers:{
            'Authorization': `Bearer ${access}`
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                getProfileImageRefetch()
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tags = ["United States", "Enterprise Software", "AI", "Community", "B2B2C", "Software"]

    const [form] = Form.useForm();
    const [countriesList, setCountriesList] = React.useState<Country[]>([]);



    React.useEffect(() => {
        GetCountries().then((result: any) => {
            setCountriesList(result);
        });
    }, []);

    const CountEmptyItems = React.memo(({ list }: { list: string[] }) => {
        const countEmptyItems = list.filter((item: string) => item === "").length;
        return countEmptyItems > 0 ? (
            <div className='flex gap-x-1'>
                <img src={Info} alt="" />
                <p className='text-[#FF434E]'>{countEmptyItems} missing elements</p>
            </div>
        ) : null;
    });


    const { data: teamsData, refetch: refetchTeamData } = useGetTeamMembersQuery({ startupId: startupData?.id, userId: userData?.id })

    console.log(teamsData)

    const handleCopy = async (textToCopy: string) => {
        try {
          await navigator.clipboard.writeText(textToCopy);
          successMessage("Copied!");
        } catch (err) {
          errorMessage("Failed to copy!");
        }
      };





    const initialValues = {
        name_of_start_up: startupData?.name_of_start_up || "",
        about_start_up: startupData?.about_start_up || "",
        country: userData?.country || "",
    }
    return (
        <div className='mt-6 h-[90vh] overflow-y-auto'>
            <h2 className='text-xl font-medium'>Overview</h2>
            {/* Overview */}
            <section className='w-full border-[#E9ECEF] mt-6'>
                <div className='flex flex-col xl:flex-row'>
                    <div className='xl:w-1/5'>
                        <h6 className='text-sm font-semibold text-grey700'>Startup Profile Picture</h6>
                    </div>
                    <div className='xl:w-3/5 flex gap-x-5 items-start'>

                        <div className=''>
                            <Avatar className={'selectedImage'} size={72} src={getProfileImage?.url || defaultProfile} />
                        </div>

                        <Dragger {...props} className='bg-transparent w-full'>
                            <div className='ant-upload-drag-icon flex w-full justify-center items-center '>
                                <div className='size-10 bg-grey50 rounded-full flex justify-center items-center'>
                                    <FiUploadCloud size={20} />
                                </div>
                            </div>
                            <p className="ant-upload-text"><span className='text-primary700 font-semibold'>Click to upload</span> or drag and drop</p>
                            <p className="ant-upload-hint">
                                SVG, PNG, JPG
                            </p>
                        </Dragger>
                    </div>
                </div>

            </section>

            {/* Startup Information */}
            <section className='w-full border-[#E9ECEF] mt-8'>
                <div className='flex flex-col xl:flex-row'>
                    <div className='xl:w-1/5'>
                        <h6 className='text-sm font-semibold text-grey700'>Startup Information</h6>
                    </div>
                    <div className='xl:w-3/5 flex gap-x-5 items-start'>

                        <Form
                            className='w-full'
                            form={form}
                            layout="vertical"
                            onFinish={() => { }}
                            initialValues={initialValues}

                        >

                            <div className='flex flex-col md:flex-row gap-x-5'>
                                <div className='w-full'>


                                    <div className="w-full grid md:grid-cols-2 gap-x-5">
                                        <Form.Item
                                            name="name_of_start_up"
                                            label="Startup Name"

                                        >
                                            <Input placeholder="Name of Start-Up" disabled className='h-11' />
                                        </Form.Item>

                                        <Form.Item
                                            name="country"
                                            label="Country"
                                            rules={[{ required: true }]}

                                        >
                                            <Select placeholder="Select Country"
                                                onChange={(e) => {
                                                }}
                                                disabled
                                                className='h-11'
                                            >
                                                <Option value={""} >Select country</Option>
                                                {countriesList.map((country: any, index: number) => (
                                                    <Option key={index} value={country?.id}>{country?.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="name_of_start_up"
                                            label="Website"
                                            rules={[{ required: true, message: 'Please input the name of your start-up!' }]}

                                        >
                                            <Input addonBefore={<p>https://</p>} placeholder="Website" disabled className='h-11' />
                                        </Form.Item>

                                        <Form.Item
                                            name="currency"
                                            label="Currency"
                                            rules={[{ required: true }]}

                                        >
                                            <Select placeholder="Select Currency"
                                                onChange={(e) => {

                                                }}
                                                disabled
                                                className='h-11'
                                            >
                                                <Option value={"USDollar"} >Select country</Option>
                                                {["USDollar"].map((curr: any, index: number) => (
                                                    <Option key={index} value={curr}>{curr}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                </div>
                            </div>


                            <div className=''>
                                <Form.Item
                                    name="about_start_up"
                                    label=""

                                    rules={[{ required: false, message: 'Please input the name of your start-up!' }]}

                                >
                                    <Input.TextArea placeholder="Generative AI-Powered mentorship and networking platform for entrepreneurs." disabled rows={6} maxLength={500} />
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>

            </section>

            {/* Pitch deck */}
            <section className='w-full border-[#E9ECEF] my-8'>
                <div className='flex flex-col xl:flex-row'>
                    <div className='xl:w-1/5'>
                        <h6 className='text-sm font-semibold text-grey700'>Pitch Deck</h6>
                    </div>
                    <div className='xl:w-3/5 flex gap-x-5 items-start'>
                        <div className='grid md:grid-cols-2 gap-6 w-full'>

                            <div className='w-full p-6 border border-[#E8E8E8] rounded-md  max-h-[290px] overflow-y-auto'>
                                {!pitchDeckIsLoading &&
                                    pitchDeckFile ?
                                    <DocumentViewer
                                        url={pitchDeckFile?.file_path}
                                        viewer="url"
                                        viewerUrl={'https://docs.google.com/gview?url=%URL%&embedded=true'}
                                    />
                                    :
                                    <div className='flex items-center justify-center h-full'>
                                        <p className='text-2xl font-bold'>No pitch deck uploaded yet</p>
                                    </div>
                                }
                            </div>

                            <div className='w-full flex flex-col gap-y-3'>
                                <div className='w-full rounded-md'>
                                    <Form
                                        layout="vertical"
                                        className='w-full'
                                        initialValues={{
                                            share_pitch: pitchDeckFile?.file_path
                                        }}
                                    >
                                        <div className='flex gap-x-1 items-end w-full'>
                                            <Form.Item
                                                name="share_pitch"
                                                className='w-full'
                                                label="Share your pitch"
                                                rules={[{ required: false, message: 'Please input the name of your start-up!' }]}

                                            >
                                                <Input placeholder="" disabled />

                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="text" htmlType="submit" className='bg-primary600 text-white' onClick={() => { handleCopy(pitchDeckFile?.file_path) }}>
                                                    Copy Link
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </div>

                                <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10'>
                                    <p className='text-sm font-normal'>Views</p>
                                    <p className='text-4xl text-grey900 font-semibold mt-4'>0</p>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <hr />

            {/* Fund Raising */}
            <section className='w-full border-[#E9ECEF] my-8'>
                <div className='flex flex-col xl:flex-row'>
                    <div className='xl:w-1/5'>
                        <h6 className='text-sm font-semibold text-grey700'>Fundraising</h6>
                    </div>
                    <div className='xl:w-3/5 flex flex-col gap-5 items-start'>
                        <div className='flex flex-row gap-x-3 w-full'>
                            <div className='w-2/3 p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10'>
                                <p className='text-base font-semibold'>Views</p>
                                <p className='text-4xl text-grey900 font-semibold mt-4'>0</p>
                            </div>
                            <div className='w-1/3 p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10'>
                                <p className='text-base font-semibold'>Current Road</p>
                                <p className='text-4xl text-grey900 font-semibold mt-4'>-</p>
                            </div>
                        </div>
                        <div className='flex flex-row gap-x-3 w-full'>
                            <div className='w-1/3 p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10'>
                                <p className='text-base font-semibold'>Capital Seeking</p>
                                <p className='text-4xl text-grey900 font-semibold mt-4'>$3.5m</p>
                            </div>
                            <div className='w-1/3 p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10'>
                                <p className='text-base font-semibold'>Pre Money Evaluation</p>
                                <p className='text-4xl text-grey900 font-semibold mt-4'>$0</p>
                            </div>
                            <div className='w-1/3 p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10'>
                                <p className='text-base font-semibold'>Already Raised</p>
                                <p className='text-4xl text-grey900 font-semibold mt-4'>$0</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <hr />

            {/* Executive Summary */}
            <section className='w-full border-[#E9ECEF] my-8'>
                <div className='flex flex-col xl:flex-row'>
                    <div className='xl:w-1/5'>
                        <h6 className='text-sm font-semibold text-grey700'>Executive Summary</h6>
                    </div>
                    <div className='xl:w-3/5 flex flex-col gap-5 items-start'>
                        <div className='w-full rounded-md  space-y-6 '>
                            {summary.map((item, index) => (
                                <div className='flex flex-col gap-y-3' key={index}>
                                    <div className='flex gap-x-2 items-center'>
                                        <p className='text-sm font-normal'>{item.title}</p>
                                        <img src={item.icon} alt="" className='size-5' />
                                    </div>
                                    <div className='border px-3 py-2 rounded-md border-[#E8E8E8] text-sm text-[#4F4F4F] h-32 overflow-scroll'>
                                        <p className='text-sm font-normal'>{item.content}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </section>

            <hr />

            {/* Transactions */}
            <section className='w-full border-[#E9ECEF] my-8'>
                <div className='flex flex-col xl:flex-row'>
                    <div className='xl:w-1/5'>
                        <h6 className='text-sm font-semibold text-grey700'>Transactions</h6>
                    </div>
                    <div className='xl:w-3/5 flex flex-row gap-5 items-start'>
                        <div className='w-full rounded-md  flex flex-col md:flex-row gap-3 justify-between'>

                            <div className='w-full p-6 border border-[#E8E8E8] rounded-md  flex flex-col justify-center gap-y-5'>
                                <p className='text-xs'>MRR</p>
                                <p className='text-sm text-[#5FA3F0] font-bold'>{startupData.monthly_recurring_revenue || 0}</p>
                            </div>

                            <div className='w-full p-6 border border-[#E8E8E8] rounded-md  flex flex-col justify-center gap-y-5'>
                                <p className='text-xs'>Private funds raised before current round</p>
                                <p className='text-sm text-[#5FA3F0] font-bold'>{startupData.private_fund_raised_bcr || 0}</p>
                            </div>

                            <div className='w-full p-6 border border-[#E8E8E8] rounded-md  flex flex-col justify-center gap-y-5'>
                                <p className='text-xs'>Public funds raised before current round</p>
                                <p className='text-sm text-[#5FA3F0] font-bold'>{startupData.public_fund_raised_bcr || 0}</p>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <hr />
            {/* Team */}
            <section className='w-full border-[#E9ECEF] my-8'>
                <div className='flex flex-col xl:flex-row'>
                    <div className='xl:w-1/5'>
                        <h6 className='text-sm font-semibold text-grey700'>Team Members</h6>
                    </div>
                    <div className='xl:w-3/5 flex flex-row gap-5 items-start'>
                        <div className='space-y-3'>
                            {teamsData?.map((item: any, index: number) => (
                                <div className='flex flex-col md:flex-row justify-between gap-2 items-center'>
                                    <div className='flex gap-3 items-center'>
                                        <img src={item?.profile_image_url !== "None" ? item.profile_image_url : defaultProfile} alt="" className='size-20 rounded-full' />
                                        <div className='flex flex-col gap-y-1'>
                                            <p className='text-base font-semibold text-grey700'>{item.name} </p>
                                            <p className='text-xs'>{item.position}</p>
                                            {/* <p className='text-xs'>{item.bio}</p> */}
                                            {/* <p className='text-sm text-white bg-primary w-max py-0.5 px-2 rounded-2xl'>Owner</p> */}
                                        </div>
                                    </div>

                                </div>
                            ))}

                        </div>
                    </div>
                </div>

            </section>


            {/* Attachment */}
            {/* <section className='mt-7 border-b w-full border-[#E9ECEF]'>
                <div className='flex gap-x-3 items-center justify-between'>
                    <h2 className='text-xl font-medium'>Attachments</h2>
                    <Button size="large" className='text-primary px-8 py-3.5'>
                        View
                    </Button>
                </div>

                <div className='mt-7 grid md:grid-cols-2 gap-4'>

                    <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 '>
                        <Dragger {...props}>
                            <div className='flex items-center justify-center mb-2'>
                                <img src={UploadIcon} alt="" />
                            </div>
                            <p className="ant-upload-hint">
                                Upload or Drag and drop your files here
                            </p>
                        </Dragger>
                    </div>

                    <div className='flex flex-col gap-y-3'>
                        <div className='w-full py-4 px-5 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 '>
                            <Form
                                layout="vertical"
                                className='w-full'
                            >
                                <div className='flex gap-x-1 items-end w-full'>
                                    <Form.Item
                                        name="name_of_start_up"
                                        className='w-full'
                                        label="Add External link"

                                    >
                                        <Input addonBefore={<p>https://</p>} placeholder="" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="text" htmlType="submit" className='bg-black text-white'>
                                            Add Link
                                        </Button>
                                    </Form.Item>
                                </div>
                                <div className='w-full'>
                                    <Form.Item>
                                        <Button type="text" htmlType="submit" className='bg-[#5FA3F0] text-white w-full'>
                                            Add more
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>

                </div>
                <div className=' mt-5'></div>
            </section> */}


        </div>
    )
}

export default Overview