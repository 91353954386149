import React from "react";

function Terms() {
  return (
    <section className="space-y-12 my-10">
      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Introduction
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          Welcome to MyCofoundr.AI, a cutting-edge platform proudly developed
          and operated by FounderTribes Inc. (“FounderTribes”). By accessing or
          using our website, mobile applications, and services (collectively,
          the "Services"), you agree to comply with and be bound by these Terms
          of Service ("Terms"). If you disagree with any part of these Terms,
          you must not use our Services.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Definition
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          <ul>
            <li>
              "User," "you," and "your" refer to individuals or entities
              accessing or using the Services.
            </li>
            <li>
              "MyCofoundr.AI," “FounderTribes”, "we," "us," and "our" refer to
              the company operating the Services.
            </li>
            <li>
              "Content" refers to all information, data, text, software, music,
              sound, photographs, graphics, video, messages, or other materials
              available through the Services.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Eligibility
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          You must be at least 18 years old to use our Services. By using the
          Services, you represent and warrant that you have the right,
          authority, and capacity to enter into these Terms and to abide by all
          of the terms and conditions set forth herein.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Use of Services
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          MyCofoundr.AI provides AI-driven tools and recommendations to help
          startups get AI-powered business advice and connect with relevant
          funders. Our platform's features are intended solely for informational
          purposes to assist in identifying potential funding sources.
          <br />
          You agree to:
          <ul className="list-disc mt-2 ml-5">
            <li>
              Use the Services only for lawful purposes and in accordance with
              these Terms.
            </li>
            <li>
              Not use the Services in any way that violates any applicable
              federal, state, local, or international law or regulation.
            </li>
            <li>
              Not attempt to gain unauthorized access to, interfere with,
              damage, or disrupt any parts of the Services, the server on which
              the Services are stored, or any server, computer, or database
              connected to the Services.
            </li>
            <li>
              Not use any robot, spider, or other automatic device, process, or
              means to access the Services for any purpose, including monitoring
              or copying any of the material on the Services.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          User Accounts
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          To access certain features of MyCofoundr.AI, you must create an
          account. You are responsible for:
          <ul className="list-disc mt-2 ml-5">
            <li>
              Providing accurate, current, and complete information during the
              registration process.
            </li>
            <li>
              Maintaining the confidentiality of your account and password.
            </li>
            <li>Restricting access to your computer or mobile device.</li>
            <li>
              Accepting responsibility for all activities that occur under your
              account or password.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Subscription and Billing
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          MyCofoundr.AI offers both free trials and paid subscriptions. By
          subscribing to a paid plan:
          <ul className="list-disc mt-2 ml-5">
            <li>
              You agree to pay all fees associated with your chosen subscription
              plan.
            </li>
            <li>
              Subscription fees are non-refundable once the service period
              begins, except as otherwise provided by applicable law.
            </li>
            <li>
              You authorize us to charge the designated payment method for all
              applicable fees.
            </li>
            <li>
              You agree to promptly update your account information with any
              changes in your payment method.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Intellectual Property Rights
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          The Services and their entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof) are owned by FounderTribes, its licensors, or
          other providers of such material and are protected by United States
          and international copyright, trademark, patent, trade secret, and
          other intellectual property or proprietary rights laws.
          <br />
          These Terms permit you to use the Services for your personal,
          non-commercial use only. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store, or transmit any of the material on our
          Services, except as follows:
          <ul className="list-disc mt-2 ml-5">
            <li>
              Your computer may temporarily store copies of such materials
              incidental to your accessing and viewing those materials.
            </li>
            <li>
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
            </li>
            <li>
              You may print or download one copy of a reasonable number of pages
              of the Services for your own personal, non-commercial use and not
              for further reproduction, publication, or distribution.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          User Contributions
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          The Services may contain Interactive Services that allow users to post
          content or materials. All User Contributions must comply with the
          Content Standards set out in these Terms. Any User Contribution will
          be considered non-confidential and non-proprietary.
          <br />
          All User Contributions must comply with the Content Standards set out
          in these Terms. Any User Contribution you post to the site will be
          considered non-confidential and non-proprietary. By providing any User
          Contribution on the Services, you grant us and our affiliates and
          service providers, and each of their and our respective licensees,
          successors, and assigns the right to use, reproduce, modify, perform,
          display, distribute, and otherwise disclose to third parties any such
          material for any purpose.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Prohibited Uses
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          You may use the Services only for lawful purposes and in accordance
          with these Terms. You agree not to use the Services:
          <ul className="list-disc mt-2 ml-5">
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation.
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information, or
              otherwise.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any "junk mail," "chain letter,"
              "spam," or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate MyCofoundr.AI, a
              FounderTribes employee, another user, or any other person or
              entity.
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Services, or which, as determined by us,
              may harm MyCofoundr.AI or users of the Services or expose them to
              liability.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Monitoring and Enforcement; Termination{" "}
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          We have the right to:
          <ul className="list-disc mt-2 ml-5">
            <li>
              Remove or refuse to post any User Contributions for any or no
              reason in our sole discretion.
            </li>
            <li>
              Take any action with respect to any User Contribution that we deem
              necessary or appropriate in our sole discretion.
            </li>
            <li>
              Terminate or suspend your access to all or part of the Services
              for any or no reason, including without limitation, any violation
              of these Terms.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Disclaimer of Warranties
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Services will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data.
          <br />
          <br />
          TO THE FULLEST EXTENT PROVIDED BY LAW, WE DISCLAIMS ALL WARRANTIES OF
          ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
          INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
          NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Limitation on Liability
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL FOUNDERTRIBES,
          ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
          UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE,
          OR INABILITY TO USE, THE SERVICES, ANY WEBSITES LINKED TO IT, ANY
          CONTENT ON THE SERVICES OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
          INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
          EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
          OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
          AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
          OR OTHERWISE, EVEN IF FORESEEABLE.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Indemnification
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
        You agree to defend, indemnify, and hold harmless FounderTribes, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Services.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Governing Law and Jurisdiction
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
        These Terms and any dispute or claim arising out of or related to them, their subject matter, or their formation (in each case, including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of the State of Delaware, without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising out of, or related to, these Terms or the Services shall be instituted exclusively in the federal courts of the United States or the courts of the State of Delaware, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms in your country of residence or any other relevant country.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Waiver and Severability
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
        No waiver by FounderTribes of any term or condition set out in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of FounderTribes to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.
        <br />
        <br />
        If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Entire Agreement
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
        The Terms constitute the sole and entire agreement between you and FounderTribes regarding the Services and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Services.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
        Changes to Terms of Service        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
        We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Services thereafter. Your continued use of the Services following the posting of revised Terms means that you accept and agree to the changes.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
        Contact Information </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
        Questions or comments about the Services or these Terms may be directed to our support team at <a href="mailto:gary.stewart@mycofoundr.ai">gary.stewart@mycofoundr.ai.</a>
        </p>
      </div>
    </section>
  );
}

export default Terms;
