import React, { FC } from "react";
import { useLocation } from "react-router-dom";

interface IProps {
  label: string;
  Icon: any;
  onClick: () => void;
  isDisabled: boolean;
  disabledIcon: string;
  isAuthenticated: boolean;
  path: string;
  isSidebarOpen?: boolean;
}

export const SideMenuItem: FC<IProps> = ({
  Icon,
  label,
  onClick,
  isDisabled,
  disabledIcon,
  isAuthenticated,
  path,
  isSidebarOpen,
}) => {
  const location = useLocation();
  return isAuthenticated ? (
    <div
      className={`relative z-0 mt-1 w-full  flex items-center rounded-lg hover:border-b hover:border-primary50 ${
        location.pathname === path
          ? isSidebarOpen
            ? "bg-primary50"
            : "bg-primary600"
          : ""
      }`}
    >
      <button
        disabled={isDisabled}
        onClick={onClick}
        className={`flex items-center ${
          isSidebarOpen ? "justify-start" : "justify-center"
        } gap-2 py-2.5 px-3 rounded-lg`}
      >
        <div className="">
          <Icon
            className={`size-6 ${
              location.pathname === path
                ? isSidebarOpen
                  ? "text-primary700"
                  : "text-white "
                : "text-white"
            } `}
          />
        </div>
        {isSidebarOpen && (
          <div
            className={`relative text-md font-semibold ${
              location.pathname === path
                ? isSidebarOpen
                  ? "text-primary700"
                  : "text-white "
                : "text-white"
            } `}
          >
            {label}
          </div>
        )}
      </button>
    </div>
  ) : null;
};
