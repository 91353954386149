import React, { useState } from 'react'
import { useAppSelector } from '../../../lib/hooks'
import { RootState } from '../../../lib/store'
import { useGetFunnelInvestorsQuery } from '../../../lib/features/recommendations/recommendations'
import { dummyReason } from './Overview'
import Kofi from "../../../assets/KofiAmpadu.jpg"
import Shashi from "../../../assets/ShashiSrikantan.jpg"
import Fabian from "../../../assets/Fabian.png"
import { InvestorCard } from '../../Recommendation/components/InvestorCard'
import { defaultProfile } from '../../../constants/defaults'
import Loading from '../../../components/Loading'


function ReachedOut() {
    const user = useAppSelector((state: RootState) => state.user)
    const [recommendationCard, setRecommendationCard] = useState<any>(null)


    const { data: vcRecommendations, isLoading } = useGetFunnelInvestorsQuery(user?.id, { refetchOnMountOrArgChange: true })
    const shortlist = vcRecommendations?.shortlist || []

    console.log(vcRecommendations?.reached_out)

    if (isLoading) return <Loading />
    return (
        <div>
            <h1 className='text-xl font-semibold mt-6'>Reached Out</h1>

            {
                vcRecommendations?.reached_out.length > 0 ? (
                    <div className='grid 2xl:grid-cols-3 xl:grid-cols-2 mt-4' >
                        {[...vcRecommendations?.reached_out].sort((a: any, b: any) => b.Score - a.Score).map((key: any, index: number) => {

                            const recommendation = key;
                            return (
                                <InvestorCard
                                    key={index}
                                    index={index}
                                    id={recommendation?.id}
                                    // avatar={defaultProfile}
                                    avatar={recommendation?.id.toString().includes('vc') ? recommendation?.metadata?.ProfilepicturesinS3 : !recommendation?.id.toString().includes('vc') ? recommendation?.metadata?.ProfilepictureinS3 : defaultProfile}
                                    Reason={recommendation.Reason}
                                    name={recommendation.Name}
                                    fund={recommendation.FundName}
                                    stages={recommendation?.metadata?.Stageofinvestment}
                                    sectors={recommendation?.metadata?.Sector}
                                    email={recommendation?.metadata?.Email}
                                    matchLevel={recommendation?.Score.toString()}
                                    bio={recommendation?.metadata?.Longerbio}
                                    fundwebsite={recommendation?.metadata?.Fundwebsite}
                                    linkedin={recommendation?.metadata?.LinkedInprofile}
                                    tag={recommendation?.id.toString().includes('vc') ? false : true}
                                    loading={false}
                                    active={true}
                                    setRecommendationCard={setRecommendationCard}
                                    recommendationCard={recommendationCard}
                                    shortlist={shortlist.includes(recommendation?.id)}

                                />
                            );
                        })}
                        {/* {[...vcRecommendations?.reached_out].sort((a: any, b: any) => b.Score - a.Score).slice(0, 50).map((key: any, index: number) => {

                            const recommendation = key;
                            return (
                                <InvestorCard
                                    key={index - vcRecommendations?.reached_out.length}
                                    index={index - vcRecommendations?.reached_out.length}
                                    // avatar={defaultProfile}
                                    tag={true}
                                    avatar={recommendation?.metadata?.ProfilepictureinS3 || defaultProfile}
                                    Reason={recommendation.Reason}
                                    name={recommendation.Name}
                                    fund={recommendation.FundName}
                                    stages={recommendation?.metadata?.Stageofinvestment}
                                    sectors={recommendation?.metadata?.Sector}
                                    email={recommendation?.metadata?.Email}
                                    matchLevel={recommendation?.Score.toString()}
                                    bio={recommendation?.metadata?.Longerbio}
                                    fundwebsite={recommendation?.metadata?.Fundwebsite}
                                    linkedin={recommendation?.metadata?.LinkedInprofile}
                                    loading={false}
                                    active={true}
                                    setRecommendationCard={setRecommendationCard}
                                    recommendationCard={recommendationCard}
                                />
                            );
                        })} */}

                    </div>
                ) :

                    (
                        <>
                            <div className='flex items-center h-[50vh] justify-center mt-10'>
                                No Reached out Investors yet
                            </div>
                        </>
                    )
            }

        </div>
    )
}

export default ReachedOut
