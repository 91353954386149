import LandingLayout from "../Layout";
import { Check } from "lucide-react";
import MostPopular from "../../../assets/popular.png";
import { Link } from "react-router-dom";

interface PricingFeature {
  text: string;
}

interface PricingCardProps {
  title: string;
  price: string;
  period: string;
  subtitle: string;
  description?: string;
  features: PricingFeature[];
  isPopular?: boolean;
}

const pricing: PricingCardProps[] = [
  {
    title: "Annual Plan",
    price: "$49.99/month",
    period: "Billed annually",
    subtitle: "Annual Plan",
    features: [
      { text: "Unlimited funder matches" },
      { text: "Full access to contact details for each matched funder" },
      { text: "Unlimited AI fundraising co-pilot" },
      { text: "Fundraising analytics and tracking" },
      { text: "AI-powered pitch deck review" },
      { text: "Alerts for funders and relevant events" },
      {
        text: "Free 7-day trial (up to 5 AI funder matches + limited pitch deck review)",
      },
    ],
    isPopular: true,
  },
  {
    title: "Monthly Plan",
    price: "$99/month",
    period: "Billed monthly",
    subtitle: "Monthly Plan",
    features: [
      { text: "25 AI-based investor matches monthly" },
      { text: "Full access to contact details for each matched funder" },
      { text: "Unlimited AI fundraising co-pilot" },
      { text: "Fundraising analytics and tracking" },
      { text: "AI-powered pitch deck review" },
      { text: "Alerts for funders and relevant events" },
      {
        text: "Free 7-day trial (up to 5 AI funder matches + limited pitch deck review)",
      },
    ],
  },
  {
    title: "Enterprise plan",
    price: "Custom Pricing",
    period: "Enterprise plan",
    subtitle: "For scale-ups, accelerators, investors, and vendors:",
    features: [
      { text: "Unlimited funder matches" },
      { text: "Team access with discounted plans" },
      { text: "Full access to contact details for each matched funder" },
      { text: "Fundraising analytics and tracking" },
      { text: "Custom AI-powered fundraising solutions" },
      { text: "AI-powered pitch deck review" },
      { text: "Personalized support" },
    ],
  },
];

function Pricing() {
  return (
    <LandingLayout>
      {/* pricing table */}
      <section className="xl:py-[80px] py-16 max-w-[1440px] mx-auto px-7">
        <div className="xl:block flex flex-col items-center justify-center ">
          <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5 ">
            Pricing
          </span>
          <h3 className="text-4xl font-semibold mt-3 xl:text-left text-center">
            Start Your Free Trial Today!
          </h3>
          <p className="text-grey600 text-xl font-regular mt-5 text-center xl:text-left">
            Sign up now and save 50% with our annual plan, No credit card
            required!
          </p>
        </div>

        <div className="mt-24 max-w-[1440px] mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-16">
            {pricing.map(
              (
                {
                  isPopular,
                  price,
                  period,
                  title,
                  subtitle,
                  description,
                  features,
                }: PricingCardProps,
                index
              ) => {
                return (
                  <div className="relative flex flex-col p-8 bg-white rounded-2xl shadow-lg shadow-grey200 border border-gray-100 hover:border-blue-500 transition-all duration-300">
                    {isPopular && (
                      <div className="absolute -top-8 xl:-right-16 right-0 ">
                        <img src={MostPopular} className="h-[50px]" alt="" />
                      </div>
                    )}

                    <div className="mb-6 text-center xl:text-left">
                      <h3 className="text-3xl font-bold mb-1 ">{price}</h3>
                      <p className="text-gray-600 font-medium">{period}</p>
                    </div>

                    <h4 className="text-xl font-semibold mb-1 text-center xl:text-left">
                      {title}
                    </h4>
                    <p className="text-sm text-gray-600 mb-6 text-center xl:text-left">
                      {subtitle}
                    </p>

                    {description && (
                      <p className="text-sm text-gray-600 mb-6">
                        {description}
                      </p>
                    )}

                    <div className="flex-grow">
                      <ul className="space-y-4">
                        {features.map((feature, index) => (
                          <li key={index} className="flex items-start gap-3">
                            <div className="bg-green-100 rounded-full p-0.5 flex items-center justify-center">
                              <Check className="w-3 h-3 text-green-500 flex-shrink-0 mt-0.5" />
                            </div>
                            <span className="text-gray-600 text-sm">
                              {feature.text}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <Link
                      to={"/login"}
                      className="mt-8 w-full bg-primary600 hover:bg-primary text-white py-3 px-6 rounded-lg font-medium transition-colors duration-200 text-center"
                    >
                      Get started
                    </Link>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>
    </LandingLayout>
  );
}

export default Pricing;
