import React from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "../components/Navbar/Navbar";
import { SidebarProvider } from "../hooks/use-sidebar";


// import Topbar from '../components/Navbar/Topbar';

const Layout = ({ showNavbar }: { showNavbar?: boolean }) => {
  

  return (
    <SidebarProvider>
      {/* <Topbar /> */}
      <div className="lg:flex h-[screen] bg-white overflow-hidden">
        {showNavbar && <Navbar />}

        <div className="rounded-2xl w-full shadow-[0px 10px 15px -3px rgba(0,0,0,0.1)] peer-[[data-state=open]]:lg:ml-[320px] peer-[[data-state=closed]]:lg:ml-[90px]  peer-[[data-state=closed]]:opacity-100 peer-[[data-state=closed]]:lg:opacity-100 peer-[[data-state=open]]:lg:opacity-100 transition-all">
          <Outlet />
        </div>
      </div>
    </SidebarProvider>
  );
};

export default Layout;
