import React from 'react'
import { MdStar, MdStarHalf, MdStarOutline } from "react-icons/md";


function Rating({ rating, maxRating }: { rating: number, maxRating: number }) {

    const activeCount = Math.floor(rating);
    const hasHalf = rating % 1 !== 0;
    const inactiveCount = maxRating - Math.ceil(rating);



    let images = [];

    // for (let i = 0; i < activeCount; i++) {
    //     images.push(<MdStar />);
    // }

    for (let i = 0; i < activeCount; i++) {
        images.push(<MdStar key={`active_${i}`} className='size-6 text-[#F3E931]' />);
    }

    if (hasHalf) {
        images.push(<MdStarHalf key="half" className='size-6 text-[#F3E931]' />);
    }

    for (let i = 0; i < inactiveCount; i++) {
        images.push(<MdStar key={`inactive_${i}`} className='size-6 text-[#D9D9D9]' />);
    }
    return (
        <div className='flex flex-row gap-x-1 items-center'>
            {images.map((image, index) => (
                <React.Fragment key={index}>
                    {image}
                </React.Fragment>
            ))}
            {/* <span className='bg-primary100 text-primary700 text-xs font-medium px-1 py-0.5 rounded-2xl'>{Math.round((rating / maxRating) * 100)}%</span> */}
        </div>
    )
}

export default Rating
