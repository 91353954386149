import { apiSlice } from "../../services/apiSlice";


export const recommendationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRecommendations: builder.query({
            query: (user_id) => ({
                url: `semantic/get_recommendation/${user_id}`,
                method: 'GET',
            })
        }),
        getFunnelInvestors: builder.query({
            query: (user_id) => ({
                url: `funnel/get_investors/${user_id}`,
                method: 'GET',
            })
        }),
        moveInvestors: builder.mutation({
            query: (data) => ({
                url: `funnel/switch_investors/`,
                method: 'POST',
                body: data
            })
        }),
        shortlistInvestors: builder.mutation({
            query: (data) => ({
                url: `funnel/shortlist/${data.user_id}`,
                method: 'POST',
                body: data
            })
        }),
    })
})

export const {
    useGetRecommendationsQuery,
    useGetFunnelInvestorsQuery,
    useMoveInvestorsMutation,
    useShortlistInvestorsMutation
} = recommendationsApiSlice