import { useState } from "react";
import Phone from "../../../assets/svg/team/Phone.svg";
import { Button } from "antd";
import { Form, Input } from "antd";
import { useAppSelector } from "../../../lib/hooks";
import { RootState } from "../../../lib/store";
import { shallowEqual } from "react-redux";
import {
  useAddTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useGetTeamMembersQuery,
  useUpdateTeamMemberMutation,
} from "../../../lib/features/startup/startupApiSlice";
import { errorMessage, successMessage } from "../../../constants/notification";
import { Popconfirm, message, Upload } from "antd";
import type { GetProp, UploadProps, PopconfirmProps } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../lib/services/reAuth";
import Gmail from "../../../assets/svg/gmail.svg";
import { FaLinkedin } from "react-icons/fa";
import { FiEdit, FiTrash2 } from "react-icons/fi";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

function Team() {
  const [form] = Form.useForm();
  const [teamMemberId, setTeamMemberId] = useState("");
  const user = useAppSelector((state: RootState) => state.user, shallowEqual);
  const startupData = useAppSelector(
    (state: RootState) => state.startup,
    shallowEqual
  );
  const token = useAppSelector((state: RootState) => state.auth.access);
  const [addTeamMember] = useAddTeamMemberMutation();
  const { data, refetch } = useGetTeamMembersQuery({
    startupId: startupData?.id,
    userId: user?.id,
  });
  const [deleteTeamMember] = useDeleteTeamMemberMutation();
  const [updateTeamMember] = useUpdateTeamMemberMutation();

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const confirm = (e: any, teamMemberId: any) => {
    deleteTeamMember({
      startupId: startupData?.id,
      userId: user?.id,
      teamMemberId: teamMemberId,
    })
      .then(() => {
        successMessage("Team member deleted successfully");
        refetch();
        setTeamMemberId("");
      })
      .catch(() => {
        errorMessage("Failed to delete team member");
      });
    console.log(e);
  };

  const cancel: PopconfirmProps["onCancel"] = (e) => {
    console.log(e);
    setTeamMemberId("");
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
      successMessage("Profile image uploaded successfully");
      refetch();
      setTeamMemberId("");
    }
  };

  console.log("dattum", data);


  const initialValues = {
    id: "",
    name: "",
    position: "",
    email: "",
    phone_number: "",
    linkedin_profile: "",
    bio: "",
  };

  const handleFinish = (values: any) => {
    console.log(values);
    const credentials = {
      userId: user.id,
      startupId: startupData.id,
      data: {
        name: values.name,
        position: values.position,
        email: values.email,
        phone_number: values.phone_number,
        linkedin_profile: values.linkedin_profile,
        bio: values.bio,
      },
    };
    if (values.id !== "") {
      updateTeamMember({ ...credentials, teamMemberId: values.id })
        .then(() => {
          refetch();
          form.resetFields();
          setTeamMemberId("");
          successMessage("Team Member updated successfully");
        })
        .catch(() => {
          console.log("error");
        });
    } else {
      addTeamMember(credentials)
        .then(() => {
          refetch();
          form.resetFields();
          setTeamMemberId("");
          successMessage("Team Member added successfully");
        })
        .catch(() => {
          console.log("error");
        });
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <div className="pt-4 w-full h-[95vh] overflow-y-scroll">
      <h2 className="text-xl font-medium">Team</h2>

      {/* Overview */}
      <section className="w-full border-[#E9ECEF] mt-6">
        <div className="flex flex-col xl:flex-row ">
          <div className="xl:w-1/5">
            <h6 className="text-sm font-semibold text-grey700">
              Add Team Member
            </h6>
          </div>
          <div className="2xl:w-3/5  flex gap-x-5 items-start">
            <div className="w-full">
              <div className="rounded-md text-sm text-[#4F4F4F]">
                <div className="">
                  <div>
                    <Form
                      layout="vertical"
                      className="w-full"
                      onFinish={handleFinish}
                      initialValues={initialValues}
                      form={form}
                    >
                      <div className="flex md:flex-row flex-col gap-x-2 items-end w-full">
                        <Form.Item
                          name="id"
                          className="w-full hidden"
                          label="ID"
                          rules={[
                            {
                              required: false,
                              message:
                                "Please input the id of your Team member",
                            },
                          ]}
                        >
                          <Input placeholder="Team member ID" />
                        </Form.Item>
                        <Form.Item
                          name="name"
                          className="w-full"
                          label="Full name"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the name of your Team member",
                            },
                          ]}
                        >
                          <Input placeholder="Team member name" />
                        </Form.Item>
                        <Form.Item
                          name="position"
                          className="w-full"
                          label="Position"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the position of your Team member",
                            },
                          ]}
                        >
                          <Input placeholder="Position" />
                        </Form.Item>
                      </div>
                      <div className="flex md:flex-row flex-col gap-x-2 items-end w-full">
                        <Form.Item
                          name="email"
                          className="w-full"
                          label="Email"
                          rules={[
                            {
                              required: true,
                              message: "The inputt is not a valid E-mail",
                              type: "email",
                            },
                          ]}
                        >
                          <Input
                            addonBefore={<p>Email</p>}
                            placeholder="sample@email.com"
                          />
                        </Form.Item>
                        <Form.Item
                          name="phone_number"
                          className="w-full"
                          label="Phone number"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the phone number of your Team member",
                            },
                          ]}
                        >
                          <Input placeholder="Phone number" />
                        </Form.Item>
                      </div>
                      <div className="flex gap-x-2 items-end w-full">
                        <Form.Item
                          name="linkedin_profile"
                          className="w-full"
                          label="Linkedin Profile"
                          rules={[{ required: true, message: "" }]}
                        >
                          <Input addonBefore={<p>https</p>} placeholder="" />
                        </Form.Item>
                      </div>

                      <div className="flex gap-x-2 items-end w-full">
                        <Form.Item
                          name="bio"
                          className="w-full"
                          label="Bio"
                          rules={[{ required: true, message: "" }]}
                        >
                          <Input.TextArea placeholder="Team Member Bio" />
                        </Form.Item>
                      </div>

                      <div className="flex gap-x-2">
                        <Form.Item>
                          <Button
                            className="bg-primary text-white"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                        {teamMemberId !== "" && (
                          <Button
                            type="text"
                            onClick={() => {
                              form.resetFields();
                              setTeamMemberId("");
                            }}
                          >
                            Cancel
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr />

      <section className="w-full border-[#E9ECEF] mt-6 ">
        <div className="flex flex-col xl:flex-row">
          <div className="xl:w-1/5">
            <h6 className="text-sm font-semibold text-grey700">Team Members</h6>
            <p className="text-xs text-grey700">{data?.length + 1} member(s)</p>
          </div>
          <div className="xl:w-3/5 flex gap-x-5 items-start">
            <div className="w-full">
              <div className="overflow-y-scroll ">
                {/* info */}
                {/* <div className='my-1'>

                  <div className='mx-3 bg-white px-3 py-2 rounded-md border border-[#E8E8E8] text-sm text-[#4F4F4F]'>

                    <div className='flex  items-center justify-between py-2 border-b border-[#E8E8E8]'>
                      <p className='text-sm'>Your info</p>
                      <div className='flex gap-x-2 items-center cursor-pointer' onClick={() => navigate("/userSetting")}>
                        <p className='text-primary text-xs' >Edit</p>
                        <img src={EditIcon} alt="" />
                      </div>
                    </div>

                    <div className='flex md:flex-row flex-col items-center justify-between py-4 gap-x-3'>
                      <div className=''>
                        <img src={user?.founder_image_url || defaultProfile} alt="" className='max-w-[200px] rounded-lg' />
                        <Button className='w-full mt-2 py-6' onClick={() => navigate("/userSetting")}>Change Profile</Button>
                      </div>

                      <div className='w-full '>
                        <div className='space-y-3'>
                          <h3 className='text-base font-medium'>{user.first_name} {user.last_name} (You)</h3>
                          <p className='text-xs'>Chief Executive Officer (CEO)</p>
                          <p className='text-xs mt-2'>{user.bio}</p>
                        </div>
                        <div className='w-full grid grid-cols-1 gap-x-5 gap-y-2 mt-6'>
                          {profileSocial.map((item, index) => {
                            return item.content ? (

                              <div key={index} className='flex flex-wrap gap-x-2 items-center'>
                                <img src={item.icon} alt="" />
                                {item.content ? <p className='text-xs text-wrap'>{item.content}</p> : null}
                              </div>
                            ) : null
                          })}

                        </div>
                      </div>

                    </div>

                  </div>

                </div> */}

                {/* member 1 */}
                {data?.map((item: any, index: number) => (
                  <div className="my-1">
                    <div className="mx-3 bg-white px-3 py-2 rounded-md text-sm text-[#4F4F4F]">
                      <div className="flex md:flex-row flex-col items-start justify-between py-4 gap-3">
                        <Upload
                          name="image"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          action={`${BASE_URL}team_member/upload-image/${user?.id}/${startupData?.id}/${item.id}/`}
                          beforeUpload={beforeUpload}
                          onChange={handleChange}
                          headers={{ Authorization: `Bearer ${token}` }}
                          style={{ width: "80px" }}
                        >
                          {(item.profile_image_url &&
                            item.profile_image_url !== "None") ||
                          imageUrl ? (
                            <img
                              src={item.profile_image_url}
                              alt="avatar"
                              className="w-full h-full rounded-full object-cover"
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>

                        <div className="w-full">
                          <div className="space-y-3">
                            <h3 className="text-base font-medium">
                              {item.name}
                            </h3>
                            <p className="text-xs">{item.position}</p>
                            {/* <p className='text-xs mt-2'>{item.bio}</p> */}
                          </div>
                          <div className="w-full flex flex-wrap items-center gap-x-8 gap-y-2 mt-6">
                            {item.email && (
                              <div className="flex gap-x-2 items-center">
                                <img src={Gmail} alt="" />
                                <p className="text-xs">{item.email}</p>
                              </div>
                            )}
                            {item.phone_number && (
                              <div className="flex gap-x-2 items-center">
                                <img src={Phone} alt="" />
                                <p className="text-xs">{item.phone_number}</p>
                              </div>
                            )}
                            {item.linkedin_profile && (
                              <div className="flex gap-x-2 items-center">
                                <FaLinkedin className="text-[#0A66C2] size-5 hover:text-primary cursor-pointer" />
                                <p className="text-xs">
                                  {item.linkedin_profile}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="flex  items-center justify-between py-2">
                          <div className="flex  gap-x-2 items-center">
                            <div
                              className="flex gap-x-2 items-center cursor-pointer"
                              onClick={() => {
                                setTeamMemberId(item.id);
                                form.setFieldsValue({
                                  id: item.id,
                                  name: item.name,
                                  position: item.position,
                                  email: item.email,
                                  phone_number: item.phone_number,
                                  linkedin_profile: item.linkedin_profile,
                                  bio: item.bio,
                                });
                                form.scrollToField("name");
                              }}
                            >
                              <FiEdit className="size-5 text-primary400" />
                            </div>
                            <div>
                              <Popconfirm
                                title="Delete team member data?"
                                description="Are you sure to delete this data?"
                                onConfirm={(e) => confirm(e, item.id)}
                                onCancel={cancel}
                                okText="Delete"
                                cancelText="Cancel"
                              >
                                <FiTrash2 className="size-5 text-red-400" />
                              </Popconfirm>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Team;
