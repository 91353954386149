import React, { FC } from 'react'
import footer1 from "../../../assets/svg/Footer1.svg";
import footer2 from "../../../assets/svg/Footer2.svg";
import footer3 from "../../../assets/svg/Footer2.svg";

interface IProps {
    sources: { title: string, link: string }[]
}

export const Sources: FC<IProps> = ({ sources }) => {

    const [showAllResults, setShowAllResults] = React.useState(false)
    const handleViewMore = () => {
        setShowAllResults(true)
    }

    console.log(sources)

    const displayUrlName = (url: string) => {
        const hostname = new URL(url).hostname
        const parts = hostname.split('.')
        return parts.length > 2 ? parts.slice(1, -1).join('.') : parts[0]
    }

    const displayedResults = showAllResults ? sources : sources?.slice(0, 3)
    const additionalResultsCount = sources.length > 3 ? sources.length - 3 : 0
    // 
    const DisplaySources = () => {
        if (sources) {
            return (
                <div className=''>
                    <h2 className="flex items-center leading-none py-2 text-lg font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-newspaper mr-1.5 text-muted-foreground"><path d="M4 22h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v16a2 2 0 0 1-2 2Zm0 0a2 2 0 0 1-2-2v-9c0-1.1.9-2 2-2h2"></path><path d="M18 14h-8"></path><path d="M15 18h-5"></path><path d="M10 6h8v4h-8V6Z"></path>
                        </svg>
                        Sources
                    </h2>
                    <div className='flex flex-wrap gap-2 mt-2'>
                        {displayedResults?.map((source, index) =>
                            <div className='w-1/2 max-w-[180px] h-[70px] md:w-1/4 p-2.5 bg-[#F1F3F5] flex flex-col  rounded-xl text-xs font-medium' key={index}>
                                <a target="_blank" rel="noopener noreferrer" href={source.link} className='h-full' >
                                    <div className='flex flex-col h-full justify-between'>
                                        <div className='relative max-h-5 flex-1 select-none overflow-hidden text-ellipsis break-all'>
                                            <span className='whitespace-nowrap'>{source.title}</span>

                                        </div>

                                        <div className='flex items-center gap-x-2 relative max-h-5 flex-1 select-none overflow-hidden text-ellipsis break-all'>
                                            <img src={`https://www.google.com/s2/favicons?domain=${new URL(source.link).hostname
                                                }`}
                                                alt="" />
                                            <span className='whitespace-nowrap'> {`${displayUrlName(source.link)} - ${index + 1}`}</span>

                                        </div>
                                    </div>
                                </a>
                            </div>
                        )}
                        {!showAllResults && additionalResultsCount > 0 && (
                            <div className='w-1/2 max-w-[180px] h-[70px] md:w-1/4 p-2.5 bg-[#F1F3F5] flex flex-col  rounded-xl text-xs font-medium'>
                                <div className="flex-1 flex h-full items-center justify-center">
                                    <div className='p-2'>
                                        <button className="text-muted-foreground" onClick={handleViewMore}>View {additionalResultsCount} more</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: 85,
                        width: 180,
                        backgroundColor: '#F1F3F5',
                        borderRadius: 8,
                        padding: 10,
                        fontSize: 12,
                        fontWeight: '500'
                    }}
                >
                    <div>
                        How to get investors for mobile app startups?
                    </div>

                    <div><img src={footer1} alt={'footer1'} /></div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: 85,
                        width: 180,
                        backgroundColor: '#F1F3F5',
                        borderRadius: 8,
                        padding: 10,
                        fontSize: 12,
                        fontWeight: '500'
                    }}
                >
                    <div>
                        How to get investors for my app in 2023?
                    </div>

                    <div><img src={footer2} alt={'footer1'} /></div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: 85,
                        width: 180,
                        backgroundColor: '#F1F3F5',
                        borderRadius: 8,
                        padding: 10,
                        fontSize: 12,
                        fontWeight: '500'
                    }}
                >
                    <div>
                        How to get investors for your mobile app: the     definitive guide
                    </div>

                    <div><img src={footer3} alt={'footer1'} /></div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: 85,
                        width: 180,
                        backgroundColor: '#F1F3F5',
                        borderRadius: 8,
                        padding: 10,
                        fontSize: 12,
                    }}
                >
                    <div>
                        ...
                    </div>

                    <div>View 2 more</div>
                </div>
            </>
        )
    }
    return (
        <div

        >
            <DisplaySources />

        </div>
    )
};

