import { apiSlice } from "../../services/apiSlice";


export const startupApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStartUp: builder.query({
            query: (userId) => ({
                url: `startup/${userId}`,
                method: 'GET',
            })
        }),
        updateStartUpAndUser: builder.mutation({
            query: (credentials) => ({
                url: `users/${credentials.id}/${credentials.startup_id}/`,
                method: 'PATCH',
                body: {
                    start_up_data: credentials.start_up_data,
                    user_data: credentials.user_data,
                }
            })
        }),
        updateUserProfileImg: builder.mutation({
            query: (credentials: { userId: string, formData: FormData }) => ({
                url: `users/${credentials.userId}/profile-image`,
                method: 'POST',
                body: credentials.formData,
            })
        }),
        addTeamMember: builder.mutation({
            query: (credentials: { startupId: string | undefined, userId: string | undefined, data: any }) => ({
                url: `team_member/startup/${credentials.startupId}/${credentials.userId}/add/`,
                method: 'POST',
                body: credentials.data
            })
        }),
        getTeamMembers: builder.query({
            query: (credentials: { startupId: string | undefined, userId: string | undefined }) => ({
                url: `team_member/startup/user/${credentials.userId}/start_up/${credentials.startupId}/`,
                method: 'GET',
            })
        }),
        deleteTeamMember: builder.mutation({
            query: (credentials: { startupId: string | undefined, userId: string | undefined, teamMemberId: string | undefined }) => ({
                url: `team_member/startup/${credentials.startupId}/${credentials.userId}/delete/${credentials.teamMemberId}/`,
                method: 'DELETE',
            })
        }),
        updateTeamMember: builder.mutation({
            query: (credentials: { startupId: string | undefined, userId: string | undefined, teamMemberId: string | undefined, data: any }) => ({
                url: `team_member/startup/${credentials.startupId}/${credentials.userId}/update/${credentials.teamMemberId}/`,
                method: 'PATCH',
                body: credentials.data
            })
        }),
        generateEmail: builder.mutation({
            query: (credentials: { user_id: string | undefined, investor_class: string | undefined, investor_id: string | undefined }) => ({
                url: `/send_email/send-email-to-investor/`,
                method: 'POST',
                body: credentials,
                
                
            })
        }),
    })
})

export const {
    useLazyGetStartUpQuery,
    useGetStartUpQuery,
    useUpdateStartUpAndUserMutation,
    useUpdateUserProfileImgMutation,
    useAddTeamMemberMutation,
    useGetTeamMembersQuery,
    useDeleteTeamMemberMutation,
    useUpdateTeamMemberMutation,
    useGenerateEmailMutation
} = startupApiSlice