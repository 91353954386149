import React from "react";
import Header from "../../components/landing/Header";
import CTA from "../../components/landing/CTA";
import Footer from "../../components/landing/Footer";


function LandingLayout({ children }: { children: React.ReactElement }) {
  
  return (
    <main className="bg-white">
      <Header />

      {children}

      {/* CTA */}
      <CTA />

      {/* footer */}
      <Footer />
    </main>
  );
}

export default LandingLayout;
