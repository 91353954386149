import { apiSlice } from "../../services/apiSlice";


export const deepDiveApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllChats: builder.query({
            query: (userId) => ({
                url: `deep_dive_my_biz/get-all-chat/${userId}`,
                method: 'GET',
                
            }),
            providesTags: ['deepDiveNavbarList']
        }),
        getSingleChats: builder.query({
            query: (data: {userId: string, conversationId: string}) => ({
                url: `deep_dive_my_biz/get-chat/${data.userId}/${data.conversationId}`,
                method: 'GET',
            })
        }),
        deepDiveChat: builder.mutation({
            query: (credentials) => ({
                url: `deep_dive_my_biz/conversation/`,
                method: 'POST',
                body: {
                    id: credentials.id,
                    userId: credentials.userId,
                    title: credentials.title,
                    content: [
                        {
                            role: "user",
                            query: credentials.query
                        }
                    ]
                }
            }),
            invalidatesTags: ['deepDiveNavbarList']
        }),
        deleteLastChat: builder.mutation({
            query: () => ({
                url: `deep_dive_my_biz/delete-last-conversation/`,
                method: 'DELETE',
            })
        }),
        deleteChat: builder.mutation({
            query: () => ({
                url: `deep_dive_my_biz/delete-conversation/`,
                method: 'DELETE',
            })
        }),
        changeTitle: builder.mutation({
            query: (credentials: {userId: string, conv_id: string, new_title: string}) => ({
                url: `deep_dive_my_biz/change-deep-dive-title/`,
                method: 'PATCH',
                body: credentials
            })
        }),
    })
})

export const {
    useGetAllChatsQuery,
    useGetSingleChatsQuery,
    useDeepDiveChatMutation,
    useDeleteLastChatMutation,
    useDeleteChatMutation,
    useChangeTitleMutation
} = deepDiveApiSlice