import { Button, Input, Modal } from "antd";
import React, { useCallback } from "react";
import { FiArrowRight } from "react-icons/fi";
import { IconBlue, IconWhite } from "../../assets";
import Table from "../../components/CustomTable";
import { Link, useNavigate } from "react-router-dom";

import {
  useAiFaqFeedbackMutation,
  useDeleteFeedbackChatMutation,
  useGetFeedbackQuery,
  useGetSingleFeedbackAIFAQConversationQuery,
} from "../../lib/features/user/userApiSlice";
import { useAppSelector } from "../../lib/hooks";
import { RootState } from "../../lib/store";
import { shallowEqual } from "react-redux";
import AiLogoWhite from "../../assets/svg/ai-white.svg";
import AIBlue from "../../assets/svg/ai-blue.svg";
import { AIMessage } from "../../components/message";
import { Trash2Icon } from "lucide-react";
import { LiaTimesSolid } from "react-icons/lia";
import { successMessage } from "../../constants/notification";
import useAnalyticsEventTracker from "../../utils/Analytics";

const strenghtAI = [
  "How can I use my strengths to grow faster?",
  "How can I optimize my unique value proposition?",
  "What’s the best way to expand my customer base?",
  "How do I leverage my business model to attract investors?",
];

const weaknessAI = [
  "What’s the average burn rate for my stage?",
  "How can I reduce my marketing costs?",
  "How can I increase my market presence quickly?",
  "What’s the industry standard for burn rate, and how can I reduce mine?",
  "What are low-cost ways to boost brand awareness?",
  "How do I fix my marketing strategy with limited resources?",
];

const opportunityAI = [
  "Which market trend should I focus on first?",
  "How can I explore international expansion on a budget?",
  "What’s the best way to find new partnerships?",
  "How can I use tech advancements to my advantage?",
];

const threatAI = [
  "How can I stay competitive in a crowded market",
  "What should I do to manage regulatory changes?",
  "How do I plan for supply chain disruptions?",
  "How can I avoid the risks of market saturation?",
];

function Feedback() {
  const userId = useAppSelector((state) => state.user.id);
  const navigate = useNavigate();

  const startupData = useAppSelector(
    (state: RootState) => state.startup,
    shallowEqual
  );
  const user = useAppSelector((state: RootState) => state.user, shallowEqual);
  const [question, setQuestion] = React.useState("");

  const [chatActive, setChatActive] = React.useState(false);

  const { data: feedbackData, isLoading, error } = useGetFeedbackQuery(userId);
  const {
    data: aiconvoData,
    isLoading: aiconvoIsLoading,
    refetch,
  } = useGetSingleFeedbackAIFAQConversationQuery({
    userID: userId,
    startup_id: startupData?.id,
  });

  const [aiFAQ, { isLoading: askIsLoading }] = useAiFaqFeedbackMutation();

  const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      // Trigger button click
      buttonRef.current?.click();
      scrollToBottom();
    }
  };

  const handleFAQ = async (question: string) => {
    gAnalytics("Interacted with deep dive my biz AI", user?.email || "User");
    setChatActive(true);
    scrollToBottom();
    await aiFAQ({
      userId: user.id,
      question: question,
      startupId: startupData?.id,
    }).then((res) => {
      setQuestion("");
      refetch();
      scrollToBottom();
      // console.log(res)
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Competitors",
        accessor: "competitors",
        maxWidth: 400,
        minWidth: 140,
        width: 200,
      },
      {
        Header: "Fund raised",
        accessor: "fund_raised",
        size: 200,
      },
      {
        Header: "User growth",
        accessor: "user_growth",
      },
      {
        Header: "Strength",
        accessor: "strength",
        maxWidth: 400,
        minWidth: 140,
        width: 150,
      },
      {
        Header: "Weakness",
        accessor: "weakness",
        maxWidth: 400,
        minWidth: 140,
        width: 200,
      },
    ],
    []
  );

  const getFeedbackData = useCallback(() => {
    return Object.keys(feedbackData?.benchmark_analysis?.competitors || {}).map(
      (item: any) => {
        return {
          competitors: item,
          fund_raised:
            feedbackData?.benchmark_analysis?.competitors[item]?.funds_raised,
          user_growth:
            feedbackData?.benchmark_analysis?.competitors[item]?.users,
          strength:
            feedbackData?.benchmark_analysis?.competitors[item]?.strengths.join(
              ",\r\n"
            ),
          weakness:
            feedbackData?.benchmark_analysis?.competitors[
              item
            ]?.weaknesses.join("\r\n"),
        };
      }
    );
  }, [feedbackData]);

  const data = React.useMemo(() => getFeedbackData(), [getFeedbackData]);

  const [deleteFeedbackChat, { isLoading: deleteIsLoading }] =
    useDeleteFeedbackChatMutation();

  const messageEndRef = React.useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [aiconvoData, question, askIsLoading]);
  const gAnalytics = useAnalyticsEventTracker("feedback");

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        Getting feedback...
      </div>
    );
  }

  return (
    <div className=" overflow-scroll p-8">
      <div className="flex justify-between items-center">
        <h2 className="text-3xl font-semibold">Startup Feedback Overview</h2>
        <Button
          icon={<img src={AiLogoWhite} alt="" className="size-4" />}
          size="large"
          type="primary"
          className="!py-4 !px-8 bg-primary600"
          onClick={() => {
            gAnalytics(
              "Clicked to open Deep Dive My Biz modal",
              user?.email || "User"
            );
            setChatActive(true);
            navigate("/ai-feedback");
          }}
        >
          Deep Dive My Biz
        </Button>
      </div>
      <div className="w-full h-0.5 border-t border-grey50 mt-8 mb-5" />
      <div>
        <h4 className="text-sm font-semibold text-grey700">Startup Overview</h4>

        <div className="mt-4">
          <Input.TextArea
            value={feedbackData?.brief_overview_startup}
            placeholder="Bio"
            rows={6}
            maxLength={500}
            disabled
          />
          {/* <p>{500 - (feedbackData?.brief_overview_startup.length || 0)} characters left</p> */}
        </div>
      </div>

      <div className="w-full h-0.5 border-t border-grey50 my-5" />

      {/* top */}
      <div className="grid xl:grid-cols-2 gap-5">
        {/* Strength */}
        <div className="bg-success50 p-5">
          <h4 className="text-sm font-semibold text-grey700">Strengths</h4>
          <div className="bg-white mt-2 p-4 ">
            <div className="space-y-2">
              {feedbackData?.swot_analysis?.strengths?.map(
                (element: string, index: number) => (
                  <div className="flex items-center gap-x-2.5" key={index}>
                    <FiArrowRight className="size-5" />
                    <p className="text-base text-grey900">{element}</p>
                  </div>
                )
              )}
            </div>

            <div className="w-full h-0.5 border-t border-grey50 my-4" />

            <div>
              <div className="flex flex-col gap-2">
                <div
                  className="flex gap-x-2 items-center cursor-pointer"
                  
                >
                  <img src={IconBlue} alt="" className="size-4" />
                  <p className="text-grey700">AI assistant</p>
                </div>
                <div className="flex gap-2 items-center flex-wrap">
                  {strenghtAI.map((element: string, index: number) => (
                    <button
                      className="bg-primary100 lg:text-sm text-xs font-medium text-primary700 rounded-full py-1 px-3"
                      key={index}
                      value={element}
                      onClick={() => {
                      }}
                    >
                      {element}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Weaknesses */}
        <div className="bg-error50 p-5">
          <h4 className="text-sm font-semibold text-grey700">Weaknesses</h4>
          <div className="bg-white mt-2 p-4 ">
            <div className="space-y-2">
              {feedbackData?.swot_analysis?.weaknesses?.map(
                (element: string, index: number) => (
                  <div className="flex items-center gap-x-2.5" key={index}>
                    <FiArrowRight className="size-5" />
                    <p className="text-base text-grey900">{element}</p>
                  </div>
                )
              )}
            </div>

            <div className="w-full h-0.5 border-t border-grey50 my-4" />

            <div>
              <div className="flex flex-col gap-2">
                <div
                  className="flex gap-x-2 items-center cursor-pointer"
                  
                >
                  <img src={IconBlue} alt="" className="size-4" />
                  <p className="text-grey700">AI assistant</p>
                </div>
                <div className="flex gap-2 items-center flex-wrap">
                  {weaknessAI.map((element: string, index: number) => (
                    <button
                      className="bg-primary100 lg:text-sm text-xs font-medium text-primary700 rounded-full py-1 px-3"
                      key={index}
                      value={element}
                      onClick={() => {
                        
                      }}
                    >
                      {element}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-0.5 border-t border-grey50 mt-8 mb-5" />

      {/* bottom */}
      <div className="grid xl:grid-cols-2 gap-5">
        {/* Opportunities */}
        <div className="bg-success50 p-5">
          <h4 className="text-sm font-semibold text-grey700">Opportunities</h4>
          <div className="bg-white mt-2 p-4 ">
            <div className="space-y-2">
              {feedbackData?.swot_analysis?.opportunities?.map(
                (element: string, index: number) => (
                  <div className="flex items-center gap-x-2.5" key={index}>
                    <FiArrowRight className="size-5" />
                    <p className="text-base text-grey900">{element}</p>
                  </div>
                )
              )}
            </div>

            <div className="w-full h-0.5 border-t border-grey50 my-4" />

            <div>
              <div className="flex flex-col gap-2">
                <div
                  className="flex gap-x-2 items-center cursor-pointer"
                  
                >
                  <img src={IconBlue} alt="" className="size-4" />
                  <p className="text-grey700">AI assistant</p>
                </div>
                <div className="flex gap-2 items-center flex-wrap">
                  {opportunityAI.map((element: string, index: number) => (
                    <button
                      className="bg-primary100 lg:text-sm text-xs font-medium text-primary700 rounded-full py-1 px-3"
                      key={index}
                      value={element}
                      onClick={() => {
                        
                      }}
                    >
                      {element}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Threats */}
        <div className="bg-error50 p-5">
          <h4 className="text-sm font-semibold text-grey700">Threats</h4>
          <div className="bg-white mt-2 p-4 ">
            <div className="space-y-2">
              {feedbackData?.swot_analysis?.threats?.map(
                (element: string, index: number) => (
                  <div className="flex items-center gap-x-2.5" key={index}>
                    <FiArrowRight className="size-5" />
                    <p className="text-base text-grey900">{element}</p>
                  </div>
                )
              )}
            </div>

            <div className="w-full h-0.5 border-t border-grey50 my-4" />

            <div>
              <div className="flex flex-col gap-2">
                <div
                  className="flex gap-x-2 items-center cursor-pointer"
                  
                >
                  <img src={IconBlue} alt="" className="size-4" />
                  <p className="text-grey700">AI assistant</p>
                </div>
                <div className="flex gap-2 items-center flex-wrap">
                  {threatAI.map((element: string, index: number) => (
                    <button
                      className="bg-primary100 lg:text-sm text-xs font-medium text-primary700 rounded-full py-1 px-3"
                      key={index}
                      value={element}
                      onClick={() => {
                        
                      }}
                    >
                      {element}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-0.5 border-t border-grey50 mt-8 mb-5" />
      <div>
        <h4 className="text-sm font-semibold text-grey700">
          Competitor Analysis
        </h4>

        <section className="mt-4">
          <Table columns={columns} data={data} />
        </section>
      </div>

      <div className="flex justify-end my-8">
        <Link
          to="/recommendation"
          className="rounded-md !py-4 px-10 bg-primary600 text-white hover:bg-primary400 hover:text-grey700"
        >
          Next
        </Link>
      </div>

      {/* chatbot */}
      <Modal
        open={chatActive}
        width={1200}
        onClose={() => setChatActive(false)}
        onCancel={() => setChatActive(false)}
        footer={null}
        closable={false}
        title={
          <div className="bg-primary50 flex text-grey700 items-center py-2 justify-between px-3 rounded md">
            <div className="flex items-center gap-x-4">
              <img src={AiLogoWhite} alt="" className="size-6" />
              <p className="">Deep Dive My Biz</p>
            </div>
            <div className="flex items-center gap-x-4">
              <Trash2Icon
                className="size-5 cursor-pointer"
                onClick={() => {
                  if (deleteIsLoading) {
                    return;
                  } else {
                    deleteFeedbackChat({
                      userID: user?.id,
                      startup_id: startupData?.id,
                    })
                      .then((res) => {
                        refetch();
                        setChatActive(false);
                        successMessage("Chats cleared");
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                }}
              />
              <LiaTimesSolid
                className="size-5 cursor-pointer"
                onClick={() => setChatActive(false)}
              />
            </div>
          </div>
        }
      >
        <div
          className="w-full xl:h-[700px] h-[500px] overflow-y-scroll px-3 space-y-3 pb-5 mt-4"
          ref={scrollableDivRef}
        >
          <div className="relative pt-4 flex items-start ">
            <img src={AIBlue} alt="" className="my-1" />
            <div className="px-2.5  rounded-md">
              <AIMessage
                content={
                  "Welcome to our review session! I’m here to give you actionable insights to take your business to the next level."
                }
              />
              {/* <p className="text-sm text-white">{</p> */}
            </div>
          </div>
          {aiconvoData?.map((item: any, index: number) => {
            const isQuestion = item?.role === "user";
            const parsedConversation = !isQuestion && item.content;
            // const isLastItem = index === data?.length - 1
            return isQuestion ? (
              <div className=" flex items-center justify-end ">
                {/* <div className='flex gap-x-4 items-center'>
                                            <img src={Flag} alt="" />
                                            <p className='text-sm font-normal'>Problem</p>
                                        </div> */}
                <p className="p-2.5 bg-primary600 w-1/3 text-sm text-white font-bold rounded-b-md rounded-tl-md">
                  {item.content.USER}
                </p>
              </div>
            ) : (
              <div className="relative pt-4 flex items-start ">
                <img src={AIBlue} alt="" className="my-1" />
                <div className="px-2.5  rounded-md">
                  <AIMessage content={parsedConversation.AI} />
                  {/* <p className="text-sm text-white">{</p> */}
                </div>
              </div>
            );
          })}
          {question !== "" && (
            <div className="flex items-center justify-end">
              {/* <div className='flex gap-x-4 items-center'>
                                    <img src={Flag} alt="" />
                                    <p className='text-sm font-normal'>Problem</p>
                                </div> */}
              <p className="p-2.5 bg-primary600 w-1/3 text-sm text-white font-bold rounded-b-md rounded-tl-md mt-2">
                {question}
              </p>
            </div>
          )}
          {askIsLoading && (
            <div className="relative w-2/3 float-left">
              <img src={AIBlue} alt="" className="my-1" />
              <div className="h-20 w-full bg-primary/30 rounded-md animate-pulse"></div>
            </div>
          )}

          <div className="float-left clear-both" ref={messageEndRef}></div>
        </div>
        <div className="px-4 py-2">
          <div className="relative max-w-[800px] w-full mx-auto">
            <input
              type="text"
              className="border border-primary600 rounded-full pl-3 pr-10 py-3 w-full"
              placeholder="Type here"
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              ref={inputRef}
              onKeyDown={handleKeyDown}
            />
            <button
              className="absolute right-4 top-1/2 -translate-y-1/2 size-8 rounded-full bg-primary p-1.5 "
              ref={buttonRef}
              onClick={() => {
                handleFAQ(question);
              }}
            >
              <img src={IconWhite} alt={"askBtn"} />
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Feedback;
