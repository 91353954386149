import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Overview from './Profiletabs/Overview'
import { Information } from './Profiletabs/Information';
import PitchDeck from './Profiletabs/PitchDeck';
import ExecutiveSummary from './Profiletabs/ExecutiveSummary';
import Team from './Profiletabs/Team';
import FundRaising from './Profiletabs/FundRaising';
// import { Switch } from 'antd';
import { useLocation } from 'react-router-dom';


function MainProfile() {

  const [hashQuery, setHash] = useState("");
  const { hash } = useLocation();

  console.log("location hash", hash.substring(1))


  useEffect(() => {
    if (hash) {
      setHash(hash.substring(1) || "Information")
    }
  }, [hash]);

  const isActive = useCallback(
    (value: string) => {
      return hashQuery === value;
    },
    [hashQuery],
  )

  // const isActive = (value: string) => {
  //   return hash === value
  // }

  return (
    <div className='h-screen'
    >
      <div>
        <div className='flex flex-wrap items-center bg-primary50 px-8 py-3 gap-x-1'>
          <span onClick={() => setHash("")} className={`cursor-pointer text-md font-semibold px-3 py-2 ${isActive("Overview") || isActive("") ? 'text-white bg-primary600 rounded-md' : 'text-grey700'}`}>Overview</span>
          <span onClick={() => setHash("Information")} className={`text-md font-semibold cursor-pointer px-3 py-2 ${isActive("Information") ? 'text-white bg-primary600 rounded-md' : 'text-grey700'}`}>Information</span>
          <span onClick={() => setHash("Pitch-Deck")} className={`text-md font-semibold cursor-pointer px-3 py-2 ${isActive("Pitch-Deck") ? 'text-white bg-primary600 rounded-md' : 'text-grey700'}`}>Pitch Deck</span>
          <span onClick={() => setHash("Executive-Summary")} className={`text-md font-semibold cursor-pointer px-3 py-2 ${isActive("Executive-Summary") ? 'text-white bg-primary600 rounded-md' : 'text-grey700'}`}>Executive Summary</span>
          {/* <span onClick={() => setHash("Fund-Raising")} className={`text-md font-semibold cursor-pointer px-3 py-2 ${isActive("Fund-Raising") ? 'text-white bg-primary600 rounded-md' : 'text-grey700'}`}>Fund Raising</span> */}
          <span onClick={() => setHash("Team")} className={`text-md font-semibold cursor-pointer px-3 py-2 ${isActive("Team") ? 'text-white bg-primary600 rounded-md' : 'text-grey700'}`}>Team</span>
        </div>
      </div>
      <div className='md:px-11 px-5 flex flex-col md:flex-row  gap-x-16'>

        <div className='w-full md:pt-0'>
          {isActive("Overview") || isActive("") ? <Overview setHash={setHash} /> : null}
          {isActive("Information") ? <Information /> : null}
          {isActive("Pitch-Deck") ? <PitchDeck /> : null}
          {isActive("Executive-Summary") ? <ExecutiveSummary /> : null}
          {/* {isActive("Fund-Raising") ? <FundRaising /> : null} */}
          {/* {isActive("Traction") ? <div>Traction</div> : null} */}
          {isActive("Team") ? <Team /> : null}
          {/* {isActive("Attachments") ? <div>Attachments</div> : null} */}
        </div>

      </div>




    </div>
  )
}

export default MainProfile
