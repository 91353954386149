import React from "react";
import Logo from "../../assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { FaTimes } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";

function Header() {
  React.useEffect(() => {
    scroll.scrollToTop();
  }, []);

  const [open, setOpen] = React.useState(false);

  return (
    <header className="bg-white sticky top-0 py-6 border-b  z-[99]">
      <nav className="flex items-center justify-between w-full max-w-[1440px] px-4 mx-auto">
        <Link to={"/"}>
          <img src={Logo} style={{ height: 32 }} alt={"myCofoundr.ai logo"} />
        </Link>
        <div className="hidden md:flex items-center gap-x-8 text-grey600 text-base font-semibold cursor-pointer">
          <ul className="flex items-center gap-x-1 text-grey600 text-base font-semibold cursor-pointer">
            <Link to={"/"}>
              <li className="px-4 py-2.5 rounded-lg">Home</li>
            </Link>
            <HashLink
              activeClass="text-primary700 bg-primary50 rounded-lg"
              to="/#AboutUs"
              spy={true}
              offset={-100}
              duration={500}
              smooth={true}
              hashSpy={true}
            >
              <li className="px-4 py-2.5 rounded-lg">About Us</li>
            </HashLink>
            <HashLink
              activeClass="text-primary700 bg-primary50 rounded-lg"
              to="/#Solution"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              hashSpy={true}

            >
              <li className="px-4 py-2.5 rounded-lg">Our Solution</li>
            </HashLink>
            <HashLink
              activeClass="text-primary700 bg-primary50 rounded-lg"
              to="/#Testimonials"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              isDynamic={true}
              hashSpy={true}

            >
              <li className="px-4 py-2.5 rounded-lg">Testimonials</li>
            </HashLink>
            <HashLink
              activeClass="text-primary700 bg-primary50 rounded-lg"
              to="/#FAQ"
              spy={true}
              smooth={true}
              duration={500}
              offset={-100}
              hashSpy={true}

            >
              <li className="px-4 py-2.5 rounded-lg">FAQ</li>
            </HashLink>
            <li>
              <NavLink
                to={"/team"}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "text-primary700 bg-primary50 px-4 py-2.5 rounded-lg"
                    : "px-4 py-2.5 rounded-lg"
                }
              >
                Team
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/pricing"}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "text-primary700 bg-primary50 px-4 py-2.5 rounded-lg"
                    : "px-4 py-2.5 rounded-lg"
                }
              >
                Pricing
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="xl:flex hidden items-center gap-x-4">
          <Link
            to={"/login"}
            className="text-base font-semibold text-grey600 py-2.5 px-5"
          >
            Log in
          </Link>
          <Link
            to={"/login"}
            className="text-base font-semibold bg-primary600 text-white rounded-lg py-2.5 px-5"
          >
            Sign up
          </Link>
        </div>
        <div
          className="md:hidden flex items-center gap-x-4 cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          {open ? <FaTimes /> : <FiMenu />}
        </div>
      </nav>
      <div
        className={`block xl:hidden absolute top-16 bg-white ${
          open ? "h-screen" : "h-0"
        }  w-screen overflow-hidden`}
      >
        <ul className="flex flex-col items-center justify-center gap-1 text-grey600 text-base font-semibold cursor-pointer space-y-3 mt-20">
          <Link to={"/"}>
            <li className="px-4 py-2.5 rounded-lg">Home</li>
          </Link>
          <HashLink
            activeClass="text-primary700 bg-primary50 rounded-lg"
            to="/#AboutUs"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            onClick={() => setOpen(false)}
          >
            <li className="px-4 py-2.5 rounded-lg">About Us</li>
          </HashLink>
          <HashLink
            activeClass="text-primary700 bg-primary50 rounded-lg"
            to="/#Solution"
            spy={true}
            smooth={true}
            offset={200}
            duration={500}
            onClick={() => setOpen(false)}
          >
            <li className="px-4 py-2.5 rounded-lg">Our Solution</li>
          </HashLink>
          <HashLink
            activeClass="text-primary700 bg-primary50 rounded-lg"
            to="/#Testimonials"
            spy={true}
            smooth={true}
            offset={500}
            duration={500}
            isDynamic={true}
            onClick={() => setOpen(false)}
          >
            <li className="px-4 py-2.5 rounded-lg">Testimonials</li>
          </HashLink>
          <HashLink
            activeClass="text-primary700 bg-primary50 rounded-lg"
            to="/#FAQ"
            spy={true}
            smooth={true}
            duration={500}
            offset={-100}
            onClick={() => setOpen(false)}
          >
            <li className="px-4 py-2.5 rounded-lg">FAQ</li>
          </HashLink>
          <li className="px-4 py-2.5">
            <NavLink
              to={"/team"}
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending"
                  : isActive
                  ? "text-primary700 bg-primary50 px-4 py-2.5 rounded-lg"
                  : "px-4 py-2.5 rounded-lg"
              }
            >
              Team
            </NavLink>
          </li>
          <li className="px-4 py-2.5">
            <NavLink
              to={"/pricing"}
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending"
                  : isActive
                  ? "text-primary700 bg-primary50 px-4 py-2.5 rounded-lg"
                  : "px-4 py-2.5 rounded-lg"
              }
            >
              Pricing
            </NavLink>
          </li>
        </ul>

        <div className="flex items-center justify-center mt-20 gap-x-4">
          <Link
            to={"/login"}
            className="text-base font-semibold text-grey600 py-2.5 px-5"
          >
            Log in
          </Link>
          <Link
            to={"/login"}
            className="text-base font-semibold bg-primary600 text-white rounded-lg py-2.5 px-5"
          >
            Sign up
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
