import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../lib/hooks";

import { RootState } from "../../lib/store";
// import tools from '../../assets/svg/Tools.svg'
import askBtn from "../../assets/svg/askBtn.svg";
import { PulseLoader } from "react-spinners";
import { ArrowRight } from "lucide-react";
import { SearchSkeleton } from "../../components/SearchSkeleton";
import AiLogoWhite from "../../assets/svg/ai-white.svg";
import AIBlue from "../../assets/svg/ai-light-blue.svg";
import { AIMessage } from "../../components/message";
import { Connection } from "../Answer/components/Connection";
import { Sources } from "../Answer/components/Sources";
import Table from "../../components/CustomTable";
import {
  useAskMutation,
  useGetSingleConversationQuery,
} from "../../lib/features/conversation/conversationApiSlice";
import { IconWhite } from "../../assets";
import { Button, Drawer } from "antd";
import { useGetFeedbackQuery } from "../../lib/features/user/userApiSlice";
import {
  useDeepDiveChatMutation,
  useGetSingleChatsQuery,
} from "../../lib/features/deepdive/deepDiveApiSlice";

const strenghtAI = [
  "How can I use my strengths to grow faster?",
  "How can I optimize my unique value proposition?",
  "What’s the best way to expand my customer base?",
  "How do I leverage my business model to attract investors?",
];

function FeedbackAI() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [question, setQuestion] = React.useState("");
  const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const userId = useAppSelector((state: RootState) => state.user?.id);

  const { data: feedbackData, isLoading, error } = useGetFeedbackQuery(userId);

  const { data, refetch } = useGetSingleChatsQuery(
    {
      userId: userId!!,
      conversationId: id!!,
    },
    { skip: !id }
  );
  // const [ask, { isLoading: askIsLoading }] = useAskMutation();
  const [askDeepDive, { isLoading: askDeepDiveIsLoading }] =
    useDeepDiveChatMutation();

  const scrollToBottom = React.useCallback(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      // Trigger button click
      buttonRef.current?.click();
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Competitors",
        accessor: "competitors",
        maxWidth: 400,
        minWidth: 140,
        width: 200,
      },
      {
        Header: "Fund raised",
        accessor: "fund_raised",
        size: 200,
      },
      {
        Header: "User growth",
        accessor: "user_growth",
      },
      {
        Header: "Strength",
        accessor: "strength",
        maxWidth: 400,
        minWidth: 140,
        width: 150,
      },
      {
        Header: "Weakness",
        accessor: "weakness",
        maxWidth: 400,
        minWidth: 140,
        width: 200,
      },
    ],
    []
  );

  const getFeedbackData = useCallback(() => {
    return Object.keys(feedbackData?.benchmark_analysis?.competitors || {}).map(
      (item: any) => {
        return {
          competitors: item,
          fund_raised:
            feedbackData?.benchmark_analysis?.competitors[item]?.funds_raised,
          user_growth:
            feedbackData?.benchmark_analysis?.competitors[item]?.users,
          strength:
            feedbackData?.benchmark_analysis?.competitors[item]?.strengths.join(
              ",\r\n"
            ),
          weakness:
            feedbackData?.benchmark_analysis?.competitors[
              item
            ]?.weaknesses.join("\r\n"),
        };
      }
    );
  }, [feedbackData]);

  const tableData = React.useMemo(() => getFeedbackData(), [getFeedbackData]);

  React.useEffect(() => {
    scrollToBottom();
  }, [data, refetch, scrollToBottom, userId]);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="h-screen overflow-scroll relative">
      <div className="fixed flex justify-between items-center px-8 py-4 bg-grey200 z-[1] w-full rounded-bl-2xl">
        <h2 className="text-xl font-semibold">Feedback Page</h2>
      </div>

      <div
        className="flex flex-col relative mt-12 scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch py-6 px-4"
        ref={scrollableDivRef}
      >
        {/* Overview */}
        <div className="p-8">
          <h3 className="text-lg font-semibold mb-2">Overview</h3>
          <p className="text-grey900 text-base">
            {feedbackData?.brief_overview_startup}
          </p>
        </div>

        {/* Swot Analysis */}
        <div className="mt-8 p-8">
          <h3 className="text-lg font-semibold mb-2">SWOT Analysis</h3>
          <div className="grid xl:grid-cols-4 gap-x-5">
            {/* strenght */}
            <div>
              <p className="text-base font-semibold">Strength</p>
              <ul className="!list-disc space-y-2 mt-2">
                {feedbackData?.swot_analysis?.strengths?.map(
                  (element: string, index: number) => (
                    <li className="flex items-center gap-x-2.5" key={index}>
                      <p className="text-base text-grey900">- {element}</p>
                    </li>
                  )
                )}
              </ul>
            </div>

            {/* weaknesses */}
            <div>
              <p className="text-base font-semibold">Weaknesses</p>
              <ul className="!list-disc space-y-2 mt-2">
                {feedbackData?.swot_analysis?.weaknesses?.map(
                  (element: string, index: number) => (
                    <li className="flex items-center gap-x-2.5" key={index}>
                      <p className="text-base text-grey900">- {element}</p>
                    </li>
                  )
                )}
              </ul>
            </div>

            {/* opportunities */}
            <div>
              <p className="text-base font-semibold">Opportunities</p>
              <ul className="!list-disc space-y-2 mt-2">
                {feedbackData?.swot_analysis?.opportunities?.map(
                  (element: string, index: number) => (
                    <li className="flex items-center gap-x-2.5" key={index}>
                      <p className="text-base text-grey900">- {element}</p>
                    </li>
                  )
                )}
              </ul>
            </div>

            {/* threats */}
            <div>
              <p className="text-base font-semibold">Threats</p>
              <ul className="!list-disc space-y-2 mt-2">
                {feedbackData?.swot_analysis?.threats?.map(
                  (element: string, index: number) => (
                    <li className="flex items-center gap-x-2.5" key={index}>
                      <p className="text-base text-grey900">- {element}</p>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>

        {/* Competitor Analysis */}
        <div className="mt-8 p-8">
          <h3 className="text-lg font-semibold mb-2">Competitor Analysis</h3>
          <Table columns={columns} data={tableData} />
        </div>

        <div className="pl-8">
          <div className="mt-2 flex items-center gap-2.5">
            <h2 className="flex items-center leading-none py-2 text-xl font-semibold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-repeat2 mr-1.5 text-muted-foreground"
              >
                <path d="m2 9 3-3 3 3"></path>
                <path d="M13 18H7a2 2 0 0 1-2-2V6"></path>
                <path d="m22 15-3 3-3-3"></path>
                <path d="M11 6h6a2 2 0 0 1 2 2v10"></path>
              </svg>
              Related
            </h2>
          </div>
          {strenghtAI.map((rq: any, index: number) => (
            <div className="flex items-center w-full" key={index}>
              <ArrowRight className="h-4 w-4 mr-2 mt-1 flex-shrink-0" />
              <button
                className="flex-1 justify-start px-0 py-1 h-fit "
                value={rq}
                onClick={() => {
                  scrollToBottom();
                  setQuestion(rq);
                  askDeepDive({
                    id: id,
                    userId: userId,
                    title: "",
                    query: rq,
                  })
                    .then((res) => {
                      setQuestion("");
                      refetch();
                      scrollToBottom();
                    })
                    .catch((err) => {});
                }}
              >
                <p className="font-semidbold text-base  whitespace-normal text-left">
                  {rq}
                </p>
              </button>
            </div>
          ))}
        </div>

        {/*Answer/Question*/}
        <div className="w-full  mx-auto pb-20 pr-8">
          {data?.message_logs.map((item: any, index: number) => {
            const isQuestion = item?.role === "user";
            const parsedConversation = !isQuestion && JSON.parse(item.content);
            // const isLastItem = index === data?.content.length - 1;
            //
            return isQuestion ? (
              <>
                <div
                  style={{ fontSize: 25, marginTop: 40 }}
                  className="flex justify-end items-center"
                >
                  <p className="p-2.5 bg-primary600 min-w-[360px] max-w-[640px] text-sm text-white font-bold rounded-b-md rounded-tl-md">
                    {item.content}
                  </p>
                </div>
              </>
            ) : (
              <div className="mt-5 flex items-start gap-x-2 ml-4">
                <img src={AIBlue} alt="" className="my-1" />
                <div>
                  {/* <Sources sources={parsedConversation.sources ?? []} /> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <h2 className="flex items-center leading-none py-2 text-xl font-semibold">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-book-check mr-1.5 text-muted-foreground"
                      >
                        <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20"></path>
                        <path d="m9 9.5 2 2 4-4"></path>
                      </svg>
                      Answer
                    </h2>
                  </div>

                  {/* <div className="mt-2">{parsedConversation?.answer}</div> */}
                  <AIMessage content={parsedConversation?.answer} />

                  <div className="mt-2 flex items-center gap-2.5">
                    <h2 className="flex items-center leading-none py-2 text-xl font-semibold">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-repeat2 mr-1.5 text-muted-foreground"
                      >
                        <path d="m2 9 3-3 3 3"></path>
                        <path d="M13 18H7a2 2 0 0 1-2-2V6"></path>
                        <path d="m22 15-3 3-3-3"></path>
                        <path d="M11 6h6a2 2 0 0 1 2 2v10"></path>
                      </svg>
                      Related
                    </h2>
                  </div>
                  {parsedConversation?.follow_up_questions.map(
                    (rq: any, index: number) => (
                      <div className="flex items-center w-full" key={index}>
                        <ArrowRight className="h-4 w-4 mr-2 mt-1 flex-shrink-0" />
                        <button
                          className="flex-1 justify-start px-0 py-1 h-fit "
                          value={rq}
                          onClick={() => {
                            scrollToBottom();
                            setQuestion(rq);
                            askDeepDive({
                              id: id,
                              userId: userId,
                              title: "",
                              query: rq,
                            })
                              .then((res) => {
                                setQuestion("");
                                refetch();
                                scrollToBottom();
                              })
                              .catch((err) => {});
                          }}
                        >
                          <p className="font-semidbold text-base  whitespace-normal text-left">
                            {rq}
                          </p>
                        </button>
                      </div>
                    )
                  )}
                </div>
                <hr className="my-6 outline outline-gray-300" />
                {/* {isLastItem && !askIsLoading && (
                  <div style={{ width: "100%", height: "18vh" }} />
                )} */}
              </div>
            );
          })}

          {question !== "" && (
            <>
              <div
                style={{ fontSize: 25, marginTop: 40 }}
                className="flex items-center justify-end"
              >
                <p className="p-2.5 bg-primary600 w-1/3 text-sm text-white font-bold rounded-b-md rounded-tl-md">
                  {question}
                </p>
              </div>
            </>
          )}

          {askDeepDiveIsLoading && (
            <>
              <SearchSkeleton />
              <div style={{ width: "100%", height: "18vh" }} />
            </>
          )}
        </div>

        <div className="fixed w-full mt-5 bottom-0 flex justify-center bg-white py-6 lg:px-48 px-5 lg:left-[10%] ">
          <div className="relative lg:w-1/2 w-full">
            <p
              className="absolute -top-10 right-[4%] bg-primary100 px-3 py-1 rounded-full text-primary700 font-semibold cursor-pointer"
              onClick={showDrawer}
            >
              View Feedback Again!
            </p>
            <input
              ref={inputRef}
              onKeyDown={handleKeyDown}
              placeholder={"Ask MyCofounder.Ai"}
              value={question}
              onChange={({ target: { value } }) => {
                setQuestion(value);
              }}
              className="outline-none border border-[#A5D8FF] rounded-[100px] h-[54px] shadow-[0px_0px_8px_8px_#E7F5FF] pr-[100px] pl-5 text-base text-[#495057] w-full"
            />

            <div className="flex items-center absolute right-2.5 gap-1.5 top-3">
              {askDeepDiveIsLoading ? (
                <PulseLoader
                  color="#228BE6"
                  style={{ marginTop: 10, marginRight: 5 }}
                  size={12}
                />
              ) : (
                <>
                  {/* <div style={{ cursor: 'pointer' }}><img src={newSessionBtn} alt={'newSessionBtn'} /></div> */}
                  <button
                    ref={buttonRef}
                    onClick={async () => {
                      scrollToBottom();
                      askDeepDive({
                        id: id || "",
                        userId: userId,
                        title: "",
                        query: question,
                      })
                        .then((res) => {
                          setQuestion("");
                          if (res.data.new) {
                            navigate(`/ai-feedback/${res.data.id}`);
                          } else {
                            refetch();
                            scrollToBottom();
                          }
                        })
                        .catch((err) => {});
                    }}
                    style={{ cursor: "pointer" }}
                    className="size-8 rounded-full bg-primary p-1.5 mr-2"
                  >
                    <img src={IconWhite} alt={"askBtn"} />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer onClose={onClose} open={open} title={<p>Feedback</p>} width={500}>
        <div>
          <h3 className="text-lg font-semibold mb-2">Startup Overview</h3>
          <p className="text-grey900 text-sm">
            {feedbackData?.brief_overview_startup}
          </p>
        </div>

        {/* Swot Analysis */}
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-2">SWOT Analysis</h3>
          <div className="grid gap-6">
            {/* strenght */}
            <div>
              <p className="text-base font-semibold">Strength</p>
              <ul className="!list-disc">
                {feedbackData?.swot_analysis?.strengths?.map(
                  (element: string, index: number) => (
                    <li className="flex items-center gap-x-2.5" key={index}>
                      <p className="text-base text-grey900">{element}</p>
                    </li>
                  )
                )}
              </ul>
            </div>

            {/* weaknesses */}
            <div>
              <p className="text-base font-semibold">Weaknesses</p>
              <ul>
                {feedbackData?.swot_analysis?.weaknesses?.map(
                  (element: string, index: number) => (
                    <li className="flex items-center gap-x-2.5" key={index}>
                      <p className="text-base text-grey900">{element}</p>
                    </li>
                  )
                )}
              </ul>
            </div>

            {/* opportunities */}
            <div>
              <p className="text-base font-semibold">Opportunities</p>
              <ul>
                {feedbackData?.swot_analysis?.opportunities?.map(
                  (element: string, index: number) => (
                    <li className="flex items-center gap-x-2.5" key={index}>
                      <p className="text-base text-grey900">{element}</p>
                    </li>
                  )
                )}
              </ul>
            </div>

            {/* threats */}
            <div>
              <p className="text-base font-semibold">Threats</p>
              <ul>
                {feedbackData?.swot_analysis?.threats?.map(
                  (element: string, index: number) => (
                    <li className="flex items-center gap-x-2.5" key={index}>
                      <p className="text-base text-grey900">{element}</p>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>

        {/* Competitor Analysis */}
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-2">Competitor Analysis</h3>
          <Table columns={columns} data={tableData} />
        </div>
      </Drawer>
    </div>
  );
}

export default FeedbackAI;
