import React, { useEffect } from 'react'
import { useLoginMutation } from '../../lib/features/auth/authApiSlice'
import { Button, Checkbox, Form, FormProps, Input } from 'antd';
import { errorMessage, successMessage } from '../../constants/notification';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../lib/features/auth/authSlice';
import { setProfile } from '../../lib/features/user/userSlice';
import { useLazyGetStartUpQuery } from '../../lib/features/startup/startupApiSlice';
import { useLazyGetConversationsQuery } from '../../lib/features/conversation/conversationApiSlice';
import { FaLinkedin } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { LINKEDIN_LOGIN_URL } from '../../constants/defaults';
import defaultSmall from '../../assets/linked-in/Sign-In-Small---Default.png';
import defaultLarge from '../../assets/linked-in/Sign-In-Large---Default.png';
import useAnalyticsEventTracker from '../../utils/Analytics';





type FieldType = {
    username?: string;
    password?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
};


function LoginComponent({ isLogin, setIsLogin }: { isLogin: boolean, setIsLogin: React.Dispatch<React.SetStateAction<boolean>> }) {
    const gAnalytics = useAnalyticsEventTracker("Login");

    const [login, { isLoading: loginIsLoading }] = useLoginMutation()
    const [getStartUp] = useLazyGetStartUpQuery()
    const [getConversations] = useLazyGetConversationsQuery()

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const helperLogin = useCallback(async (params: { [key: string]: string }) => {
        await login(params).unwrap().then(async (res: any) => {
            // await updateProfileImage({ userId: res?.userData.id })
            successMessage("Login successful")
            dispatch(authenticate(res.signinResponse.AuthenticationResult))
            dispatch(setProfile(res?.userData))
            getStartUp(res?.userData.id)
            getConversations(res?.userData.id)
            if (res?.userData.watched_tutorial) {
                navigate('/home')
            } else {
                navigate('/first-time-login')
            }
        }).catch((err) => {
            errorMessage(err?.data?.detail || "Failed to login")

        });
    }, [dispatch, getConversations, getStartUp, login, navigate]);

    const location = useLocation();
    useEffect(() => {
        // Extract the code from the URL
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');

        if (code) {
            console.log('Authorization code received:', code);
            // Call the login function with the code
            helperLogin({ username: '', password: '', code: code });
        } else {
            console.error('No authorization code found in URL');
        }
    }, [location, helperLogin]);

    const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
        if (isLogin) {
            try {
                const { username, password } = values
                if (!username || !password) {
                    errorMessage('Complete the fields')
                    return
                } else if (password.length <= 7) {
                    errorMessage("Password should be at least 8 characters long")
                    return
                }


                helperLogin({ username: username, password: password, code: '' });
            }
            catch (e: any) {
                errorMessage(e)

            }
        }
    };



    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {

    };
    return (
        <div className='mt-8'>
            <Form
                layout="vertical"
                style={{ width: 350 }}
                initialValues={{}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"

            >
                <Form.Item<FieldType>
                    hasFeedback
                    label="Email"
                    name="username"
                    rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}

                >
                    <Input className='h-11' placeholder='Enter your email'
                        suffix={
                            <CiMail size={16} className='text-grey' />

                        }
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    hasFeedback
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password className='h-11' placeholder='••••••••' />
                </Form.Item>

                <div className='flex items-center justify-between mb-6'>
                    <div>
                        <Checkbox>Remember me</Checkbox>
                    </div>
                    <div>
                        <Link to={'/forgot-password'} className=''><span className='text-primary cursor-pointer' >Forgot Password?</span></Link>
                    </div>
                </div>

                <Form.Item>
                    <Button
                        disabled={loginIsLoading}
                        loading={loginIsLoading}
                        className='py-4 w-full bg-primary600 text-base font-semibold text-white'
                        htmlType="submit"
                    >
                        Sign in
                    </Button>

                </Form.Item>

                <Button
                    // href={LINKEDIN_LOGIN_URL}
                    // type='text'
                    onClick={() => {
                        gAnalytics("Linkedin Login", "Clicked");
                        window.location.href = LINKEDIN_LOGIN_URL;
                    }}
                    className='py-4 w-full  text-base font-semibold border border-grey300 -mt-2'
                >
                    <FaLinkedin className='text-[#0A66C2]' size={24} />
                    <p>Sign in with Linkedin</p>
                </Button>

            </Form>




            {/* <Button
                        onClick={() => {
                            setIsLogin(false)
                        }}
                        type="dashed"
                        style={{ width: '100%', borderColor: '#228BE6', color: '#228BE6' }}
                        htmlType="submit"
                    >
                        back
                    </Button> */}
        </div>
    )
}

export default LoginComponent
