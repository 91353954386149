import React, { useEffect, useState } from "react";
import Logo from "../../assets/logo.png";
import { useAppSelector } from "../../lib/hooks";
import { RootState } from "../../lib/store";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "lucide-react";
import { GiCheckMark } from "react-icons/gi";
import { useGetFeedbackQuery } from "../../lib/features/user/userApiSlice";
function FeedbackProcess() {
  // const [percent, setPercent] = useState(0);
  // const [semanticCompleted, setSemanticCompleted] = useState(false);
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const semanticNotif = React.useMemo(
    () => [
      "Processing request",
      "Startup Overview",
      "SWOT Analysis",
      "Competitor Analysis",
      "Finalizing...",
    ],
    []
  );


  const [semanticNofifList, setSemanticNofitList] =
    React.useState(semanticNotif);
  // const [timelineItems, setTimelineItems] = useState<TimelineInterface[] | []>(
  //   []
  // );
  // const [pendingNotification, setPendingNotification] = useState("");

  const {
    data: feedbackData,
  } = useGetFeedbackQuery(user?.id);

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prevStep) => {
        if (prevStep >= semanticNotif.length - 1) {
          clearInterval(interval);
          return prevStep;
        }
        return prevStep + 1;
      });
    }, 8000);

    return () => clearInterval(interval);
  }, [semanticNotif.length]);

  useEffect(() => {
    if (feedbackData) {
      navigate("/ai-feedback");
    }
  }, [feedbackData, navigate]);

  // useEffect(() => {
  //   if (!semanticCompleted) {
  //     const updateTimeline = (index: number) => {
  //       if (index < semanticNotif.length) {
  //         setTimelineItems(prevItems => [
  //           ...prevItems,
  //           { children: semanticNotif[index] }
  //         ]);
  //         setCurrentStep((prevStep) => {
  //           // If all steps are complete, stop the interval
  //           if (prevStep >= semanticNofifList.length - 1) {
  //             // clearInterval(interval);
  //             return prevStep;
  //           }
  //           return prevStep + 1;
  //         });

  //         setPendingNotification(semanticNotif[index + 1] || "Finalizing...");
  //       }
  //     };

  //     // Update timeline at intervals
  //     const intervals = [16000, 32000, 58000, 70000];
  //     intervals.forEach((delay, index) => {
  //       setTimeout(() => updateTimeline(index), delay);
  //     });

  //   }
  // }, [semanticCompleted, semanticNofifList.length, semanticNotif]);

  // useEffect(() => {
  //   let currentPercent = 0;
  //   const targetPercent = 99;
  //   const duration = 2 * 60 * 1000;
  //   const increment = Math.ceil((targetPercent / duration) * 1000);

  //   const id = setInterval(() => {
  //     currentPercent += increment;
  //     if (currentPercent >= targetPercent) {
  //       currentPercent = targetPercent;
  //       clearInterval(id);
  //     }
  //     setPercent(prev => (prev < 100 ? currentPercent : prev));
  //   }, 1000);

  //   return () => clearInterval(id);
  // }, []);

  return (
    <div className="h-[90vh] overflow-hidden flex justify-center items-start mt-[5%]">
      <div className="max-w-[1000px] w-full max-h-[530px]">
        <div className="flex flex-col items-center justify-center w-full mt-5 gap-x-4">
          <img src={Logo} alt="" className="h-[32px]" />
          <p className="mt-8 font-bold text-3xl text-grey900">
            Generating Feedback...
          </p>
          <p className="mt-6 max-w-[520px] text-grey600 text-center">
            Hang tight while we analyze the contents and get a feeback for you.
            This might take a moment
          </p>

          {/* <div className='mt-5 mb-7 max-w-[500px] w-full'>
            <Progress percent={percent} />
          </div> */}

          {/* {isLoading &&

            <div>
              <div className='mt-6'>
                <div className='flex items-center gap-x-2'>
                  <Timeline className='text-2xl font-bold'
                    pending={pendingNotification}
                    reverse={false}
                    items={timelineItems}
                  />
                  <AiOutlineLoading3Quarters className='animate-spin text-primary' />
                  {pendingNotification}
                </div>
              </div>

            </div>
          } */}

          <div className="w-full max-w-xl mx-auto space-y-1 p-4 rounded-lg mt-10">
            {semanticNofifList.map((text, index) => {
              return (
                index <= currentStep && (
                  <div
                    key={index}
                    className="flex items-center justify-between p-4  rounded-md"
                  >
                    <div className="flex items-center space-x-4">
                      <div
                        className={`flex-shrink-0 w-6 h-6 bg-[#D1FAE7] rounded-full flex items-center justify-center ${
                          index < currentStep ? "" : "animate-pulse"
                        } `}
                      >
                        {/* Check icon only visible for completed steps */}
                        {index < currentStep && (
                          <GiCheckMark className="w-4 h-4 text-[#12B76A]" />
                        )}
                      </div>
                      {/* Show text if currentStep is >= index, otherwise show Skeleton */}
                      {index <= currentStep ? (
                        <span className="">{text}</span>
                      ) : (
                        <div className="h-4 w-64 bg-gray-700 animate-pulse rounded-full" />
                      )}
                    </div>
                    <ChevronDownIcon className="w-5 h-5 text-gray-500" />
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedbackProcess;
