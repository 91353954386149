import React from "react";

function Privacy() {
  return (
    <section className="space-y-12 my-10">
      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Introduction
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          MyCofoundr.AI ("we," "our," or "us") is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you visit our website or use our
          services.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Information We Collect
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          We collect several types of information from and about users of our
          Services, including:
          <ul className=" mt-2 ml-5">
            <li>
              <strong>Personal Information:</strong>
              <ul className="list-disc mt-2 ml-5">
                <li>
                  Contact information (e.g., name, email address, phone number)
                </li>
                <li>Account credentials</li>
                <li>Payment information</li>
                <li>
                  Professional information (e.g., company name, job title,
                  industry)
                </li>
                <li>Any other information you choose to provide</li>
              </ul>
            </li>
            <li>
              <strong>Usage Data:</strong>{" "}
              <ul className="list-disc mt-2 ml-5">
                <li>IP address</li>
                <li>Browser type and version</li>
                <li>Operating system</li>
                <li>Date and time of visits</li>
                <li>Pages viewed</li>
                <li>Links clicked</li>
                <li>Search terms used</li>
                <li>Referring website addresses</li>
              </ul>
            </li>
            <li>
              <strong>Device Information:</strong>
              <ul className="list-disc mt-2 ml-5">
                <li>Device type</li>
                <li>Unique device identifiers</li>
                <li>Network information</li>
              </ul>
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          How We Collect Information
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          We collect information:
          <ul className="list-disc mt-2 ml-5">
            <li>Directly from you when you provide it to us.</li>
            <li>
              Automatically as you navigate through the site or use our
              services.
            </li>
            <li>
              From third parties, such as business partners and analytics
              providers.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          How We Use Your Information
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          We use the information we collect about you or that you provide to us,
          including any personal information:
          <ul className="list-disc mt-2 ml-5">
            <li>To provide, maintain, and improve our Services.</li>
            <li>To process transactions and send related information.</li>
            <li>
              To send administrative information, such as updates, security
              alerts, and support messages.
            </li>
            <li>To respond to your comments, questions, and requests.</li>
            <li>
              To personalize your experience and deliver content and product
              offerings relevant to your interests.
            </li>
            <li>
              To monitor and analyze trends, usage, and activities in connection
              with our Services.
            </li>
            <li>To carry out our obligations and enforce our rights.</li>
            <li>To comply with legal obligations.</li>
          </ul>
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Data Retention
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          We will retain your personal information only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use your information to the extent necessary to comply with
          our legal obligations, resolve disputes, and enforce our policies.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Data Security
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration, and disclosure. However, the transmission of information
          via the internet is not completely secure. Although we do our best to
          protect your personal information, we cannot guarantee the security of
          your personal information transmitted to our Services. Any
          transmission of personal information is at your own risk.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Your Rights
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          Depending on your location, you may have certain rights regarding your
          personal information, such as:
          <ul className="list-disc mt-2 ml-5">
            <li>The right to access personal information we hold about you.</li>
            <li>
              The right to request the correction of inaccurate personal
              information.
            </li>
            <li>The right to request deletion of your personal information.</li>
            <li>
              The right to object to processing of your personal information.
            </li>
            <li>
              The right to request restriction of processing of your personal
              information.
            </li>
            <li>
              The right to request the transfer of your personal information to
              another party.
            </li>
          </ul>
          <br />
          To exercise any of these rights, please contact us using the
          information provided in the "Contact Us" section.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Children's Privacy
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          Our Services are not intended for children under 13 years of age. We
          do not knowingly collect personal information from children under 13.
          If you are under 13, do not use or provide any information on our
          Services. If we learn we have collected or received personal
          information from a child under 13 without verification of parental
          consent, we will delete that information.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Changes to Our Privacy Policy
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          We may update our Privacy Policy from time to time. If we make
          material changes to how we treat our users' personal information, we
          will notify you through a notice on the Services or by email to the
          primary email address specified in your account. The date the Privacy
          Policy was last revised is identified at the top of the page. You are
          responsible for periodically visiting our Services and this Privacy
          Policy to check for any changes.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Contact Information
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          To ask questions or comment about this Privacy Policy and our privacy
          practices, contact us at:
          <br />
          gary.stewart@mycofoundr.ai
          <br />
          MyCofoundr.AI
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          International Transfers
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          If you are accessing our Services from outside the United States,
          please be aware that your information may be transferred to, stored,
          and processed by us and our affiliates in our facilities and by those
          third parties with whom we may share your personal information, in the
          United States and other countries. These countries may have data
          protection laws that are different from the laws of your country.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          California Privacy Rights
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          If you are a California resident, you have additional rights under the
          California Consumer Privacy Act (CCPA). Please refer to our California
          Privacy Notice for more information.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Do Not Track Signals
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          We do not currently respond to "do not track" signals or other
          mechanisms that might enable users to opt out of tracking on our site.
        </p>
      </div>

      <div>
        <h3 className="text-3xl font-semibold mt-3 xl:text-left text-left text-grey900">
          Third-Party Links
        </h3>
        <p className="text-grey600 text-base font-regular mt-5 text-center xl:text-left">
          Our Services may contain links to third-party websites and services.
          We are not responsible for the content or privacy practices of those
          websites or services. We encourage you to read the privacy policies of
          any third-party websites you visit.
          <br />
          <br />
          <br />
          By using our Services, you acknowledge that you have read and
          understood this Privacy Policy and agree to its terms.
        </p>
      </div>
    </section>
  );
}

export default Privacy;
