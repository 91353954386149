import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Upload, UploadProps } from "antd";
import { useAppSelector } from "../../lib/hooks";
import { RootState } from "../../lib/store";
import { BASE_URL } from "../../lib/services/reAuth";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../lib/features/user/userApiSlice";
import { errorMessage, successMessage } from "../../constants/notification";
import { Edit } from "lucide-react";
import useAnalyticsEventTracker from "../../utils/Analytics";
import UploadIcon from "../../components/Icons/UploadIcon";
import Logo from "../../assets/logo.png";
import LikeGif from "../../assets/gifs/like.gif";
import UploadGif from "../../assets/gifs/upload.gif";

function CTA() {
  const gAnalytics = useAnalyticsEventTracker("onBoarding");

  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const [updateUser] = useUpdateUserMutation();

  const accessToken = useAppSelector((state: RootState) => state.auth.access);
  const startupData = useAppSelector((state: RootState) => state.startup);

  const { refetch: userDataRefetch } = useGetUserQuery(user?.id);

  const props: UploadProps = {
    name: "file",
    action: `${BASE_URL}pitch_deck_uploader/uploading_pitch_deck/`,
    method: "POST",
    accept: ".pdf,.ppt,.pptx,.doc,.docx",
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    data: {
      user_id: user?.id,
      startup_id: startupData?.id,
    },
    onChange(info) {
      gAnalytics("Chose to Upload Pitch deck", user?.email || "User");
      console.log(info.file.status, "status");
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        updateUser({ userId: user?.id, uploaded_pitch_deck: true })
          .then((res) => {
            userDataRefetch().then(() => {
              navigate("/process-pitch-deck");
            });
          })
          .catch((err) => {
            // errorMessage("Failed to update information")
          });
        successMessage(`${info.file.name} file uploaded successfully`);
        // navigate('/ai-complete-profile')
      } else if (info.file.status === "error") {
        errorMessage(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <section className="bg-white h-screen xl:px-[100px] px-10 py-6 overflow-y-scroll">
      <div className="h-[90%] flex flex-col items-center justify-center py-8 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6">
        <div className="flex flex-col items-center gap-y-5">
          <img src={Logo} style={{ height: 48 }} alt={"logo"} />
          <p className="text-2xl font-medium">
            Choose how you'd like to share you startup's details
          </p>
        </div>
        <div className="mt-11 space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
          <div className="rounded-lg border border-gray-100 shadow flex items-center lg:min-w-[480px]">
            <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white xl:p-8">
              <div className="flex items-center justify-center">
                <img src={LikeGif} alt="" className="size-20" />
              </div>
              <h3 className="mb-4 mt-2 text-lg font-semibold">
                Let's Build Your Business
              </h3>
              <p className="font-light text-gray-500 sm:text-[13px] max-w-[285px]">
                Add your startup's information yourself for a more personalized
                touch
              </p>
              <div className="flex justify-center items-baseline my-8 w-full">
                <Link
                  to={"/ai-complete-profile"}
                  className="flex w-full justify-center items-center gap-x-3 bg-primary600 text-[15px] text-white px-6 py-3 rounded-lg text-sm font-normal text-center"
                >
                  <div className="flex items-center gap-x-1">
                    <Edit className="size-4" />
                    <p>Start Entering Details</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="lg:min-w-[480px] rounded-lg border border-gray-100 shadow flex items-center">
            <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white  xl:p-8">
              <div className="flex items-center justify-center">
                <img src={UploadGif} alt="" className="size-20" />
              </div>
              <h3 className="mb-4 mt-2 text-lg font-semibold">
                Upload Your Pitch Deck
              </h3>
              <p className="font-light text-gray-500 sm:text-[13px] max-w-[400px]">
                Upload your pitch deck and let our AI do the heavy lifting by
                analyzing it for key insights.
              </p>
              <div className="flex justify-center items-baseline my-7 w-full ">
                <Upload {...props} className="w-full">
                  {/* <Button size='large' type='primary' className='!bg-primary' icon={<UploadOutlined />}>Click to Upload</Button> */}
                  <button className="flex w-full justify-center items-center gap-x-3 bg-primary600 text-[15px] text-white px-6 py-3 rounded-lg text-sm font-normal text-center">
                    <UploadIcon />
                    <p>Upload Pitch Deck</p>
                  </button>
                </Upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTA;
