
import { createSlice } from '@reduxjs/toolkit'
import { startupApiSlice } from './startupApiSlice';

interface StartUp {
    id?: string;
    name_of_start_up?: string;
    about_start_up?: string;
    product_stages?: string;
    industry?: [];
    monthly_recurring_revenue?: string;
    users?: string;
    foundername?: string;
    founder_bios?: string;
    prominent_investor_bios?: string;
    fund_raised?: string;
    award_won?: string;
    name_of_co_founders?: string;
    problem?: string;
    solution?: string;
    market?: string;
    competitive_advantages?: string;
    good_investment_case?: string;
    mvp?: boolean;
    use_of_fund?: object;
    capital_seeking?: string;
    pre_money_valuation?: string;
    private_fund_raised_bcr?: string;
    public_fund_raised_bcr?: string;
}

const initialData: StartUp = {
    id: "",
    name_of_start_up: '',
    about_start_up: '',
    product_stages: '',
    industry: [],
    monthly_recurring_revenue: '',
    users: '',
    foundername: '',
    founder_bios: '',
    prominent_investor_bios: '',
    fund_raised: '',
    award_won: '',
    name_of_co_founders: '',
    problem: '',
    solution: '',
    market: '',
    competitive_advantages: '',
    good_investment_case: '',
    mvp: false,
    use_of_fund: {},
    capital_seeking: '',
    pre_money_valuation: '',
    private_fund_raised_bcr: '',
    public_fund_raised_bcr: '',
};


const startupSlice = createSlice({
    name: 'startup',
    initialState: initialData,
    reducers: {
        setStartUpData: (state, action) => {
            state = { ...state, ...action.payload };
            console.log(state)
            return state
        },
        unSetStartUpData: state => {
            return initialData
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(startupApiSlice.endpoints.getStartUp.matchPending, (state, action) => {
                return
            })
            .addMatcher(startupApiSlice.endpoints.getStartUp.matchFulfilled, (state, action) => {
                state = { ...state, ...action.payload };
                return state
            })
            .addMatcher(startupApiSlice.endpoints.getStartUp.matchRejected, (state, action) => {
                return state
            })
    },
})


export const { setStartUpData, unSetStartUpData } = startupSlice.actions
export default startupSlice.reducer