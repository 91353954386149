import React from "react";
import { FaInstagram, FaLinkedin, FaYoutube, FaTiktok } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { Link as ScrollLink } from "react-scroll";

function Footer() {
  return (
    <footer className="pt-16 pb-12 max-w-[1440px] mx-auto px-4">
      <div className="grid xl:grid-cols-3 gap-16 border-b pb-16">
        <div>
          <img src={Logo} style={{ height: 32 }} alt={"myCofoundr.ai logo"} />
          <p className="mt-8 xl:max-w-80 text-base text-grey600">
            Fueling startups with AI-driven investor connections.
          </p>
        </div>
        <div>
          <p className="text-base font-semibold text-grey">Navigation</p>
          <ul className="mt-4 space-y-3 text-sm font-semibold text-grey600 cursor-pointer">
            <li>
              <Link to={"/"} className="text-grey600">
                Home
              </Link>
            </li>
            <li>
              <ScrollLink
                to="AboutUs"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                About Us
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="Solution"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Our Solution
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="Testimonials"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Testimonials
              </ScrollLink>
            </li>
            <li>
            <ScrollLink
                to="FAQ"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                FAQ
              </ScrollLink>
            </li>
            <li>
              <Link to={"/pricing"} className="text-grey600">
                Pricing
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <p className="text-sm font-semibold text-grey">Legal</p>
          <ul className="mt-4 space-y-3 text-sm font-semibold text-grey600">
            <li>
              <Link to={"/terms-and-privacy"} className="text-grey600">
                Terms
              </Link>
            </li>
            <li>
              <Link to={"/terms-and-privacy"} className="text-grey600">
                Privacy
              </Link>
            </li>
            {/* <li>
              <Link to={"/cookies"} className="text-grey600">
                Cookies
              </Link>
            </li>
            <li>
              <Link to={"/licenses"} className="text-grey600">
                Licenses
              </Link>
            </li>
            <li>
              <Link to={"/settings"} className="text-grey600">
                Settings
              </Link>
            </li> */}
            <li>
              <Link to={"/contact"} className="text-grey600">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="pt-8 xl:flex items-center justify-between">
        <p className="text-base text-grey text-center xl:text-left">
          © MyCofoundr.AI. All rights reserved.
        </p>
        <div className="flex items-center justify-center xl:justify-normal gap-x-6 mt-4 xl:mt-0">
          <Link
            to={"https://x.com/MyCofoundrAI"}
            target="_blank"
            rel="noreferrer"
          >
            <RiTwitterXLine className="size-6 text-grey400" />
          </Link>
          <Link
            to={"https://www.linkedin.com/company/mycofoundrai/"}
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin className="size-6 text-grey400" />
          </Link>
          <Link
            to={
              "https://www.instagram.com/mycofoundr.ai?igsh=MWxibzMxM20yMXN5OQ=="
            }
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="size-6 text-grey400" />
          </Link>
          <Link
            to={"https://www.youtube.com/@MyCofoundrAI"}
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube className="size-6 text-grey400" />
          </Link>
          <Link
            to={"https://www.tiktok.com/@mycofoundrai"}
            target="_blank"
            rel="noreferrer"
          >
            <FaTiktok className="size-6 text-grey400" />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
