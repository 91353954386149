import { apiSlice } from "../../services/apiSlice";


export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUser: builder.query({
            query: (userId) => ({
                url: `users/${userId}`,
                method: 'GET',
            })
        }),
        updateUser: builder.mutation({
            query: (credentials) => ({
                url: `users/update/${credentials.userId}/`,
                method: 'PATCH',
                body: credentials
            })
        }),
        updateUserProfileImg: builder.mutation({
            query: (credentials: { userId: string | undefined, formData: FormData }) => ({
                url: `users/upload-image/${credentials.userId}/`,
                method: 'POST',
                body: credentials.formData,
            })
        }),
        getProfileImage: builder.query({
            query: (credentials: { userId: string | undefined }) => ({
                url: `users/image-url/${credentials.userId}/`,
                method: 'GET',
            })
        }),
        updateFeedback: builder.mutation({
            query: (credentials) => ({
                url: `users/submit-feedback/${credentials.userId}/`,
                method: 'POST',
                body: { feedback: credentials.feedback }
            })
        }),
        updateSubResponse: builder.mutation({
            query: (credentials) => ({
                url: `users/submit-response/${credentials.userId}/`,
                method: 'POST',
                body: { response: credentials.response }
            })
        }),
        getFeedback: builder.query({
            query: (userId) => ({
                url: `feedback/generating_template/${userId}/`,
                method: 'GET',
            })
        }),
        aiFaqFeedback: builder.mutation({
            query: (credentials) => ({
                url: `feedback/ai_faq_agent/`,
                method: 'POST',
                body: {
                    user_id: credentials.userId,
                    question: credentials.question,
                    startup_id: credentials.startupId,
                }
            })
        }),
        getSingleFeedbackAIFAQConversation: builder.query({
            query: (data) => ({
                url: `feedback/faq_conversation/${data.userID}/${data.startup_id}`,
                method: 'GET',
            })
        }),
        deleteFeedbackChat: builder.mutation({
            query: (data) => ({
                url: `feedback/faq_conversation/clear_chat/${data.userID}/${data.startup_id}`,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetUserQuery,
    useUpdateUserMutation,
    useUpdateUserProfileImgMutation,
    useUpdateFeedbackMutation,
    useUpdateSubResponseMutation,
    useGetProfileImageQuery,
    useGetFeedbackQuery,
    useAiFaqFeedbackMutation,
    useGetSingleFeedbackAIFAQConversationQuery,
    useDeleteFeedbackChatMutation

} = userApiSlice