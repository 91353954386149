/* eslint-disable jsx-a11y/alt-text */
import React, { FC, useState } from 'react'
import askBtn from '../../assets/svg/askBtn.svg'
import { garySvg } from '../../api/index';
import { manuelaSvg } from '../../api/index';
import { valentinaSvg } from '../../api/index';
import arrowDown from '../../assets/svg/arrowDown.svg'
import {  useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { Navigate, useNavigate } from "react-router-dom";
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { RootState } from '../../lib/store';
import { useAskMutation, useGetConversationsQuery } from '../../lib/features/conversation/conversationApiSlice';
import { useAppSelector } from '../../lib/hooks';
import { IconBlue, IconWhite } from '../../assets';

interface IProps {
}

export const AskAnything: FC<IProps> = () => {
    const [question, setQuestion] = useState('');
    const userId = useAppSelector((state: RootState) => state.user?.id)

    const [select, setSelect] = useState<"Gary" | "Manuela" | "Valentina">('Gary');
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)

    const { refetch: conversationRefetch } = useGetConversationsQuery(userId)

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // Trigger button click
            buttonRef.current?.click();
        }
    };



    const [ask, { isLoading: askIsLoading }] = useAskMutation()

    const hint = [
        { id: 1, title: "How do I know if my startup is “venture-backable”?" },
        { id: 2, title: "When is the right time to seek funding for my startup?" },
        { id: 3, title: "How much funding should I try to raise?" },
        { id: 4, title: "What type of funding is best for my startup at this stage (e.g. angel, VC, accelerator, etc.)?" },
        { id: 5, title: "How do I determine the valuation for my company?" },
        { id: 6, title: "What do investors look for in a startup before investing?" },
        { id: 7, title: "How do I find the right investors for my company?" },
        { id: 8, title: "What should I include in my pitch deck?" },
        { id: 9, title: "How do I prepare for investor meetings?" },
        { id: 10, title: "What are the different stages of funding (pre-seed, seed, Series A, B, etc.)?" },
        { id: 11, title: "How much equity should I expect to give up?" },
        { id: 12, title: "What are the typical terms in a term sheet?" },
        { id: 13, title: "How long does the fundraising process usually take?" },
        { id: 14, title: "How do I create financial projections for investors?" },
        { id: 15, title: "What metrics should I focus on to attract investors?" },
        { id: 16, title: "How do I demonstrate product-market fit to investors?" },
        { id: 17, title: "What are the pros and cons of taking on outside investment?" },
        { id: 18, title: "How do I negotiate with investors?" },
        { id: 19, title: "What are the legal considerations when raising funding?" },
        { id: 20, title: "How do I structure my cap table?" },
        { id: 21, title: "What happens if I can't raise the full amount I'm targeting?" }
    ];





    const items: MenuProps['items'] = [
        {
            key: '1',
            label: 'Gary',
            onClick: (info) => handleSelect(info.key),
            icon: <img src={garySvg} />
        },
        {
            key: '2',
            label: 'Manuela',
            onClick: (info) => handleSelect(info.key),
            icon: <img src={manuelaSvg} />
        },
        {
            key: '3',
            label: 'Valentina',
            onClick: (info) => handleSelect(info.key),
            icon: <img src={valentinaSvg} />
        },

    ];

    const handleSelect = (key: string) => {
        switch (key) {
            case '1':
                setSelect('Gary')
                return

            case '2':
                setSelect('Manuela')
                return

            case '3':
                setSelect('Valentina')
                return
        }
    }

    const handleSelectBtn = (key: string) => {
        switch (key) {
            case 'Gary':
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                        <img src={garySvg} />
                        <div style={{ fontSize: 16 }}>Gary</div>
                        <img src={arrowDown} style={{ marginTop: 4 }} />
                    </div>
                )

            case 'Manuela':
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                        <img src={manuelaSvg} />
                        <div style={{ fontSize: 16 }}>Manuela</div>
                        <img src={arrowDown} style={{ marginTop: 4 }} />
                    </div>
                )

            case 'Valentina':
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                        <img src={valentinaSvg} />
                        <div style={{ fontSize: 16 }}>Valentina</div>
                        <img src={arrowDown} style={{ marginTop: 4 }} />
                    </div>
                )
        }
    }

    if (!isAuthenticated) {

        return <Navigate to={"/"} />
    }

    return (
        <div className='flex flex-col items-center h-screen justify-center'
        >
            {/*TITLE*/}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 8,
                alignItems: 'center',
                fontSize: 36,
                fontWeight: '600',
                color: '#343A40'
            }}>
                <div style={{ marginBottom: 0 }}>Ask me anything</div>

                {/* <Dropdown
                    onOpenChange={() => {
                    }}

                    menu={{ items }}
                >
                    <div style={{ border: '4px solid #D0EBFF', borderRadius: 100, padding: 4 }}>
                        {handleSelectBtn(select)}
                    </div>
                </Dropdown>

                <div style={{ marginBottom: 0 }}>anything!</div> */}
            </div>

            {/*ASK*/}
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20,
                }} className='lg:w-min w-full px-3'
            >
                <input
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    placeholder={'Ask MyCofoundr.Ai'}
                    value={question}
                    onChange={({ target: { value } }) => {
                        setQuestion(value)
                    }}
                    style={{
                        outline: 'none',
                        borderRadius: 100,
                        height: 54,
                        paddingRight: 100,
                        paddingLeft: 20,
                        fontSize: 16,
                        color: '#495057'
                    }} className='lg:w-[40vw] w-full border-2 mb-2 border-primary'
                />

                <div className='flex items-center absolute gap-x-1.5 right-5 top-3'>
                    {askIsLoading
                        ? <PulseLoader color="#228BE6" style={{ marginTop: 10, marginRight: 5 }} size={12} />
                        : <>
                            {/* <div style={{ cursor: 'pointer' }}>
                                <img src={newSessionBtn} alt={'newSessionBtn'} />
                            </div> */}
                            <button
                                ref={buttonRef}
                                onClick={async () => {
                                    ask({
                                        id: "",
                                        userId: userId,
                                        title: "",
                                        chatPersona: select,
                                        content: [
                                            {
                                                role: "user",
                                                content: question
                                            }
                                        ]
                                    }).then((res) => {

                                        navigate(`/answer/${res.data.id}`)
                                        // getConversations(res?.data?.userId)
                                        conversationRefetch()
                                    }).catch((err) => {

                                    })
                                }}
                                style={{ cursor: 'pointer' }} className='size-8 rounded-full bg-primary p-1.5 mr-2'>
                                <img src={IconWhite} alt={'askBtn'} />
                            </button>
                        </>
                    }
                </div>
            </div>

            {/* <div
                style={{
                    display: 'flex',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20
                }}
            >
                <img src={hints} style={{ resize: 'horizontal' }} alt={'hints'} />
            </div> */}

            <div className='flex flex-wrap items-center gap-2  mt-4 max-w-[40vw]'>
                {hint.sort(() => 0.5 - Math.random()).slice(0, 4).map((item, index) => (
                    <button className='text-xs flex items-center justify-center gap-x-1.5 border py-1 px-2.5 bg-grey100 rounded-full cursor-pointer' key={index}
                        disabled={askIsLoading}
                        onClick={() => {
                            setQuestion(item.title)
                            ask({
                                id: "",
                                userId: userId,
                                title: "",
                                chatPersona: select,
                                content: [
                                    {
                                        role: "user",
                                        content: item.title
                                    }
                                ]
                            }).then((res) => {

                                navigate(`/answer/${res.data.id}`)
                                // getConversations(res?.data?.userId)
                                conversationRefetch()
                            }).catch((err) => {

                            })
                        }}
                    >
                        <div className='bg-grey700 size-2 rounded-full'></div>
                        <p className='text-sm text-grey700 font-medium'>{item.title}</p>
                    </button>
                ))}


            </div>
        </div>
    )
};

