import React, { ReactNode } from "react";
// import fillForm from '../../../assets/svg/fillForm.svg'
import { Form, Input, Select, Button, Checkbox, Tooltip } from "antd";
// import moment from 'moment';
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../../lib/features/user/userApiSlice";
import { useAppSelector } from "../../../lib/hooks";
import { RootState } from "../../../lib/store";
import { errorMessage, successMessage } from "../../../constants/notification";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import { useNavigate } from "react-router-dom";
import { IoIosInformationCircleOutline } from "react-icons/io";
// Mock data for choices, replace these with actual imports or values

export interface Country {
  id: number;
  name: string;
  iso3: string;
  iso2: string;
  numeric_code: string;
  phone_code: number;
  capital: string;
  currency: string;
  currency_name: string;
  currency_symbol: string;
  tld: string;
  native: string;
  region: string;
  subregion: string;
  latitude: string;
  longitude: string;
  emoji: string;
}

export interface State {
  id: number;
  name: string;
  state_code: string;
  latitude: string;
  longitude: string;
}

const { Option } = Select;

function DemographicsInfo({ complete }: { complete: boolean }) {
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.user);

  const [countryid, setCountryid] = React.useState(0);
  const [stateid, setStateid] = React.useState(0);

  const [countriesList, setCountriesList] = React.useState<Country[]>([]);
  const [stateList, setStateList] = React.useState<State[]>([]);
  const [cityList, setCityList] = React.useState([]);

  const [updateUser, { isLoading: updateUserLoading }] =
    useUpdateUserMutation();

  const { refetch: userDataRefetch } = useGetUserQuery(user?.id, {
    refetchOnMountOrArgChange: true,
  });

  React.useEffect(() => {
    GetCountries().then((result: any) => {
      setCountriesList(result);
    });
  }, []);

  const [form] = Form.useForm();

  const initialValues = {
    under_school: user?.under_school || "",
    graduate_school: user?.graduate_school || "",
    highest_degree: user?.highest_degree || "",
    entrepreneurial_experience: user?.entrepreneurial_experience || "",
    race: user?.race || "",
    pre_accelerators: user?.pre_accelerators || "",
    recent_employer: user?.recent_employer || "",
    penultimate_employer: user?.penultimate_employer || "",
    employers_last_10_years: user?.employers_last_10_years || "",
    gender: user?.gender || "",
    sexual_orientation: user?.sexual_orientation || "",
    religion: user?.religion || "",
    nationality: user?.nationality || "",
    country: user?.country || "",
    city: user?.city || "",
    state: user?.state || "",
    ethnicity: user?.ethnicity || "",
    interests: user?.interests?.toString() || "",
    hobbies: user?.hobbies?.toString() || "",
    relationship_status: user?.relationship_status || "",
    disability_status: user?.disability_status || "",
    has_kids: user?.has_kids || "",
    us_first_gen: user?.us_first_gen || false,
    dedication: user?.dedication || "",
    name_full_time_co_founder: user?.name_full_time_co_founder || "",
    name_part_time_co_founder: user?.name_part_time_co_founder || "",
  };

  const handleFinish = async (values: any) => {
    const {
      dedication,
      entrepreneurial_experience,
      name_full_time_co_founder,
      name_part_time_co_founder,
      pre_accelerators,
      recent_employer,
      penultimate_employer,
      employers_last_10_years,
      under_school,
      graduate_school,
      highest_degree,
      ethnicity,
      disability_status,
      has_kids,
      us_first_gen,
      sexual_orientation,
      religion,
      nationality,
      city,
      relationship_status,
      race,
      gender,
      interests,
      hobbies,
    } = values;
    await updateUser({
      userId: user?.id,
      dedication,
      entrepreneurial_experience,
      name_full_time_co_founder,
      name_part_time_co_founder,
      pre_accelerators,
      recent_employer,
      penultimate_employer,
      employers_last_10_years,
      under_school,
      graduate_school,
      highest_degree,
      ethnicity,
      disability_status,
      has_kids,
      us_first_gen: us_first_gen.toString(),
      sexual_orientation,
      religion,
      nationality,
      country: countriesList.find((country: any) => country?.id === countryid)
        ?.name,
      city,
      relationship_status,
      race,
      state: stateList.find((state: any) => state?.id === stateid)?.name,
      gender,
      interests: interests
        ? interests.split(",").map((interest: string) => interest.trim())
        : [],
      // interests: interests,
      // hobbies: hobbies,
      hobbies: hobbies
        ? hobbies.split(",").map((hobby: string) => hobby.trim())
        : [],
    })
      .then((res) => {
        console.log(res);
        if (res?.error) {
          errorMessage("Something went wrong, Please try again later");
          return;
        }
        userDataRefetch();
        successMessage("Profile updated successfully");
        if (complete) {
          navigate("/recommendation");
        }
      })
      .catch((err) => {
        console.log(err);
        errorMessage("Something went wrong, Please try again later");
      });
  };
  return (
    <>
      <div className="flex justify-between gap-x-8 mt-7">
        {/*LEFT*/}
        <div className="w-full">
          <div className="flex flex-col flex-wrap">
            {/* <div>
                            <div
                                style={{
                                    padding: '24px 0'
                                }}
                            >
                                <div style={{ fontSize: 16, color: '#495057' }}>
                                    Complete your info to help our recommendation engine find the best investors for
                                    you.
                                </div>
                                <div style={{ fontSize: 16, color: '#868E96', marginTop: 5 }}>
                                    Your info is private and only used to instruct the AI to be more useful to you.
                                </div>
                            </div>
                        </div> */}

            <div>
              <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                initialValues={initialValues}
                requiredMark={(
                  label: ReactNode,
                  info: { required: boolean }
                ) => {
                  return info.required ? (
                    <div className="flex items-center gap-x-1">
                      <p className="text-[#0F172A] font-medium text-sm">
                        {label}
                      </p>
                      <span className="text-red-600">*</span>
                      {/* <p className='bg-[#BDDCFF] text-[#4083CE] rounded-md text-[10px] px-2 py-0.5 font-medium'>Required</p> */}
                    </div>
                  ) : (
                    <p className="text-[#0F172A] font-medium text-sm">
                      {label}
                    </p>
                  );
                }}
              >
                <div className="grid lg:grid-cols-3 gap-x-5">
                  <Form.Item
                    name="recent_employer"
                    // label="Recent Employer"
                    label={
                      <div className="flex gap-x-1 items-center">
                        <p>Recent Employer</p>
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input your recent employer, if this does not apply, please write None",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Recent Employer (if this does not apply, please write None)"
                      suffix={
                        <Tooltip
                          title="A recent employer refers to the most recent organization or company where an individual has been employed. It is the last workplace or business entity in which the person worked before their current employment status."
                          placement="right"
                        >
                          <IoIosInformationCircleOutline />
                        </Tooltip>
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="penultimate_employer"
                    // label="Penultimate Employer"
                    label={
                      <div className="flex gap-x-1 items-center">
                        <p>Penultimate Employer</p>
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input your penultimate employer, if this does not apply, please write None",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Penultimate Employer (if this does not apply, please write None"
                      suffix={
                        <Tooltip
                          title="The penultimate employer refers to the organization or company where an individual was employed just before their most recent employer. It is the second-to-last place of employment in the individual's work history."
                          placement="right"
                        >
                          <IoIosInformationCircleOutline />
                        </Tooltip>
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="employers_last_10_years"
                    // label="Employers in the Last 10 Years"
                    label={
                      <div className="flex gap-x-1 items-center">
                        <p>Employers in the Last 10 Years</p>
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input your employers in the last 10 years, if this does not apply, please write None",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Employers in the Last 10 Years (if this does not apply, please write None)"
                      suffix={
                        <Tooltip
                          title="This refers to all the organizations or companies where an individual has been employed during the past decade. It includes every place of employment within that time frame, regardless of the duration of each job or the number of employers."
                          placement="right"
                        >
                          <IoIosInformationCircleOutline />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                </div>

                <div className="grid lg:grid-cols-3 gap-x-5">
                  <Form.Item
                    name="under_school"
                    label="Undergraduate School"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input your undergraduate school, if this does not apply, please write None",
                      },
                    ]}
                  >
                    <Input placeholder="Undergraduate School (if this does not apply, please write None)" />
                  </Form.Item>

                  <Form.Item
                    name="graduate_school"
                    label="Graduate School"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input your graduate school, if this does not apply, please write None",
                      },
                    ]}
                  >
                    <Input placeholder="Graduate School (if this does not apply, please write None)" />
                  </Form.Item>

                  <Form.Item
                    name="highest_degree"
                    label="Highest Degree"
                    rules={[{ required: false }]}
                  >
                    <Input placeholder="Highest Degree" />
                  </Form.Item>
                </div>
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-5">
                  <Form.Item
                    name="race"
                    label="Race"
                    rules={[
                      { required: true, message: "Please select your race!" },
                    ]}
                  >
                    <Select placeholder="Select race" showSearch>
                      <Option value={""}>Select race</Option>
                      {[
                        "American Indian or Alaska Native",
                        "East Asian or East Asian American",
                        "Black, African American, Afro-Caribbean or Afro-Latino",
                        "Hispanic, Latino, Latina, or Latinx",
                        "Middle Eastern or Northern African",
                        "Native Hawaiian or Other Pacific Islander",
                        "White or Caucasian",
                        "South East Asian or South East Asian American",
                        "Native American",
                        "Multiracial/Multiethnic",
                        "Other",
                        "Prefer not to say",
                        "Not specified",
                        "",
                      ].map((race) => (
                        <Option key={race} value={race}>
                          {race}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="ethnicity"
                    label="Ethnicity"
                    rules={[{ required: false }]}
                  >
                    <Input placeholder="Ethnicity" />
                  </Form.Item>

                  <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[{ required: false }]}
                  >
                    <Select placeholder="Select gender" showSearch>
                      <Option value={""}>Select gender</Option>
                      {[
                        "Male",
                        "Female",
                        "Transgender Male/Trans Man",
                        "Transgender Female/Trans Woman",
                        "Non-Binary",
                        "Genderqueer",
                        "Gender non-confirming",
                        "Two-Spirit",
                        "Other",
                        "Prefer not to say",
                        "Not Specified",
                        "",
                      ].map((gender) => (
                        <Option key={gender} value={gender}>
                          {gender}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="grid lg:grid-cols-3 gap-x-5">
                  <Form.Item
                    name="disability_status"
                    label="Disability Status"
                    rules={[{ required: false }]}
                  >
                    <Select placeholder="Select">
                      <Option value={""}>Select disability status</Option>
                      {[
                        "I have a disability",
                        "I don't have a disability",
                        "I prefer not to say",
                      ].map((dis) => (
                        <Option key={dis} value={dis}>
                          {dis}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="sexual_orientation"
                    label="Sexual Orientation"
                    rules={[{ required: false }]}
                  >
                    <Select placeholder="Select Sexual Orientation" showSearch>
                      <Option value={""}>Select sexual orientation</Option>
                      {[
                        "Aromantic",
                        "Asexual",
                        "Bisexual",
                        "Fluid",
                        "Gay",
                        "Lesbian",
                        "Pansexual",
                        "Queer",
                        "Questioning or unsure",
                        "Straight (heterosexual)",
                        "Prefer not to disclose",
                      ].map((sex) => (
                        <Option key={sex} value={sex}>
                          {sex}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="us_first_gen"
                    // label="First Generation"
                    label={
                      <div className="flex gap-x-1 items-center">
                        <p>First Generation</p>
                        <Tooltip
                          title="First generation refers to individuals who are the first in their family to accomplish a specific milestone or status, typically in the context of immigration or education."
                          placement="right"
                        >
                          <IoIosInformationCircleOutline />
                        </Tooltip>
                      </div>
                    }
                    valuePropName="checked"
                  >
                    <Checkbox>First Generation</Checkbox>
                  </Form.Item>
                </div>

                <div className="grid lg:grid-cols-2 gap-x-5">
                  <Form.Item
                    name="nationality"
                    label="Nationality"
                    rules={[{ required: false }]}
                  >
                    <Select
                      placeholder="Select nationality"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        String(option?.children || "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option value={""}>Select Nationality</Option>
                      {countriesList.map((country: any, index: number) => (
                        <Option key={index} value={country?.name}>
                          {country?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="country"
                    label="Country"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select country"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        String(option?.children || "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      
                      onChange={(e) => {
                        setCountryid(e);
                        const country: any = countriesList.find(
                          (country: any) => country?.id === e
                        );
                        console.log("tell tale", country, e);
                        GetState(e).then((result: any) => {
                          setStateList(result);
                        });
                      }}
                    >
                      <Option value={""}>Select Country</Option>
                      {countriesList.map((country: any, index: number) => (
                        <Option key={index} value={country?.id}>
                          {country?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="state"
                    label="State"
                    rules={[{ required: false }]}
                  >
                    <Select
                      placeholder="Select state"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        String(option?.children || "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e) => {
                        setStateid(e);
                        console.log(e)
                        GetCity(countryid, e).then((result: any) => {
                          setCityList(result);
                        });
                      }}
                    >
                      <Option value={""}>Select state</Option>
                      {stateList.map((state: any, index: number) => (
                        <Option key={index} value={state?.id}>
                          {state?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name="city" label="City">
                    <Select
                      placeholder="Select city"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        String(option?.children || "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option value={""}>Select city</Option>
                      {cityList.map((city: any, index: number) => (
                        <Option key={index} value={city?.name}>
                          {city?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="grid lg:grid-cols-3 gap-x-5">
                  <Form.Item
                    name="has_kids"
                    label="Parental Status"
                    rules={[{ required: false }]}
                  >
                    <Select>
                      <Option value={""}>Select parental status</Option>
                      {[
                        "I have kids",
                        "I don't have kids",
                        "I prefer not to say",
                      ].map((par) => (
                        <Option key={par} value={par}>
                          {par}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="religion"
                    label="Religion"
                    rules={[{ required: false }]}
                  >
                    <Select placeholder="Select religion" showSearch>
                      <Option value={""}>Select religion</Option>
                      {[
                        "Christianity",
                        "Islam",
                        "Hinduism",
                        "Buddhism",
                        "Sikhism",
                        "Judaism",
                        "Prefer not to say",
                        "Not Specified",
                        "other",
                      ].map((rel) => (
                        <Option key={rel} value={rel}>
                          {rel}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="relationship_status"
                    label="Relationship Status"
                    rules={[{ required: false }]}
                  >
                    <Select>
                      <Option value={""}>Select relationship status</Option>
                      {["Single", "Partnered", "Married", "Divorced"].map(
                        (rel) => (
                          <Option key={rel} value={rel}>
                            {rel}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </div>

                <div className="grid lg:grid-cols-2 gap-x-5">
                  <Form.Item
                    name="hobbies"
                    // label="Hobbies (separated by comma)"
                    label={
                      <div className="flex gap-x-1 items-center">
                        <p>Hobbies (separated by comma)</p>
                      </div>
                    }
                    rules={[{ required: false }]}
                  >
                    <Input
                      placeholder="e.g Hiking, Cooking, Reading"
                      suffix={
                        <Tooltip
                          title="Hobbies are activities pursued for enjoyment and personal fulfillment during leisure time. They are done outside of professional or routine commitments and are typically chosen based on personal preference."
                          placement="right"
                        >
                          <IoIosInformationCircleOutline />
                        </Tooltip>
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="interests"
                    // label="Interests (separated by comma)"
                    label={
                      <div className="flex gap-x-1 items-center">
                        <p>Interests (separated by comma)</p>
                      </div>
                    }
                    rules={[{ required: false }]}
                  >
                    <Input
                      placeholder="e.g Sports, Music, Travel"
                      suffix={
                        <Tooltip
                          title="Interest refers to a strong curiosity or attraction toward a particular subject, activity, or field. It reflects a person's enthusiasm and engagement with specific topics or pursuits, often influencing their choices and decisions."
                          placement="right"
                        >
                          <IoIosInformationCircleOutline />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                </div>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={updateUserLoading}
                    disabled={updateUserLoading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DemographicsInfo;
