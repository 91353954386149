import React, { useState, FormEvent } from "react";
import { loadStripe, StripeCardElementChangeEvent } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { createUserAndSubscription } from "../../api";

import { PUBLISHABLE_KEY } from "../../constants/stripe";

import { Check } from "lucide-react";
import MostPopular from "../../assets/popular.png";
import { Link, useNavigate } from "react-router-dom";

import { RootState } from "../../lib/store";
import { useSelector } from "react-redux";

import { Form, Input, Select, Button } from "antd";
import logo from "../../assets/logo.png";
import { ArrowLeft } from "lucide-react";

// import {Button } from '../../components/shared/Button'

// Replace with your Stripe publishable key
const stripePromise = loadStripe(PUBLISHABLE_KEY);

interface BillingComponentProps {}

interface CustomerInfo {
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
}

const { Option } = Select;

const BillingComponent: React.FC<BillingComponentProps> = () => {
  const userId = useSelector((state: RootState) => state.user.id) || "";
  const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);
  const email = useSelector((state: RootState) => state.user.email);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    email: email || "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const [priceId, setPriceId] = useState<string>("");
  const [title, setTitle] = useState<string>("Annual Plan");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const scrollToBottom = React.useCallback(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, []);

  const handleContactUs = () => {
    navigate('/contact');
  };

  const handleGetStarted = (priceId: string) => {
    console.log("priceId = ", priceId);
    setPriceId(priceId);
    const price = pricing.filter((p) => p.priceId === priceId);
    console.log("price = ", price);
    setTitle(price[0].title);
    form.setFieldsValue({ priceId });
  };
  const handleCustomerInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomerInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new Error("Card Element not found");
      }

      // Create a token with additional billing details
      const result = await stripe.createToken(cardElement, {
        name: `${customerInfo.firstName} ${customerInfo.lastName}`,
        address_line1: customerInfo.address,
        address_city: customerInfo.city,
        address_state: customerInfo.state,
        address_zip: customerInfo.postalCode,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }

      // Send the token and customer info to your server
      const response = await createUserAndSubscription(
        userId,
        title,
        result.token.id,
        priceId,
        customerInfo
      );

      // Handle the response
      if (response.customerId && response.subscriptionId) {
        setSuccess(true);
      }
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [priceId, scrollToBottom]);

  if (success) {
    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <p className="text-4xl font-bold">Subscription created successfully!</p>
      </div>
    );
  }

  interface PricingFeature {
    text: string;
  }

  interface PricingCardProps {
    title: string;
    price: string;
    period: string;
    subtitle: string;
    description?: string;
    features: PricingFeature[];
    isPopular?: boolean;
    priceId: string;
  }

  // const priceFilterId = (pId: string) => pricing.find(p => pId === p.priceId)?.price.split('/')[0];
  const priceFilter = (pId: string) => pricing.find((p) => pId === p.priceId);

  const pricing: PricingCardProps[] = [
    {
      title: "Annual Plan",
      price: "$599.88/year",
      period: "Billed annually",
      subtitle: "Annual Plan",
      features: [
        { text: "Unlimited funder matches" },
        { text: "Full access to contact details for each matched funder" },
        { text: "Unlimited AI fundraising co-pilot" },
        { text: "Fundraising analytics and tracking" },
        { text: "AI-powered pitch deck review" },
        { text: "Alerts for funders and relevant events" },
        {
          text: "Free 7-day trial (up to 5 AI funder matches + limited pitch deck review)",
        },
      ],
      // isPopular: true,
      priceId: "price_1QU9CoCHwtU8rPsFIw4AraxZ",
    },
    {
      title: "Monthly Plan",
      price: "$99/month",
      period: "Billed monthly",
      subtitle: "Monthly Plan",
      features: [
        { text: "25 AI-based investor matches monthly" },
        { text: "Full access to contact details for each matched funder" },
        { text: "Unlimited AI fundraising co-pilot" },
        { text: "Fundraising analytics and tracking" },
        { text: "AI-powered pitch deck review" },
        { text: "Alerts for funders and relevant events" },
        {
          text: "Free 7-day trial (up to 5 AI funder matches + limited pitch deck review)",
        },
      ],
      priceId: "price_1QU9C5CHwtU8rPsFtR2vJLUo",
    },
    {
      title: "Enterprise plan",
      price: "Custom Pricing",
      period: "Enterprise plan",
      subtitle: "For scale-ups, accelerators, investors, and vendors:",
      features: [
        { text: "Unlimited funder matches" },
        { text: "Team access with discounted plans" },
        { text: "Full access to contact details for each matched funder" },
        { text: "Fundraising analytics and tracking" },
        { text: "Custom AI-powered fundraising solutions" },
        { text: "AI-powered pitch deck review" },
        { text: "Personalized support" },
      ],
      priceId: "price_1QA179P1LyiQAS8LSPBYStaj",
    },
  ];

  return (
    <div ref={scrollableDivRef} className="h-screen overflow-y-scroll">
      {priceId === "" ? (
        <section className="xl:py-[150px] py-16 max-w-[1440px] mx-auto px-4">
          <div className="xl:block flex flex-col items-center justify-center ">
            <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5 ">
              Pricing
            </span>
            <h3 className="text-4xl font-semibold mt-3 xl:text-left text-center">
              Start Your Free Trial Today!
            </h3>
            <p className="text-grey600 text-xl font-regular mt-5 text-center xl:text-left">
              Sign up now and save 50% with our annual plan, No credit card
              required!
            </p>
          </div>

          <div className="mt-24 max-w-[1440px] mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-16">
              {pricing.map(
                (
                  {
                    isPopular,
                    price,
                    period,
                    title,
                    subtitle,
                    description,
                    features,
                    priceId,
                  }: PricingCardProps,
                  index
                ) => {
                  return (
                    <div className="relative flex flex-col p-8 bg-white rounded-2xl shadow-lg shadow-grey200 border border-gray-100 hover:border-blue-500 transition-all duration-300">
                      {isPopular && (
                        <div className="absolute -top-8 xl:-right-16 right-0 ">
                          <img src={MostPopular} className="h-[50px]" alt="" />
                        </div>
                      )}

                      <div className="mb-6 text-center xl:text-left">
                        <h3 className="text-3xl font-bold mb-1 ">{price}</h3>
                        <p className="text-gray-600 font-medium">{period}</p>
                      </div>

                      <h4 className="text-xl font-semibold mb-1 text-center xl:text-left">
                        {title}
                      </h4>
                      <p className="text-sm text-gray-600 mb-6 text-center xl:text-left">
                        {subtitle}
                      </p>

                      {description && (
                        <p className="text-sm text-gray-600 mb-6">
                          {description}
                        </p>
                      )}

                      <div className="flex-grow">
                        <ul className="space-y-4">
                          {features.map((feature, index) => (
                            <li key={index} className="flex items-start gap-3">
                              <div className="bg-green-100 rounded-full p-0.5 flex items-center justify-center">
                                <Check className="w-3 h-3 text-green-500 flex-shrink-0 mt-0.5" />
                              </div>
                              <span className="text-gray-600 text-sm">
                                {feature.text}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* <Link to={'/login'} className="mt-8 w-full bg-primary600 hover:bg-primary text-white py-3 px-6 rounded-lg font-medium transition-colors duration-200 text-center">
                                        Get started
                                    </Link> */}
                      {title === 'Enterprise plan'
                      ? (
                        <button
                        onClick={() => handleContactUs()}
                        className="mt-8 w-full bg-primary600 hover:bg-primary text-white py-3 px-6 rounded-lg font-medium transition-colors duration-200 text-center"
                      >
                        Contact Us
                      </button>
                      )
                      : (
                        <button
                        onClick={() => handleGetStarted(priceId)}
                        className="mt-8 w-full bg-primary600 hover:bg-primary text-white py-3 px-6 rounded-lg font-medium transition-colors duration-200 text-center"
                      >
                        Subscribe
                      </button>
                      )}

                      {/* <Button
                                                onClick={() => handleGetStarted("hello")}
                                                className='mt-8 w-full bg-primary600 hover:bg-primary text-white py-3 px-6 rounded-lg font-medium transition-colors duration-200 text-center'
                                                customData="Hello, World!"
                                          >
                                                Get started
                                            </Button> */}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </section>
      ) : (
        <div style={{ display: "flex" }}>
          <div
            style={{
              alignItems: "flex-start",
              display: "flex",
              flex: "1",
              justifyContent: "center",
              boxShadow: ".5px 0 0 #E9EAEB",
              padding: "0px 24px",
            }}
          >
            <div className="w-full xl:py-[150px] py-16 max-w-[591.5px] mx-auto px-4">
              <div
                className="flex md:flex-row flex-col gap-x-2 items-end w-full mb-10"
                onClick={() => setPriceId("")}
              >
                <ArrowLeft className="h-8 w-8 mr-2 mt-1 flex-shrink-0 text-accent-foreground/100" />
                <img src={logo} alt={"Founders Tribes Logo"} className="h-8" />
              </div>
              <div className="text-xl font-medium">
                Subscribe to MyCoFoundr.AI For {priceFilter(priceId)?.title}
              </div>
              <div className="flex md:flex-row flex-col gap-x-2 items-end w-full">
                <div className="text-4xl font-bold">
                  {priceFilter(priceId)?.price.split("/")[0]}
                </div>
                <div className="text-sm mt-5">
                  Per <br /> month
                </div>
              </div>
              <hr className="h-0.5 mx-auto my-2 bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700" />
              <div className="flex md:flex-row flex-col gap-x-2 items-end w-full">
                <div className="text-xl font-medium w-full">
                  MyCoFoundr.AI {priceFilter(priceId)?.title}
                </div>
                <div className="text-xl text-right w-full">
                  {priceFilter(priceId)?.price.split("/")[0]}
                </div>
              </div>
              <div className="text-xl font-light mt-5">
                {priceFilter(priceId)?.period}
              </div>
              <hr className="h-0.5 mx-auto my-2 bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700" />
              <div className="flex md:flex-row flex-col gap-x-2 items-end w-full">
                <div className="text-xl font-medium w-full">Subtotal</div>
                <div className="text-xl text-right w-full">
                  {priceFilter(priceId)?.price.split("/")[0]}
                </div>
              </div>
              <div className="flex md:flex-row flex-col gap-x-2 items-end w-full">
                <div className="text-xl font-light w-full mt-5">Tax</div>
                <div className="text-xl font-light text-right w-full">
                  $0.00
                </div>
              </div>
              <hr className="h-0.5 mx-auto my-2 bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700" />
              <div className="flex md:flex-row flex-col gap-x-2 items-end w-full">
                <div className="text-xl font-medium w-full">
                  Total Due Today
                </div>
                <div className="text-xl text-right w-full">
                  {priceFilter(priceId)?.price.split("/")[0]}
                </div>
              </div>
            </div>
            {/* <div style={{ padding: '64px', width: '591.5px', height: '499px' }}>
          <div style={{ fontSize: '15px' }}>Subscribe to MyCoFoundr.AI For Annual Plan</div>
          <div style={{ display: 'flex', height: '116px' }}>
            <div style={{ fontSize: '48px', fontWeight: '600' }}>{priceFilter(priceId)}</div>
            <div style={{ fontSize: '15px', width: '61px', height: '48px', padding: '15px 0px 0px 15px' }}>Per Month</div>
          </div>
          <div style={{ display: 'flex', width: '488px', height: '1px', backgroundColor: '#E9EAEB', margin: '5px 0px 5px 0px' }}></div>
          <div style={{ display: 'flex', height: '30px' }}>
            <div style={{ fontSize: '15px', fontWeight: '500', width: '252.7px' }}>MyCoFoundr.AI Annual Subscription</div>
            <div style={{ fontSize: '15px', height: '48px', width: '210px', textAlign: 'right' }}>{priceFilter(priceId)}</div>
          </div>
          <div style={{ fontSize: '15px', height: '30px' }}>Billed Monthly.</div>
          <div style={{ display: 'flex', width: '488px', height: '1px', backgroundColor: '#E9EAEB', margin: '5px 0px 5px 0px' }}></div>
          <div style={{ display: 'flex', height: '30px' }}>
            <div style={{ fontSize: '15px', fontWeight: '500', width: '252.7px' }}>Subtotal</div>
            <div style={{ fontSize: '15px', height: '48px', width: '210px', textAlign: 'right' }}>{priceFilter(priceId)}</div>
          </div>
          <div style={{ display: 'flex', height: '30px' }}>
            <div style={{ fontSize: '15px', width: '252.7px' }}>Tax</div>
            <div style={{ fontSize: '15px', height: '48px', width: '210px', textAlign: 'right' }}>$0.00</div>
          </div>
          <div style={{ display: 'flex', width: '488px', height: '1px', backgroundColor: '#E9EAEB', margin: '5px 0px 5px 0px' }}></div>
          <div style={{ display: 'flex', height: '30px' }}>
            <div style={{ fontSize: '15px', fontWeight: '500', width: '252.7px' }}>Total Due Today</div>
            <div style={{ fontSize: '15px', height: '48px', width: '210px', textAlign: 'right' }}>{priceFilter(priceId)}</div>
          </div>
        </div> */}
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flex: "1",
              justifyContent: "center",
              boxShadow: "-.5px 0 0 #E9EAEB",
              padding: "24px",
            }}
          >
            {/* <section className='xl:py-[150px] py-16 max-w-[591.5px] mx-auto px-4'> */}
            {/* <form onSubmit={handleSubmit}> */}
            <Form
              layout="vertical"
              className="w-full xl:py-[150px] py-16 max-w-[591.5px] mx-auto px-4"
              onFinish={handleSubmit}
              initialValues={{
                email: customerInfo.email,
                priceId: "price_1QA11aP1LyiQAS8Lo2Bn3QHQ",
              }}
              form={form}
            >
              <div className="flex md:flex-row flex-col gap-x-2 items-end w-full">
                <Form.Item name="email" className="w-full" label="Email">
                  <Input disabled={true} placeholder="email" />
                </Form.Item>
                <Form.Item
                  name="priceId"
                  className="w-full"
                  label="Selected Plan"
                  rules={[
                    {
                      required: true,
                      message: "Please choose a plane",
                    },
                  ]}
                >
                  <Select
                    // defaultValue="price_1QU9CoCHwtU8rPsFIw4AraxZ"
                    className="w-full"
                    onChange={handleGetStarted}
                    value={priceId}
                    options={[
                      {
                        value: "price_1QU9CoCHwtU8rPsFIw4AraxZ",
                        label: "Annual Plan",
                      },
                      {
                        value: "price_1QU9C5CHwtU8rPsFtR2vJLUo",
                        label: "Monthly Plan",
                      },
                    ]}
                  />
                  {/* <Select placeholder="Select plan"
                 showSearch
                 className="w-full"
                 onChange={handleGetStarted}
                 value='price_1QA11aP1LyiQAS8Lo2Bn3QHQ'
                >
                  {[
                    { value: 'price_1QA11aP1LyiQAS8Lo2Bn3QHQ', label: 'Annual Plan' },
                    { value: 'price_1QA15TP1LyiQAS8LMH7xBC8e', label: 'Monthly Plan' },
                    { value: 'price_1QA179P1LyiQAS8LSPBYStaj', label: 'Enterprise plan' }
                  ].map(({ value, label}) => (
                    <Option key={label} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select> */}
                </Form.Item>
              </div>

              <div style={{ fontSize: "20px", fontWeight: "500" }}>
                Card Holder Information
              </div>

              <div className="flex md:flex-row flex-col gap-x-2 items-end w-full">
                <Form.Item
                  name="firstName"
                  className="w-full"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name",
                    },
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  className="w-full"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </div>

              {/* <label className='ant-form-item-required' title='Card Number'>Card Number</label>
            <div style={{ height: '8px'}}></div>
            <div style={{ borderColor: '#CCCCCC', borderWidth: 'thin',
                boxSizing: "border-box",
                margin: "0",
                padding: "4px 11px",
                color: "rgba(0, 0, 0, 0.88)",
                fontSize: "14px",
                lineHeight: "1.5714285714285714",
                listStyle: "none",
                fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji",
                position: "relative",
                display: "inline-block",
                width: "100%",
                minWidth: "0",
                borderRadius: "6px",
                transition: "all 0.2s",
              
            }}>
              <CardNumberElement
                options={{
                  placeholder: '0000 0000 0000 0000',
                }}/>
            </div> */}

              <Form.Item
                name="creditCardNumber"
                className="w-full"
                label="Card details (number, expiration date, CVC, Zip):"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Credit Card details",
                    validator: (_, value) => Promise.resolve(),
                  },
                ]}
              >
                <div
                  style={{
                    borderColor: "#CCCCCC",
                    borderWidth: "thin",
                    boxSizing: "border-box",
                    margin: "0",
                    padding: "4px 11px",
                    color: "rgba(0, 0, 0, 0.88)",
                    fontSize: "14px",
                    lineHeight: "1.5714285714285714",
                    listStyle: "none",
                    fontFamily:
                      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji",
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                    minWidth: "0",
                    borderRadius: "6px",
                    transition: "all 0.2s",
                  }}
                >
                  {/* <CardNumberElement
                options={{
                  placeholder: '0000 0000 0000 0000',
                }}/> */}
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: "15px",
                          color: "#424770",
                          "::placeholder": {
                            color: "#cccccc",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                    onChange={(event: StripeCardElementChangeEvent) => {
                      if (event.error) {
                        setError(event.error.message);
                      } else {
                        setError(null);
                      }
                    }}
                  />
                </div>
              </Form.Item>
              {/* </div> */}

              <div style={{ fontSize: "20px", fontWeight: "500" }}>
                Billing Information
              </div>

              <Form.Item
                name="address"
                className="w-full"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter Billing Address",
                  },
                ]}
              >
                <Input placeholder="Billing Address" />
              </Form.Item>

              <div className="flex md:flex-row flex-col gap-x-2 items-end w-full">
                <Form.Item
                  name="city"
                  className="w-full"
                  label="City"
                  rules={[
                    {
                      required: true,
                      message: "Please enter city",
                    },
                  ]}
                >
                  <Input placeholder="City" />
                </Form.Item>
                <Form.Item
                  name="state"
                  className="w-full"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: "Please enter State",
                    },
                  ]}
                >
                  <Input placeholder="State" />
                </Form.Item>
              </div>

              <Form.Item
                name="postalCode"
                className="w-full"
                label="Zip"
                rules={[
                  {
                    required: true,
                    message: "Please enter Zip Code",
                  },
                ]}
              >
                <Input placeholder="Zip Code" />
              </Form.Item>

              {error && <div style={{ color: "red" }}>{error}</div>}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={!stripe || loading}
                  className="w-full"
                >
                  {loading ? "Processing..." : "Subscribe"}
                </Button>
              </Form.Item>
              {/* 
            <div>
              <label>
                Card number:
                <CardElement 
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                  onChange={(event: StripeCardElementChangeEvent) => {
                    if (event.error) {
                      setError(event.error.message);
                    } else {
                      setError(null);
                    }
                  }}
                />
              </label>
            </div> */}

              {/* <div>
              <label>
                Email: {customerInfo.email}
              </label>
            </div> */}
              {/* <div>
              <label>
                First Name:
                <input
                  type="text"
                  name="firstName"
                  value={customerInfo.firstName}
                  onChange={handleCustomerInfoChange}
                  required
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #2d9fd9',
                    color: '#a0d18c',
                    width: '250px',
                    height: '30px',
                    paddingLeft: '10px'
                  }}
                />
              </label>
            </div>
            <div>
              <label>
                Last Name:
                <input
                  type="text"
                  name="lastName"
                  value={customerInfo.lastName}
                  onChange={handleCustomerInfoChange}
                  required
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #2d9fd9',
                    color: '#a0d18c',
                    width: '250px',
                    height: '30px',
                    paddingLeft: '10px'
                  }}
                />
              </label>
            </div> */}
              {/* <div>
              <label>
                Billing Address:
                <input
                  type="text"
                  name="address"
                  value={customerInfo.address}
                  onChange={handleCustomerInfoChange}
                  required
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #2d9fd9',
                    color: '#a0d18c',
                    width: '250px',
                    height: '30px',
                    paddingLeft: '10px'
                  }}
                />
              </label>
            </div>
            <div>
              <label>
                City:
                <input
                  type="text"
                  name="city"
                  value={customerInfo.city}
                  onChange={handleCustomerInfoChange}
                  required
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #2d9fd9',
                    color: '#a0d18c',
                    width: '250px',
                    height: '30px',
                    paddingLeft: '10px'
                  }}
                />
              </label>
            </div>
            <div>
              <label>
                State:
                <input
                  type="text"
                  name="state"
                  value={customerInfo.state}
                  onChange={handleCustomerInfoChange}
                  required
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #2d9fd9',
                    color: '#a0d18c',
                    width: '250px',
                    height: '30px',
                    paddingLeft: '10px'
                  }}
                />
              </label>
            </div>
            <div>
              <label>
                Postal Code:
                <input
                  type="text"
                  name="postalCode"
                  value={customerInfo.postalCode}
                  onChange={handleCustomerInfoChange}
                  required
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #2d9fd9',
                    color: '#a0d18c',
                    width: '250px',
                    height: '30px',
                    paddingLeft: '10px'
                  }}
                />
              </label>
            </div> */}
              {/* <div>
              <label>
                Price title: {title}
              </label>
            </div>
            <div>
              <label>
                Price ID: {priceId}
              </label>
            </div> */}
              {/* <div>
              <label>
                Card details (number, expiration date, CVC):
                <CardElement 
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                  onChange={(event: StripeCardElementChangeEvent) => {
                    if (event.error) {
                      setError(event.error.message);
                    } else {
                      setError(null);
                    }
                  }}
                />
              </label>
            </div> */}
              {/* {error && <div style={{ color: 'red' }}>{error}</div>}
            <button type="submit" disabled={!stripe || loading}>
              {loading ? 'Processing...' : 'Subscribe'}
            </button> */}
              {/* </form> */}
            </Form>
            {/* </section> */}
          </div>
        </div>
      )}
    </div>
  );
};

const Billing: React.FC = () => (
  <Elements stripe={stripePromise}>
    <BillingComponent />
  </Elements>
);

export default Billing;
