import React, { FC, useCallback } from "react";
import { SideMenuItem } from "./components/SideMenuItem";
import { SessionItem } from "./components/SessionItem";
import { Link, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { garySvg } from "../../api/index";
import { manuelaSvg } from "../../api/index";
import { valentinaSvg } from "../../api/index";
import { chatPersonas } from "../../@types/types";
import recommendationsDisabled from "../../assets/svg/recommendation-inactive.svg";
import dashboardInactive from "../../assets/svg/dashboard-inactive.svg";
import profileInactive from "../../assets/svg/profile-inactive.svg";
import aiWhite from "../../assets/svg/ai-white.svg";
import savedConversationInactive from "../../assets/svg/saved-inactive.svg";
import logo from "../../assets/logo.png";
import singleLogo from "../../assets/singleLogo.png";
import { RootState } from "../../lib/store";
import { unSetProfile } from "../../lib/features/user/userSlice";
import { unauthenticate } from "../../lib/features/auth/authSlice";
import { unSetStartUpData } from "../../lib/features/startup/startupSlice";
import { useGetConversationsQuery } from "../../lib/features/conversation/conversationApiSlice";
import { useSidebar } from "../../hooks/use-sidebar";
import { getUserInitials } from "../../utils/utils";
import { Input } from "antd";
import { ChevronLeftIcon, ChevronRightIcon, Search } from "lucide-react";
import {
  FiBarChart2,
  FiUsers,
  FiCheckSquare,
  FiSave,
  FiSettings,
  FiMessageSquare,
  FiChevronUp,
} from "react-icons/fi";
import { useGetProfileImageQuery } from "../../lib/features/user/userApiSlice";
import { FiLogOut } from "react-icons/fi";
import { FiMenu } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import { IconWhite } from "../../assets";
import { useGetAllChatsQuery } from "../../lib/features/deepdive/deepDiveApiSlice";

interface IProps {}

const ChatPersonaAvatars = {
  Gary: garySvg,
  Manuela: manuelaSvg,
  Valentina: valentinaSvg,
};

export const Navbar: FC<IProps> = () => {
  const { toggleSidebar, isSidebarOpen, isLoading } = useSidebar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [chatWindow, setChatWindow] = React.useState(0);
  const [deepDiveChatWindow, setDeepDiveChatWindow] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  // const conversations = useSelector((state: RootState) => state.conversation?.conversation)
  const userId = useSelector((state: RootState) => state.user.id);

  const { data: conversations } = useGetConversationsQuery(userId);
  const { data: deepDiveConversations } = useGetAllChatsQuery(userId);
  const user = useSelector((state: RootState) => state.user, shallowEqual);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signOut = () => {
    handleClose();
    dispatch(unauthenticate());
    dispatch(unSetProfile());
    dispatch(unSetStartUpData());
    navigate("/");
  };

  const profileClick = () => {
    navigate("/userSetting");
    handleClose();
  };

  const menuItems = [
    {
      id: 1,
      label: "Dashboard",
      icon: FiBarChart2,
      disableIcon: dashboardInactive,
      path: "/home",
    },
    {
      id: 2,
      label: "Profile",
      icon: FiUsers,
      disableIcon: profileInactive,
      path: "/profile",
      // path: user?.uploaded_pitch_deck ? '/complete-profile' : '/profile'
    },

    {
      id: 3,
      label: "Recommendations",
      icon: FiCheckSquare,
      disableIcon: recommendationsDisabled,
      path: "/recommendation",
    },

    {
      id: 4,
      label: "Settings",
      icon: FiSettings,
      disableIcon: savedConversationInactive,
      path: "/settings",
    },
  ];

  const generateSessionItems = useCallback(() => {
    if (!userId) return;

    if (conversations) {
      return conversations?.map((item: any, index: number) => {
        return (
          <SessionItem
            key={index}
            username={item.chatPersona}
            comment={item.title}
            avatar={ChatPersonaAvatars[item.chatPersona as chatPersonas]}
            onClick={() => {
              try {
                navigate(`/answer/${item.id}`);
                toggleSidebar();
              } catch (e: any) {}
            }}
          />
        );
      });
    } else return <></>;
  }, [conversations, navigate, toggleSidebar, userId]);

  const generateDeepDiveSessionItems = useCallback(() => {
    if (!userId) return;

    if (deepDiveConversations) {
      return deepDiveConversations?.map((item: any, index: number) => {
        return (
          <SessionItem
            key={index}
            username={item.chatPersona}
            comment={item.title}
            onClick={() => {
              try {
                navigate(`/ai-feedback/${item.id}`);
                // toggleSidebar();
              } catch (e: any) {}
            }}
          />
        );
      });
    } else return <></>;
  }, [deepDiveConversations, navigate, userId]);

  const { data: getProfileImage } = useGetProfileImageQuery({
    userId: user?.id,
  });

  return (
    <>
      <div
        className={`hidden fixed z-[10] lg:flex flex-col h-screen justify-between peer ${
          isSidebarOpen ? "lg:w-[320px]" : "w-[90px]"
        } transition-all`}
        data-state={isSidebarOpen && !isLoading ? "open" : "closed"}
      >
        <div className="flex justify-between items-center">
          <div className="p-5">
            {isSidebarOpen ? (
              <>
                <img src={logo} alt={"Founders Tribes Logo"} className="h-8" />
              </>
            ) : (
              <img
                src={singleLogo}
                alt={"Founders Tribes Logo"}
                className="h-8"
              />
            )}
          </div>
        </div>

        <button
          className={`${
            isSidebarOpen
              ? "bg-primary700 border-white"
              : "bg-white border-primary700"
          } rounded-full border absolute -right-4 shadow-md flex items-center justify-center top-[2%] z-[99] size-8`}
          onClick={() => {
            toggleSidebar();
          }}
        >
          {isSidebarOpen ? (
            <ChevronLeftIcon className="text-white p-1" />
          ) : (
            <ChevronRightIcon className="text-primary p-1" />
          )}
        </button>

        <div
          className={`flex flex-col px-5 pt-8 rounded-t-2xl  h-full bg-sidebar`}
        >
          {/*NAVIGATION*/}
          <>
            {isSidebarOpen && <h4 className="text-sm text-white">Overview</h4>}
            <div className="mt-2 ">
              {menuItems.map((item) => {
                return (
                  <SideMenuItem
                    key={item.id}
                    disabledIcon={item.disableIcon}
                    isDisabled={false}
                    label={item.label}
                    Icon={item.icon}
                    path={item.path}
                    isAuthenticated={isAuthenticated}
                    isSidebarOpen={isSidebarOpen}
                    onClick={() => {
                      navigate(item.path);
                      if (window.innerWidth < 768) {
                        toggleSidebar();
                      }
                    }}
                  />
                );
              })}
            </div>

            {deepDiveConversations?.length > 0 && (
              <div className="mt-2">
                <button
                  className={`py-2 px-3 text-sm text-[#495057] flex items-center justify-between gap-x-2.5 ${
                    chatWindow === 2 && "bg-primary"
                  } w-full rounded-lg scroll`}
                  onClick={() => {
                    if (!isSidebarOpen) toggleSidebar();
                    setChatWindow(chatWindow === 2 ? 0 : 2);
                  }}
                >
                  <div className="flex items-center gap-x-3">
                    <div className="size-6">
                      <img
                        src={IconWhite}
                        alt={"IconWhite"}
                        className="size-full"
                      />
                    </div>
                    {/* <FiMessageSquare className="size-6" /> */}
                    {isSidebarOpen && (
                      <p className="text-md text-white font-semibold">
                        Personalized Feedback
                      </p>
                    )}
                  </div>
                  {isSidebarOpen && (
                    <FiChevronUp
                      className={`size-5 text-white ${
                        chatWindow === 2 ? "" : "rotate-180"
                      } transition-all`}
                    />
                  )}
                </button>
                <div
                  className={`overflow-y-auto max-h-[40dvh] pl-4 border-[#D9D9D9] mt-1 ${
                    chatWindow === 2 ? "h-full" : "h-0"
                  } transition-all`}
                >
                  {isSidebarOpen && generateDeepDiveSessionItems()}
                </div>
              </div>
            )}
          </>

          {/*SESSIONS*/}
          {isAuthenticated ? (
            <div className="flex flex-col mt-8">
              {isSidebarOpen && (
                <h4 className="text-sm text-white">Recent Conversations</h4>
              )}

              {conversations?.length > 0 && (
                <div className="mt-1.5">
                  <button
                    className={`py-2 px-3 text-sm text-[#495057] flex items-center justify-between gap-x-2.5 bg- w-full rounded-lg ${
                      chatWindow === 1 && "bg-primary"
                    }`}
                    onClick={() => {
                      if (!isSidebarOpen) toggleSidebar();
                      setChatWindow(chatWindow === 1 ? 0 : 1);
                    }}
                  >
                    <div className="flex items-center gap-x-3">
                      <div className="size-6">
                        <img
                          src={IconWhite}
                          alt={"IconWhite"}
                          className="size-full"
                        />
                      </div>
                      {/* <FiMessageSquare className="size-6" /> */}
                      {isSidebarOpen && (
                        <p className="text-md text-white font-semibold">
                          Anything Else
                        </p>
                      )}
                    </div>
                    {isSidebarOpen && (
                      <FiChevronUp
                        className={`size-5 text-white ${
                          chatWindow === 1 ? "" : "rotate-180"
                        } transition-all`}
                      />
                    )}
                  </button>
                  <div
                    className={`overflow-y-auto max-h-[40dvh] pl-4 border-[#D9D9D9] mt-1 ${
                      chatWindow === 1 ? "h-full" : "h-0"
                    } transition-all `}
                  >
                    {isSidebarOpen && generateSessionItems()}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        {/*LOGGED USER*/}
        {isAuthenticated && (
          <div className="">
            <div className=" flex items-start justify-between gap-2.5 p-4 text-sm lg:border-t bg-sidebar lg:border-t-[#DEE2E6]  cursor-pointer">
              {isSidebarOpen && (
                <div className="flex items-center gap-x-2">
                  <div>
                    {getProfileImage?.url ? (
                      <img
                        src={getProfileImage.url}
                        alt={"avatar"}
                        className="size-10 rounded-full"
                      />
                    ) : (
                      <div className="flex size-8 shrink-0 select-none items-center justify-center rounded-full bg-white text-xs font-medium uppercase text-primary700">
                        {getUserInitials(user.first_name!!, user.last_name!!)}
                      </div>
                    )}
                  </div>
                  <div>
                    <h6 className="text-sm font-semibold text-white">
                      {user?.first_name}
                    </h6>
                    <p className="text-white">
                      {(user?.email?.length!! ?? "") > 18
                        ? user?.email?.substring(0, 18) + "..."
                        : user?.email}
                    </p>
                  </div>
                </div>
              )}

              <button
                onClick={() => {
                  signOut();
                }}
              >
                <FiLogOut
                  className={`size-5 ${
                    isSidebarOpen ? "text-white" : "text-white"
                  }`}
                />
              </button>
            </div>
          </div>
        )}
      </div>
      {/* mobile sidebar */}
      <div className="block lg:hidden">
        <div className="p-4 w-full flex items-center justify-between">
          <img src={logo} alt={"Founders Tribes Logo"} className="h-8" />
          <div className="cursor-pointer" onClick={toggleSidebar}>
            {isSidebarOpen ? <FaTimes /> : <FiMenu />}
          </div>
        </div>
        {isSidebarOpen && (
          <div className="h-full absolute top-[6%] left-0 w-full flex flex-col justify-between bg-sidebar z-[99] pb-20">
            <div>
              <div className="m-4">
                <h4 className="text-sm text-grey400">Overview</h4>
                <div className="mt-2 ">
                  {menuItems.map((item) => {
                    return (
                      <SideMenuItem
                        key={item.id}
                        disabledIcon={item.disableIcon}
                        isDisabled={false}
                        label={item.label}
                        Icon={item.icon}
                        path={item.path}
                        isAuthenticated={isAuthenticated}
                        isSidebarOpen={isSidebarOpen}
                        onClick={() => {
                          navigate(item.path);
                          if (window.innerWidth < 768) {
                            toggleSidebar();
                          }
                        }}
                      />
                    );
                  })}
                </div>
                {deepDiveConversations?.length > 0 && (
                  <div className="mt-2">
                    <button
                      className="py-2 px-3 text-sm text-[#495057] flex items-center justify-between gap-x-2.5 bg-grey w-full rounded-lg"
                      onClick={() => setChatWindow(chatWindow === 2 ? 0 : 2)}
                    >
                      <div className="flex items-center gap-x-3">
                        <div className="size-6">
                          <img
                            src={IconWhite}
                            alt={"IconWhite"}
                            className="size-full"
                          />
                        </div>
                        {/* <FiMessageSquare className="size-6" /> */}
                        {isSidebarOpen && (
                          <p className="text-md text-white font-semibold">
                            Personalized Feedback
                          </p>
                        )}
                      </div>
                      {isSidebarOpen && (
                        <FiChevronUp
                          className={`size-5 text-white ${
                            chatWindow === 2 ? "" : "rotate-180"
                          } transition-all`}
                        />
                      )}
                    </button>
                    <div
                      className={`overflow-y-auto max-h-[40dvh] pl-4 border-[#D9D9D9] mt-1 ${
                        chatWindow === 2 ? "h-full" : "h-0"
                      } transition-all`}
                    >
                      {isSidebarOpen && generateDeepDiveSessionItems()}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col my-3 mx-4"></div>

              <div className="flex flex-col m-4">
                {isSidebarOpen && (
                  <h4 className="text-sm text-grey400">Recent Conversations</h4>
                )}
                {conversations?.length > 0 && (
                  <div className="mt-1.5">
                    <button
                      className="py-2 px-3 text-sm text-[#495057] flex items-center justify-between gap-x-2.5 bg-primary w-full rounded-lg"
                      onClick={() => setChatWindow(chatWindow === 1 ? 0 : 1)}
                    >
                      <div className="flex items-center gap-x-3">
                        <div className="size-6">
                          <img
                            src={IconWhite}
                            alt={"IconWhite"}
                            className="size-full"
                          />
                        </div>
                        {/* <FiMessageSquare className="size-6" /> */}
                        {isSidebarOpen && (
                          <p className="text-md text-white font-semibold">
                            Anything Else
                          </p>
                        )}
                      </div>
                      {isSidebarOpen && (
                        <FiChevronUp
                          className={`size-5 text-white ${
                            chatWindow === 1 ? "" : "rotate-180"
                          } transition-all`}
                        />
                      )}
                    </button>
                    <div
                      className={`overflow-y-auto max-h-[40dvh] pl-4 border-[#D9D9D9] mt-1 ${
                        chatWindow === 1 ? "h-full" : "h-0"
                      } transition-all`}
                    >
                      {isSidebarOpen && generateSessionItems()}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <div className=" flex items-start justify-between gap-2.5 p-4 text-sm lg:border-t lg:border-t-[#DEE2E6]  cursor-pointer">
                {isSidebarOpen && (
                  <div className="flex items-center gap-x-2">
                    <div>
                      {getProfileImage?.url ? (
                        <img
                          src={getProfileImage.url}
                          alt={"avatar"}
                          className="size-10 rounded-full"
                        />
                      ) : (
                        <div className="flex size-8 shrink-0 select-none items-center justify-center rounded-full bg-primary text-xs font-medium uppercase text-white">
                          {getUserInitials(user.first_name!!, user.last_name!!)}
                        </div>
                      )}
                    </div>
                    <div>
                      <h6 className="text-sm font-semibold text-white">
                        {user?.first_name}
                      </h6>
                      <p className="text-white">
                        {(user?.email?.length!! ?? "") > 18
                          ? user?.email?.substring(0, 18) + "..."
                          : user?.email}
                      </p>
                    </div>
                  </div>
                )}

                <button
                  onClick={() => {
                    signOut();
                  }}
                >
                  <FiLogOut
                    className={`size-5 ${
                      isSidebarOpen ? "text-white" : "text-white"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
