import React, { useState } from "react";
import { useAppSelector } from "../../../lib/hooks";
import { RootState } from "../../../lib/store";
import { useGetFunnelInvestorsQuery } from "../../../lib/features/recommendations/recommendations";
import { dummyReason } from "./Overview";
import Kofi from "../../../assets/KofiAmpadu.jpg";
import Shashi from "../../../assets/ShashiSrikantan.jpg";
import Fabian from "../../../assets/Fabian.png";
import { InvestorCard } from "../../Recommendation/components/InvestorCard";
import { defaultProfile } from "../../../constants/defaults";
import Loading from "../../../components/Loading";

function Matches() {
  const user = useAppSelector((state: RootState) => state.user);
  const [recommendationCard, setRecommendationCard] = useState<any>(null);

  const { data: vcRecommendations, isLoading } = useGetFunnelInvestorsQuery(
    user?.id,
    { refetchOnMountOrArgChange: true }
  );

  const shortlist = vcRecommendations?.shortlist || [];

  console.log(vcRecommendations);

  if (isLoading) return <Loading />;
  return (
    <div>
      <h1 className="text-xl font-semibold mt-6">Matches</h1>
      {vcRecommendations?.ai_matches.length > 0 ? (
        <div className="grid 2xl:grid-cols-3 xl:grid-cols-2 ">
          {[...vcRecommendations?.ai_matches]
            .sort((a: any, b: any) => b.Score - a.Score)
            .map((key: any, index: number) => {
              const recommendation = key;
              return (
                <InvestorCard
                  key={index}
                  index={index}
                  id={recommendation?.id}
                  // avatar={defaultProfile}
                  avatar={
                    recommendation?.id.toString().includes("vc")
                      ? recommendation?.metadata?.ProfilepicturesinS3
                      : !recommendation?.id.toString().includes("vc")
                      ? recommendation?.metadata?.ProfilepictureinS3
                      : defaultProfile
                  }
                  Reason={recommendation.Reason}
                  name={recommendation.Name}
                  fund={recommendation.FundName}
                  stages={recommendation?.metadata?.Stageofinvestment}
                  sectors={recommendation?.metadata?.Sector}
                  email={recommendation?.metadata?.Email}
                  matchLevel={recommendation?.Score.toString()}
                  bio={recommendation?.metadata?.Longerbio}
                  fundwebsite={recommendation?.metadata?.Fundwebsite}
                  linkedin={recommendation?.metadata?.LinkedInprofile}
                  tag={
                    recommendation?.id.toString().includes("vc") ? false : true
                  }
                  loading={false}
                  active={true}
                  setRecommendationCard={setRecommendationCard}
                  recommendationCard={recommendationCard}
                  shortlist={shortlist.includes(recommendation?.id)}
                />
              );
            })}
          {/* {[...vcRecommendations?.ai_matches].sort((a: any, b: any) => b.Score - a.Score).slice(0, 50).map((key: any, index: number) => {

                            const recommendation = key;
                            return (
                                <InvestorCard
                                    key={index - vcRecommendations?.ai_matches.length}
                                    index={index - vcRecommendations?.ai_matches.length}
                                    // avatar={defaultProfile}
                                    tag={true}
                                    avatar={recommendation?.metadata?.ProfilepictureinS3 || defaultProfile}
                                    Reason={recommendation.Reason}
                                    name={recommendation.Name}
                                    fund={recommendation.FundName}
                                    stages={recommendation?.metadata?.Stageofinvestment}
                                    sectors={recommendation?.metadata?.Sector}
                                    email={recommendation?.metadata?.Email}
                                    matchLevel={recommendation?.Score.toString()}
                                    bio={recommendation?.metadata?.Longerbio}
                                    fundwebsite={recommendation?.metadata?.Fundwebsite}
                                    linkedin={recommendation?.metadata?.LinkedInprofile}
                                    loading={false}
                                    active={true}
                                    setRecommendationCard={setRecommendationCard}
                                    recommendationCard={recommendationCard}
                                />
                            );
                        })} */}
        </div>
      ) : (
        <>
          <div className="grid xl:grid-cols-3 md:grid-cols-2 mt-4">
            <InvestorCard
              key={-3000}
              index={-3000}
              avatar={Kofi}
              name={"Kofi Ampadu"}
              fund={"a16z (partner at TxO)"}
              stages={"Pre-seed, seed"}
              sectors={""}
              Reason={dummyReason[0]}
              email={"N/A"}
              matchLevel={"5"}
              bio={
                " Kofi Ampadu, a Partner at Andreessen Horowitz (a16z) and the Head of the Talent x Opportunity Initiative (TxO), plays a pivotal role in driving innovation and supporting visionary entrepreneurs. With a focus on fostering diversity and inclusion in the entrepreneurial landscape, Kofi Ampadu leads the TxO program, which collaborates with promising companies and founders to develop products and services inspired by unique cultural insights. As the Head of TxO at Andreessen Horowitz, Kofi Ampadu works closely with founders to address significant challenges, ranging from wellness through video games to delivering affordable internet service to underserved communities. His commitment to empowering entrepreneurs and promoting innovative solutions underscores his dedication to driving positive change and supporting underrepresented voices in the tech and startup ecosystem."
              }
              fundwebsite={"https://a16z.com"}
              linkedin={"https://www.linkedin.com/in/kofiampadu/"}
              loading={false}
              setRecommendationCard={setRecommendationCard}
              shortlist={true}
            />
            <InvestorCard
              key={-2000}
              index={-2000}
              avatar={Fabian}
              Reason={dummyReason[1]}
              name={"Fabián Ronisky"}
              fund={"L'Attitude Ventures"}
              sectors={""}
              email={"N/A"}
              matchLevel={"5"}
              bio={"20"}
              fundwebsite={"https://lattitudeventures.com"}
              linkedin={"https://www.linkedin.com/in/fabianronisky/"}
              loading={false}
              setRecommendationCard={setRecommendationCard}
              shortlist={true}
            />

            <InvestorCard
              key={-1000}
              index={-1000}
              avatar={Shashi}
              name={"Shashi Srikantan"}
              fund={"Level Up Ventures"}
              Reason={dummyReason[2]}
              stages={"Seed, Series A"}
              sectors={""}
              email={"shashi101@aol.com"}
              matchLevel={"5"}
              bio={"20"}
              fundwebsite={"https://levelupventures.com"}
              linkedin={
                "https://www.linkedin.com/in/shashi-srikantan/overlay/about-this-profile/"
              }
              loading={false}
              setRecommendationCard={setRecommendationCard}
              shortlist={true}
            />
          </div>
          {/* {userCompany &&
                                    <div className='w-full flex justify-center justify-items mt-6'>
                                        <Button type="primary" size="large" shape="round" onClick={getSemantic} loading={getSemanticLoading} disabled={getSemanticLoading || userDataIsLoading} >
                                            Get Personalized Recommendations
                                        </Button>
                                    </div>

                                } */}
        </>
      )}
    </div>
  );
}

export default Matches;
