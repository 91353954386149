import React from 'react'
import { Link } from 'react-router-dom'

function CTA() {
    return (
        <section className='bg-primary50 max-w-[1440px] mx-auto px-8 py-12 rounded-md'>
            <div className='xl:flex items-center justify-between'>
                <h4 className='text-2xl font-semibold text-center xl:text-left'>Start your 7-day free trial</h4>
                <div className='flex items-center xl:justify-normal justify-center mt-3 xl:mt-0 gap-x-3'>
                    <Link to={'/pricing'} className='text-base font-semibold bg-white text-grey700 rounded-lg py-3 px-5'>
                        Get started
                    </Link>
                    {/* <Link to={'/lign'} className='text-base font-semibold bg-primary600 text-white rounded-lg py-3 px-5 border border-grey300'>
                        Learn more
                    </Link> */}

                </div>
            </div>
        </section>
    )
}

export default CTA