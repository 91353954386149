import React, { useState } from 'react'
import { useConfirmAccountMutation, useResendConfirmationMutation } from '../../lib/features/auth/authApiSlice';

import VerificationInput from "react-verification-input";
import { errorMessage, successMessage } from '../../constants/notification';
import { Button } from 'antd';


function ConfirmComponent({  setIsLogin, setIsConfirm }: { setIsLogin: React.Dispatch<React.SetStateAction<boolean>>, setIsConfirm: React.Dispatch<React.SetStateAction<boolean>> }) {

    const [confirmationCode, setConfirmationCode] = useState<string>();
    
    const [confirmAccount, { isLoading: confirmIsLoading }] = useConfirmAccountMutation()
    // const [updateProfileImage] = useUpdateProfileImageMutation()
    const [resendConfirmation, { isLoading: resendConfirmationIsLoading }] = useResendConfirmationMutation()
  return (
    <div>
                                <div style={{ color: '#868E96', marginBottom: 50 }}>Enter the verification code we just
                                    sent
                                    on your email
                                </div>

                                <div style={{ fontWeight: '600' }}>Email verification code</div>

                                <div style={{
                                    padding: 10,
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px solid #CED4DA',
                                    marginTop: 10,
                                    borderRadius: 8
                                }}>
                                    <VerificationInput
                                        onChange={(value) => {
                                            setConfirmationCode(value)
                                        }}
                                        placeholder={'*'}
                                        classNames={{
                                            container: 'VerificationInputContainer',
                                            character: 'VerificationInputStyle',
                                            characterInactive: 'VerificationInputInactiveStyle',
                                            characterSelected: 'VerificationInputSelectedStyle'
                                        }}
                                    />
                                </div>

                                <div className='flex items-center justify-end'>
                                    <button className='underline text-primary'
                                        onClick={() => {
                                            const localSEmail = localStorage.getItem("EMAIL")
                                            resendConfirmation({ email: localSEmail }).then((res) => {
                                                successMessage("Email resent successfully")
                                            }).catch((err) => {
                                                errorMessage("Failed to send email")
                                            })
                                        }}
                                    >{resendConfirmationIsLoading ? "Resending..." : "Resend"}</button>
                                </div>
                                <Button
                                    onClick={async () => {
                                        if (!confirmationCode || confirmationCode?.length !== 6) {
                                            alert('Please enter your confirmation code')
                                            return
                                        }

                                        const localSEmail = localStorage.getItem("EMAIL")
                                        if (!localSEmail) {
                                            errorMessage('Cannot confirm your account. Please try again later')
                                            return
                                        }

                                        try {
                                            confirmAccount({ email: localSEmail, code: confirmationCode }).then((res) => {
                                                localStorage.setItem("EMAIL", "")
                                                successMessage("Your account has been successfully confirmed, Kindly proceed to sign in")
                                                setIsConfirm(false)
                                                setIsLogin(true)

                                            }).catch((err) => {

                                            });
                                            // setIsConfirming(true)
                                            // if (!userFields || !userFields.email || !confirmationCode) return
                                            // const confirm = await confirmAccount(userFields.email, confirmationCode)
                                            // 
                                            // localStorage.setItem(USER, JSON.stringify(confirm))
                                            // dispatch(setUser(confirm))
                                            // navigate('/chat')
                                            // setIsConfirming(false)
                                        }
                                        catch (e: any) {

                                        }
                                    }}
                                    disabled={confirmIsLoading}
                                    loading={confirmIsLoading}
                                    type="primary"
                                    style={{ backgroundColor: '#228BE6', width: '100%', marginTop: 20 }}
                                >
                                    Verify
                                </Button>
                            </div>
  )
}

export default ConfirmComponent