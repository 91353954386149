import { Route, Routes } from "react-router-dom";
import "./App.css";
import { AskAnything } from "./screens/AskAnything/AskAnything";
import { Recommendation } from "./screens/Recommendation/Recommendation";
import { Profile } from "./screens/Profile/Profile";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Login } from "./screens/Auth/Authentication";
import { Bounce, ToastContainer } from "react-toastify";
import { UserSetting } from "./screens/UserSetting/UserSetting";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./components/ProtectedRoute";
import Conversation from "./screens/Answer/Conversation";
import Layout from "./screens/Layout";
import ForgotPassword from "./screens/password/ForgotPassword";
import AICompleteProfile from "./screens/Profile/AICompleteProfile";
import Homepage from "./screens/Home/Homepage";
import FirstTimeLogin from "./screens/FirstTimeLogin/FirstTimeLogin";
import MainProfile from "./screens/Profile/MainProfile";
import ProcessPitchDeck from "./screens/FirstTimeLogin/ProcessPitchDeck";
import Feedback from "./screens/feedback";
import LandingIndex from "./screens/Landing/pages";
import Pricing from "./screens/Landing/pages/pricing";
import Team from "./screens/Landing/pages/team";
import Billing from "./screens/Billing/billing";
import Billing2 from "./screens/Billing/billing2";

import ReactGA from "react-ga4";
import React, { useEffect } from "react";
import TermsAndPrivacy from "./screens/Landing/pages/termsAndPrivacy";
import Contact from "./screens/Landing/pages/contact";
import { useAppSelector } from "./lib/hooks";
import FeedbackProcess from "./screens/feedback/FeedbackProcess";
import FeedbackAI from "./screens/feedback/FeedbackAI";
import CTA from "./screens/FirstTimeLogin/CTA";

const TRACKING_ID = "G-QG2TKTLGNC";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

function App() {
  const user = useAppSelector((state) => state.user);

  // Initialize Google Analytics only once
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  

  // Track page views when user state changes
  useEffect(() => {
    if (ReactGA.ga()) {
      // Check if GA is initialized
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: user?.email || "",
      });
    }
  }, [user]);

  return (
    <>
      <Routes>
        <Route path="/" element={<LandingIndex />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/team" element={<Team />} />
        <Route path="/terms-and-privacy" element={<TermsAndPrivacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout showNavbar={false} />}>
            <Route path="/first-time-login" element={<FirstTimeLogin />} />
            <Route path="/cta" element={<CTA />} />
            <Route path="/process-pitch-deck" element={<ProcessPitchDeck />} />
            <Route path="/process-feedback" element={<FeedbackProcess />} />
          </Route>
          <Route element={<Layout showNavbar={true} />}>
            <Route path="/home" element={<Homepage />} />
            <Route path="/chat" element={<AskAnything />} />
            <Route path="/recommendation" element={<Recommendation />} />
            <Route path="/profile" element={<MainProfile />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/ai-feedback/" element={<FeedbackAI />} />
            <Route path="/ai-feedback/:id" element={<FeedbackAI />} />
            <Route path="/complete-profile" element={<Profile />} />
            {/* <Route path="/settings" element={<Settings />} /> */}
            <Route path="/answer/:id" element={<Conversation />} />
            <Route path="/settings" element={<UserSetting />} />
            <Route
              path="/ai-complete-profile"
              element={<AICompleteProfile />}
            />
            <Route path="/billing" element={<Billing />} />
            <Route path="/billing2" element={<Billing2 />} />
            </Route>
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </>
  );
}

export default App;
