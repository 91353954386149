import React from "react";
import Layout from "../Layout";
import Gary from "../../../assets/teams/Gary.png";
import John from "../../../assets/teams/John.png";
import Sherwyn from "../../../assets/teams/Sherwyn.png";
import Mudassir from "../../../assets/teams/Mudassir.png";
import { FaLinkedin } from "react-icons/fa";
const teams = [
  {
    id: 1,
    name: "Gary Stewart",
    title: "Founder & CEO",
    image: Gary,
    bio: "Gary Stewart is an exited, serial entrepreneur and investor whose portfolio companies have raised over $2 billion. Initially launching an SMB real estate agency, Gary pivoted to tech startups specializing in search and AI. As the former Managing Director of Techstars NYC, Wayra UK and Wayra Spain, he has supported hundreds of startups. An inaugural entrepreneurship lecturer at Yale, he holds a BA (magna cum laude, Phi Beta Kappa) and a JD from Yale. Gary founded MyCofoundr.AI to leverage his extensive experience to use AI to help more startups survive and thrive. ",
    linkedin: "https://www.linkedin.com/in/gary-stewart-a4a1b61b0/",
  },
  {
    id: 2,
    name: "Mudassir Aqeel Ahmed",
    title: "Co-Founder & CTO",
    image: Mudassir,
    bio: "Mudassir Aqeel Ahmed is a distinguished AI engineer renowned for his expertise in building and scaling advanced AI technologies across San Francisco, NYC, and Australia. Specializing in MLOps, Generative AI, and recommendation engines, Mudassir excels in leveraging LLMs, RAG applications, and knowledge graphs to drive innovation. With a Bachelor of Science degree (summa cum laude) from the University of Karachi, he brings a wealth of knowledge and a proven track record of pioneering cutting-edge AI solutions.",
    linkedin: "https://www.linkedin.com/in/mudassiraqeelahmed",
  },
  {
    id: 3,
    name: "Sherwyn Saul",
    title: "Co-Founder & Software Lead",
    image: Sherwyn,
    bio: "Sherwyn Saul is a visionary software leader and co-founder with over 20 years of expertise in application and software architecture. As a serial entrepreneur with multiple exits, he has driven innovation as the Lead Web Architect at AgEagle Aerial Systems Inc. and served as an Education Technologist at Penn State University. Sherwyn holds a BS in Computer Science and Information Systems from Brooklyn College.",
    linkedin:  "https://www.linkedin.com/in/sherwynsaul/",
  },
  {
    id: 4,
    name: "John Ononiba",
    title: "Head of Community",
    image: John,
    bio: "John Ononiba is an experienced operations and data analyst. He excels at fostering relationships and enhancing communication. His analytical skills allow him to leverage data insights to inform strategies that engage and grow our community effectively.",
    linkedin: "https://www.linkedin.com/in/Johnononiba/",
  },
];

function Team() {
  return (
    <Layout>
      <section className="xl:py-[80px] py-16 max-w-[1440px] mx-auto px-7">
        <div className="flex flex-col items-center xl:justify-normal justify-center">
          <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5 ">
            Our Team
          </span>
          <h3 className="text-4xl font-semibold mt-3 text-center xl:text-left ">
            Meet the team behind MyCofoundr.AI
          </h3>
          <p className="text-grey600 text-xl font-regular mt-5 text-center  max-w-[768px]">
            Our team blends expertise in AI, venture capital, UX design, and
            startup growth to connect founders with the right funding sources.
            Meet the people behind MyCofoundr.AI, dedicated to fueling your
            success.
          </p>
        </div>

        <div className="grid xl:grid-cols-2 gap-x-8 gap-y-16 mt-24">
          {teams.map((team, index) => (
            <div key={index} className="flex flex-col">
              <img src={team.image} alt={team.name} />

              <div className="mt-6">
                <h3 className="text-xl text-grey900 font-semibold text-left ">
                  {team.name}
                </h3>
                <p className="text-primary700 text-lg font-regular ">
                  {team.title}
                </p>
                <p className="mt-4 text-grey600 text-base font-regular ">
                  {team.bio}
                </p>
                <div className="mt-6">
                  <a
                    href={team.linkedin}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary600 text-base font-semibold mt-5 text-center "
                  >
                    <FaLinkedin className="size-6 text-grey400" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
}

export default Team;
